export const EnhanceProfileDetails: { [key: string]: string } = {
  SALUTATION: 'Salutation',
  SEX: 'Sex',
  DATEOFBIRTH: 'Date of Birth',
  CITYOFBIRTH: 'City of Birth',
  COUNTRYOFBIRTHCODE: 'Country of Birth',
  CITIZENSHIPCOUNTRYCODE: 'Citizenship Country',
  SOCIALID: 'SSN',
  SOCIALIDCOUNTRYCODE: 'SSN Country',
  WORK: 'Work Phone Number',
  HOME: 'Home Phone Number',
  MAILING: 'Mailing Address',
  PHYSICAL: 'Physical Address',
  SHIPPING: 'Shipping Address',
  LEGALNAME: 'Business Legal Name',
  DOINGBUSINESSASNAME: 'Business DBA Name',
  TAXID: 'Business Tax ID',
  TAXIDCOUNTRYCODE: 'Business Tax ID Country',
  DATEOFFORMATION: 'Date of Formation',
  LEGALENTITYTYPE: 'Legal Entity type',
  INDUSTRYCODE: 'Industry',
  DRIVINGLICENSE: 'Driving License',
  PASSPORT: 'passport',
  OTHER: 'Other',
  CONTROLLER: 'Controller',
  JOBTITLE: 'Job title',
  PERCENTAGEOWNED: 'Percentage of Busines Owned',
  FORMATION: 'Formation',
  TAX: 'Tax',
  OWNERSHIP: 'Ownership',
  PRIMARYBUSINESSPHONE: 'Primary Phone of Business',
};

export const CreateIndividualCustomerFields = ['First name', 'Middle name', 'Last name', 'Email', 'Phone number'];
export const CreateBusinessCustomerFields = ['Business Name', 'First name', 'Last name', 'Email', 'Phone number'];

export enum LinxCustomerType {
  INDIVIDUAL = 'INDIVIDUAL',
  BUSINESS = 'BUSINESS',
}

export enum LinxCustomerProfileType {
  New = 'NEW',
  Existing = 'EXISTING',
}

export enum LinxNotificationChannel {
  Sms = 'SMS',
  Email = 'EMAIL',
  Both = 'BOTH',
}

export enum LinxSteps {
  Notification = 'notification',
  DocumentAcceptance = 'document-acceptance',
  MoveMoney = 'move-money',
  CollectDocuments = 'collect-documents',
  //Individual
  CreateIndividualAccount = 'customer-account',
  LookupIndividualProfile = 'lookup-profile',
  EnhanceIndividualProfile = 'enhance-profile',
  SubmitIndividualVerification = 'submit-kyc',
  CreateIndividualFinancialAccount = 'financial-account',
  LookupIndividualFinancialAccount = 'lookup-financial-account',
  // Business
  CreateBusinessAccount = 'create-business-customer-account',
  LookupBusinessProfile = 'lookup-business-customer-account',
  EnhanceBusinessProfile = 'enhance-business-customer-account',
  SubmitBusinessVerification = 'submit-kyb',
  // Beneficiary
  CreateBeneficiary = 'create-beneficiary',
  LookupBeneficiary = 'lookup-beneficiary',
  EnhanceBeneficiary = 'enhance-beneficiary',
}

export enum LinxEnhanceAccountType {
  Individual = 'INDIVIDUAL_CUSTOMER',
  Business = 'BUSINESS_CUSTOMER',
  Benificiary = 'BENEFICIARY',
}

export enum LinxBeneficiaryProfileType {
  New = 'NEW',
  Existing = 'EXISTING',
}
