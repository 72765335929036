import { inject, Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { Store } from '@ngrx/store';
import { Router } from '@angular/router';
import { catchError, map, tap, switchMap, mergeMap, filter } from 'rxjs/operators';
import { ErrorUtils } from '@shared/utils';
import {
  MessagesActions,
  exceptionManagementFeature,
  fromAuth,
  selectExceptionsActiveFiltersRequest,
  selectRouteParams,
} from '@shared/store';
import { ExceptionManagementService } from '@shared/services';
import { ExceptionManagementActions } from './exception-management.actions';

@Injectable()
export class ExceptionManagementEffects {
  actions$ = inject(Actions);

  store = inject(Store);

  router = inject(Router);

  exceptionManagementService = inject(ExceptionManagementService);

  loadExceptions$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ExceptionManagementActions.loadExceptions),
      concatLatestFrom(() => [
        this.store.select(fromAuth.selectBusinessAccountId),
        this.store.select(selectExceptionsActiveFiltersRequest),
        this.store.select(exceptionManagementFeature.selectExceptionsAssigneeFilter),
      ]),
      mergeMap(([payload, activeBusinessAccountId, activeFilters, assigneeFilter]) => {
        return this.exceptionManagementService
          .getExeptions({
            businessAccountId: activeBusinessAccountId,
            requestParams: { ...payload.params, activeFilters },
            assignedTo: assigneeFilter?.map((item) => item.value) ?? [],
          })
          .pipe(
            map((exceptionListItems) => {
              return ExceptionManagementActions.loadExceptionsSuccess({
                exceptionListItems,
                totalElements: exceptionListItems.length,
              });
            }),
            catchError((error) => {
              return of(ExceptionManagementActions.loadExceptionsFailure({ error }));
            }),
          );
      }),
    );
  });

  loadExceptionsFailure$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ExceptionManagementActions.loadExceptionsFailure),
      tap({
        next: (errorResponse) => {
          ErrorUtils.catchError('exceptionManagementService.getExceptions - Error', errorResponse.error);
        },
      }),
      switchMap(() => of(MessagesActions.displayError({ message: 'Unable to fetch Exceptions.' }))),
    );
  });

  loadExceptionDetails$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ExceptionManagementActions.loadExceptionDetails, ExceptionManagementActions.updateExceptionFailure),
      concatLatestFrom(() => [this.store.select(selectRouteParams)]),
      mergeMap(([, routeParams]) => {
        return this.exceptionManagementService
          .getExceptionDetails({
            exceptionId: routeParams.exceptionId,
          })
          .pipe(
            map((exceptionDetails) => {
              return ExceptionManagementActions.loadExceptionDetailsSuccess({ exceptionDetails });
            }),
            catchError((error) => {
              return of(ExceptionManagementActions.loadExceptionDetailsFailure({ error }));
            }),
          );
      }),
    );
  });

  loadExceptionDetailsSuccess$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ExceptionManagementActions.loadExceptionDetailsSuccess),
      switchMap(() => of(ExceptionManagementActions.loadExceptionHistory({ params: { page: 0 } }))),
    );
  });

  loadExceptionDetailsFailure$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ExceptionManagementActions.loadExceptionDetailsFailure),
      tap({
        next: (errorResponse) => {
          ErrorUtils.catchError('exceptionManagementService.getExceptionDetails - Error', errorResponse.error);
        },
      }),
      filter((errorResponse) => errorResponse.error !== 404),
      switchMap(() => of(MessagesActions.displayError({ message: 'Unable to fetch Exception Details.' }))),
    );
  });

  loadExceptionDetailsFailureRedirect$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(ExceptionManagementActions.loadExceptionDetailsFailure),
        tap({
          next: () => this.router.navigateByUrl('/app/exception-management/exceptions'),
        }),
      );
    },
    { dispatch: false },
  );

  updateException$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ExceptionManagementActions.updateException),
      concatLatestFrom(() => [this.store.select(selectRouteParams)]),
      mergeMap(([payload, routeParams]) => {
        const { assignedTo, status } = payload.data;
        return this.exceptionManagementService
          .updateExceptionById({
            exceptionId: routeParams.exceptionId,
            data: {
              assignedTo: assignedTo === 'UNASSIGNED' ? null : assignedTo,
              status,
            },
          })
          .pipe(
            map((exceptionDetails) => {
              return ExceptionManagementActions.updateExceptionSuccess({ exceptionDetails });
            }),
            catchError((error) => {
              return of(ExceptionManagementActions.updateExceptionFailure({ error }));
            }),
          );
      }),
    );
  });

  updateExceptionSuccess$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ExceptionManagementActions.updateExceptionSuccess),
      map((response) => {
        return ExceptionManagementActions.loadExceptionDetailsSuccess({ exceptionDetails: response.exceptionDetails });
      }),
    );
  });

  updateExceptionSuccessNotification$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ExceptionManagementActions.updateExceptionSuccess),
      switchMap(() => of(MessagesActions.displaySuccess({ message: 'Exception updated successfully.' }))),
    );
  });

  updateExceptionFailure$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ExceptionManagementActions.updateExceptionFailure),
      tap({
        next: (errorResponse) => {
          ErrorUtils.catchError('exceptionManagementService.updateException - Error', errorResponse.error);
        },
      }),
      filter((errorResponse) => errorResponse.error !== 404),
      switchMap(() => of(MessagesActions.displayError({ message: 'Unable to update Exception.' }))),
    );
  });

  loadExceptionHistory$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ExceptionManagementActions.loadExceptionHistory),
      concatLatestFrom(() => [this.store.select(selectRouteParams)]),
      mergeMap(([payload, routeParams]) => {
        return this.exceptionManagementService
          .getExceptionHistory({
            exceptionId: routeParams.exceptionId,
            requestParams: { ...payload.params },
          })
          .pipe(
            map((exceptionHistoryList) => {
              return ExceptionManagementActions.loadExceptionHistorySuccess({
                exceptionHistoryItems: exceptionHistoryList.content ?? [],
                totalElements: exceptionHistoryList.totalElements,
              });
            }),
            catchError((error) => {
              return of(ExceptionManagementActions.loadExceptionHistoryFailure({ error }));
            }),
          );
      }),
    );
  });

  loadExceptionHistoryFailure$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ExceptionManagementActions.loadExceptionHistoryFailure),
      tap({
        next: (errorResponse) => {
          ErrorUtils.catchError('exceptionManagementService.getExceptionHistory - Error', errorResponse.error);
        },
      }),
      filter((errorResponse) => errorResponse.error !== 404),
      switchMap(() => of(MessagesActions.displayError({ message: 'Unable to fetch Exception History.' }))),
    );
  });
}
