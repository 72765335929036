export enum TransactionOccurrenceType {
  SINGLE = 'SINGLE',
  RECURRING = 'RECURRING',
  FIXED_NUMBER = 'FIXED_NUMBER',
}

export enum PaymentFrequency {
  DAILY = 'DAILY',
  WEEKLY = 'WEEKLY',
  BIWEEKLY = 'BIWEEKLY',
  MONTHLY = 'MONTHLY',
  QUARTERLY = 'QUARTERLY',
  YEARLY = 'YEARLY',
}
