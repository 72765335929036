import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { FormModel, LinxEnhanceCustomerDetails, LinxEnhanceProfileDetails } from '@shared/models';
import { idDocumentBusinessFields, idDocumentIndividualFields, linxRequestProfileFields } from './linx-request-enhance-profile-utils';
import { LinxCustomerType, LinxEnhanceAccountType } from '@shared/constants';

interface EnhanceProfileFormModel {
  businessInfo: Record<string, boolean>;
  personalInfo: Record<string, boolean>;
  addresses: Record<string, boolean>;
  phoneNumber: string;
  documentTypes: Record<string, boolean>;
}

@Component({
  selector: 'app-linx-request-enhance-profile',
  templateUrl: './linx-request-enhance-profile.component.html',
})
export class LinxRequestEnhanceProfileComponent implements OnInit, AfterViewInit {
  @Input() customerType? = LinxCustomerType.INDIVIDUAL;

  @Input() selectedEnhanceProfileDetails?: LinxEnhanceCustomerDetails;

  @Output() continue = new EventEmitter<LinxEnhanceCustomerDetails>();

  profileForm = new FormGroup({});

  formModel: FormModel<EnhanceProfileFormModel> = {};

  profileFields: FormlyFieldConfig[] = [];

  documentFields: FormlyFieldConfig[] = [];
  constructor(private cdr: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.documentFields = this.customerType === LinxCustomerType.BUSINESS ? idDocumentBusinessFields : idDocumentIndividualFields;
    this.setEnhanceProfile();
  }

  ngAfterViewInit(): void {
    this.profileFields = linxRequestProfileFields(this.customerType);
    this.cdr.detectChanges();
  }

  private setEnhanceProfile(): void {
    if (!this.selectedEnhanceProfileDetails) return;

    this.formModel = {};
    const { phoneNumber, personalInfo, businessInfo, addresses } = this.selectedEnhanceProfileDetails.enhanceProfileDetails ?? {};
    if (phoneNumber) {
      this.formModel.phoneNumber = phoneNumber[0];
    }
    const profileFields: string[] = personalInfo ?? businessInfo ?? [];
    if (profileFields.length) {
      const profileInfo = {} as { [key: string]: boolean };
      profileFields.forEach((info: string) => {
        profileInfo[info] = true;
      });
      if (personalInfo) {
        this.formModel.personalInfo = profileInfo;
      } else {
        this.formModel.businessInfo = profileInfo;
      }
    }

    if (addresses) {
      const formAddresses = {} as { [key: string]: boolean };
      addresses.forEach((address: string) => {
        formAddresses[address] = true;
      });
      this.formModel.addresses = formAddresses;
    }
  }

  onClickContinue(): void {
    const { documentTypes = {}, personalInfo, businessInfo, addresses, phoneNumber } = this.formModel;
    const profileDetails: LinxEnhanceProfileDetails = {};

    if (phoneNumber) {
      profileDetails.phoneNumber = [phoneNumber];
    }

    const personalInfoDetails = personalInfo ? Object.keys(personalInfo).filter((key) => personalInfo[key]) : [];
    if (personalInfoDetails.length) {
      profileDetails.personalInfo = personalInfoDetails;
    }

    const businessDetails = businessInfo ? Object.keys(businessInfo).filter((key) => businessInfo[key]) : [];
    if (businessDetails.length) {
      const businessInfoDetails = businessDetails.filter((key: string) => key !== 'primaryBusinessPhone');
      if (businessInfoDetails.length) {
        profileDetails.businessInfo = businessInfoDetails;
      }
      const phone = businessDetails.find((key: string) => key === 'primaryBusinessPhone');
      if (phone) {
        profileDetails.phoneNumber = [phone];
      }
    }

    const addressesDetails = addresses ? Object.keys(addresses).filter((key) => addresses[key]) : [];
    if (addressesDetails.length) {
      profileDetails.addresses = addressesDetails;
    }

    const enhanceDetails: LinxEnhanceCustomerDetails = {
      accountType: this.customerType === LinxCustomerType.BUSINESS ? LinxEnhanceAccountType.Business : LinxEnhanceAccountType.Individual,
      enhanceProfileDetails: Object.keys(profileDetails).length ? profileDetails : undefined,
      documentTypes: Object.keys(documentTypes).filter((key) => documentTypes[key]),
    };

    this.continue.emit(enhanceDetails);
  }

  onClearBtnClick(): void {
    this.profileForm.reset();
  }
}
