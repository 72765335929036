import { Component, Input } from '@angular/core';
import { InsightDocumentListItem, InsightDocument } from '@shared/models';
import { ErrorUtils } from '@shared/utils';
import { FileDownloadSelectComponent, ViewPdfDocumentComponent } from '@shared/components';
import { NotificationService, ReportService } from '@shared/services';
import { ModalService } from '@rocketfinancialcorp/rocket-ui/modal';

@Component({
  selector: 'app-report-list-item',
  templateUrl: './report-list-item.component.html',
  styleUrls: ['./report-list-item.component.scss'],
})
export class ReportListItemComponent {
  @Input() documentListItem!: InsightDocumentListItem;

  constructor(private reportService: ReportService, private modalService: ModalService, private notificationService: NotificationService) {}

  exportDocument(documentName: string | undefined, documentId: string, documentType: string, downloadDocument: boolean): void {
    this.reportService.getInsightReportPreSignedURL(documentId, documentType).subscribe({
      next: (response) => {
        const { signedJsonUrl } = response;

        if (downloadDocument) {
          window.open(signedJsonUrl, '_blank');
        } else {
          this.previewDocument(documentName, signedJsonUrl);
        }
      },
      error: (error) => {
        this.notificationService.displayError('Unable to download document.');
        ErrorUtils.catchError('reportService.getPreSignedURL error', error);
      },
    });
  }

  previewDocument(documentName: string | undefined, documentURL: string) {
    const previewDocumentRef = this.modalService.open(ViewPdfDocumentComponent, {
      className: 'entity-form-modal preview-document-modal',
      size: 'lg',
    });

    previewDocumentRef.componentInstance.modalInitData({
      documentName,
      documentURL,
      containerHeight: '40rem',
    });
  }

  showDownloadModal(reportDocument: InsightDocument) {
    const fileDownloadModalRef = this.modalService.open(FileDownloadSelectComponent, {
      className: 'entity-form-modal',
      size: 'sm',
    });

    fileDownloadModalRef.componentInstance.modalInitData(reportDocument);

    fileDownloadModalRef.result.then(
      (response: string[]) => {
        if (response) {
          response.forEach((fileType: string) => {
            setTimeout(() => {
              this.exportDocument(reportDocument.name, reportDocument.id, fileType.toUpperCase(), true);
            }, 100);
          });
        }
      },
      () => false,
    );
  }
}
