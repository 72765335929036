<div class="document-header">
  <div class="find-report-header">
    <button class="back-button" (click)="viewReports()">
      <rkt-icon name="arrow-left"></rkt-icon>
    </button>
    <div class="document-title">Find Documents</div>
  </div>

  <button class="close-button" (click)="closeModal()">
    <rkt-icon name="times"></rkt-icon>
  </button>
</div>

<div class="find-report-container">
  <div class="find-report">
    <rkt-form [form]="findReportForm" [fields]="formFields" [model]="formModel"></rkt-form>
    <rkt-form [form]="findReportForm" [fields]="dateFormFields" [model]="formModel" [class]="'date-range'"></rkt-form>
    <button rktButton (click)="onFindReport()" class="find-report-submit" type="submit" [disabled]="loading || !findReportForm.valid">
      Show
    </button>
  </div>

  <ng-container *ngIf="filteredReports?.length; else onFetchDocuments">
    <div *ngFor="let reportListItem of filteredReports">
      <app-report-list-item [documentListItem]="reportListItem"></app-report-list-item>
    </div>

    <app-pagination [totalPages]="totalPages" [activePage]="activePage" (updatePageParam)="getInsightReports($event)"></app-pagination>
  </ng-container>

  <ng-template #onFetchDocuments>
    <div class="empty-state" *ngIf="!loading">
      <div class="image"></div>
      <div class="message">No Report Available</div>
    </div>

    <ng-container *ngIf="loading">
      <div *ngFor="let i of [].constructor(10)" class="document-list-item"></div>
    </ng-container>
  </ng-template>
</div>
