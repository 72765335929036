import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalService } from '@rocketfinancialcorp/rocket-ui/modal';
import { ConfirmModalComponent } from '@shared/components/confirm-modal/confirm-modal.component';
import { FinancialAccountDetails, FinancialAccountHolderType } from '@shared/models';
import { SubscriberService, FinancialAccountService, NotificationService } from '@shared/services';
import { ErrorUtils } from '@shared/utils';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-financial-account-details',
  templateUrl: 'financial-account-details.component.html',
  styleUrls: ['financial-account-details.component.scss'],
})
export class FinancialAccountDetailsComponent implements OnInit {
  public financialAccountId?: FinancialAccountDetails['id'];

  public financialAccount: FinancialAccountDetails | null = null;

  public entityType?: FinancialAccountHolderType;

  public unmaskedAccountNumber?: string;

  public timeoutId?: ReturnType<typeof setTimeout>;

  public isEscrowAccount = this.router.url.split('/').includes('escrow-account-details');

  get accountNumber(): string {
    return this.unmaskedAccountNumber ?? '**** ' + this.financialAccount?.bankAccount?.accountNumberTail;
  }

  get isLoading(): boolean {
    return !this.financialAccountId || !this.financialAccount;
  }

  get isIntegrated(): boolean {
    return this.financialAccount?.category === 'INTEGRATED';
  }

  get isBankAccount(): boolean {
    return this.financialAccount?.cardOrBankAccount === 'bankAccount';
  }

  verificationStatus?: string;

  get isEligibleForVerification(): boolean {
    const statusForVerification = [ 'UNVERIFIED', 'APPROVED', 'DECLINED']
    return (
      this.financialAccount?.state === 'ACTIVE' &&
      statusForVerification.includes(this.verificationStatus ?? 'UNVERIFIED') &&
      this.financialAccount?.category === 'EXTERNAL' &&
      this.isBankAccount &&
      this.financialAccount?.subtype === 'CHECKING'
    );
  }

  private destroy$ = new Subject<void>();

  constructor(
    protected layoutOptions: SubscriberService<FinancialAccountDetails>,
    private router: Router,
    private financialAccountService: FinancialAccountService,
    private notificationService: NotificationService,
    private activatedRoute: ActivatedRoute,
    private modalService: ModalService,
  ) {
    const { snapshot } = this.activatedRoute.parent ?? {};
    this.entityType = snapshot?.data['accountHolderType'];
  }

  ngOnInit(): void {
    const { parent } = this.activatedRoute;
    this.financialAccountId = parent!.snapshot.paramMap.get('id') ?? undefined;

    this.layoutOptions.subscribe((financialAccount) => {
      this.financialAccount = { ...this.financialAccount, ...financialAccount };
      if (!this.verificationStatus) {
        this.verificationStatus = financialAccount.verificationStatus;
      }
    });
  }

  loadFinancialAccountDetails() {
    if (!this.financialAccountId) return;
    this.financialAccountService.getFinancialAccountDetailsById(this.financialAccountId).subscribe({
      next: (financialAccountDetails) => {
        this.unmaskedAccountNumber = financialAccountDetails?.bankAccount?.accountNumber;
        this.timeoutId = setTimeout(() => this.hideFinancialAccountDetails(), 30000);
      },
      error: (error) => {
        this.notificationService.displayWarning('Error Message: System is unable to retrieve details of the Financial Account');
        ErrorUtils.catchError('financialAccountService.getFinancialAccountDetailsById error', error);
      },
    });
  }

  hideFinancialAccountDetails() {
    if (this.timeoutId) clearTimeout(this.timeoutId);
    this.unmaskedAccountNumber = undefined;
  }

  onVerifyAccountClick(): void {
    const confirmModalRef = this.modalService.open(ConfirmModalComponent, { className: 'confirm-modal' });
    confirmModalRef.componentInstance.customText = 'Are you sure you want to submit a Bank Account Verification?';

    confirmModalRef.result.then(
      (result) => {
        if (result) {
          this.verifyAccount();
        }
      },
      () => false,
    );
  }

  verifyAccount(): void {
    this.financialAccountService
      .verifyFinancialAccount(this.financialAccount?.id ?? '')
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (response) => {
          if (response?.verificationResult[0].errorMessage) {
            this.notificationService.displayError(response?.verificationResult[0].errorMessage);
            ErrorUtils.catchError('financialAccountService.verifyFinancialAccount error', response?.verificationResult[0].errorMessage);
          } else {
            this.verificationStatus = response.verificationResult[0].status;
            this.notificationService.displaySuccess('Bank Account Verification requested.', 'Success');
          }
        },
        error: (error) => {
          this.notificationService.displayError('Bank Account Verification request failed', 'Error');
          ErrorUtils.catchError('financialAccountService.verifyFinancialAccount error', error);
        },
      });
  }
}
