import { inject, Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Store } from '@ngrx/store';
import { concatLatestFrom } from '@ngrx/effects';
import { Observable, catchError, map, switchMap, throwError } from 'rxjs';
import { environment } from '@env';
import {
  AttachmentCreateModel,
  AttachmentCreatedResponse,
  AttachmentDetails,
  AttachmentListRequest,
  AttachmentList,
  AttachmentListRaw,
  AttchmentUpdateModel,
} from '@shared/models';
import { NotificationService } from '@shared/services';
import { ErrorUtils } from '@shared/utils';
import { constants, READ_ONLY_ROLES } from '@shared/constants';
import { CustomHttpParamEncoder } from '@shared/encoder';
import { fromAuth } from '@shared/store';
import { BackendService } from '../backend.service';

@Injectable({
  providedIn: 'root',
})
export class AttachmentService {
  store = inject(Store);

  constructor(private backendService: BackendService, private http: HttpClient, private notificationService: NotificationService) {}

  getAttachments({ entityType, entityId, page, searchString, size, sortParams }: AttachmentListRequest): Observable<AttachmentList> {
    let params = new HttpParams({ encoder: new CustomHttpParamEncoder() })
      .set('page', `${page ?? 0}`)
      .set('size', size ?? `${constants.TABLE_ROWS}`);

    if (sortParams) {
      params = params.append('sort', `${sortParams.key},${sortParams.sortDir}`);
    }

    if (searchString) {
      params = params.append('search', searchString);
    }

    return this.backendService
      .get<AttachmentListRaw>(`${environment.documentService}/attachments?entityType=${entityType}&entityId=${entityId}`, { params })
      .pipe(
        concatLatestFrom(() => [this.store.select(fromAuth.selectUserRole)]),
        map(([response, userRole]) => this.mapAttachmentList(response, userRole)),
        catchError((errorRes) => throwError(() => errorRes)),
      );
  }

  mapAttachmentList(response: AttachmentListRaw, role: string): AttachmentList {
    const { content = [], totalElements = 0, hasNext = false } = response;
    const isViewOnly = READ_ONLY_ROLES.includes(role);

    return {
      items: content.map((item) => {
        return {
          ...item,
          deleteBtn: isViewOnly ? undefined : 'DELETE',
          editBtn: isViewOnly ? undefined : 'EDIT',
          downloadBtn: 'DOWNLOAD',
        };
      }),
      totalElements,
      hasNext,
    };
  }

  createAttachment({
    entityType,
    entityId,
    file,
    name,
    description,
    extension,
  }: AttachmentCreateModel): Observable<AttachmentCreatedResponse> {
    const formData = new FormData();
    formData.append('file', file as Blob);
    formData.append('entityType', entityType);
    formData.append('entityId', entityId);
    formData.append('extension', extension);
    formData.append('name', name ?? file.name);
    if (description) {
      formData.append('description', description);
    }

    return this.http
      .post<AttachmentCreatedResponse>(`${this.backendService.baseUrl}${environment.documentService}/attachments`, formData)
      .pipe(catchError((errorRes) => throwError(() => errorRes)));
  }

  getAttachmentById(attachmentId: string): Observable<AttachmentDetails> {
    return this.backendService
      .get<AttachmentDetails>(`${environment.documentService}/attachments/${attachmentId}`)
      .pipe(catchError((errorRes) => throwError(() => errorRes)));
  }

  downloadAttachment(attachmentId: string): void {
    this.getAttachmentById(attachmentId)
      .pipe(
        switchMap((attachmentDetails: AttachmentDetails) => {
          if (attachmentDetails?.url?.value) {
            return this.http.get(attachmentDetails.url.value, { responseType: 'blob' }).pipe(
              map((file: Blob) => {
                return { attachmentDetails, file: file };
              }),
            );
          } else {
            return throwError(() => new Error('Download URL not fould'));
          }
        }),
        catchError((errorRes) => throwError(() => errorRes)),
      )
      .subscribe({
        next: (response) => {
          const a = document.createElement('a');
          const objectUrl = URL.createObjectURL(response.file);
          a.href = objectUrl;
          a.download = response.attachmentDetails.name;
          a.click();
          URL.revokeObjectURL(objectUrl);
        },
        error: (error) => {
          this.notificationService.displayError('Unable to Download Attachment.', 'Error');
          ErrorUtils.catchError('attachmentService.downloadAttachment', error);
        },
      });
  }

  deleteAttachment(attachmentId: AttachmentDetails['id']): Observable<void> {
    return this.backendService
      .delete<void>(`${environment.documentService}/attachments/${attachmentId}`)
      .pipe(catchError((errorRes) => throwError(() => errorRes)));
  }

  updateAttachment(attachmentId: AttachmentDetails['id'], formData: AttchmentUpdateModel): Observable<AttachmentDetails> {
    return this.backendService
      .update<AttachmentDetails>(`${environment.documentService}/attachments/${attachmentId}`, formData)
      .pipe(catchError((errorRes) => throwError(() => errorRes)));
  }
}
