import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { catchError, concatMap, map, mergeMap, tap } from 'rxjs/operators';
import { HttpContext, HttpParams } from '@angular/common/http';
import { compact, find } from 'lodash-es';
import { Store } from '@ngrx/store';
import { concatLatestFrom } from '@ngrx/effects';
import { environment } from '@env';
import { CustomHttpParamEncoder } from '@shared/encoder';
import { BackendService } from '@shared/services/backend.service';
import {
  CardAlert,
  CardAlertRaw,
  CardDispute,
  CardDisputeQuestion,
  CardDisputeRaw,
  CardIssue,
  CardRestriction,
  CardRestrictionRaw,
  CardStatement,
  CardStatementListRaw,
  CreateAlertData,
  CreateDisputeData,
  CreateRestrictionData,
  EntityFinancialAccountDetailsRaw,
  FinancialAccountDetails,
  FinancialAccountDetailsWithBalancesRaw,
  CardProgram,
  CardProgramRaw,
  CardReissue,
  MccItem,
  MccItemRaw,
  SupportedLanguage,
} from '@shared/models';
import { CustomerService } from '@shared/services';
import { CUSTOM_ERROR_MESSAGE, IS_EXTENDED_ERRORS } from '@shared/interceptors';
import { AuditActions, fromAuth } from '@shared/store';
import { READ_ONLY_ROLES } from '@shared/constants';
import {
  mapCardAccountDetails,
  mapCardAlertItem,
  mapCardAlertItems,
  mapCardDisputeItem,
  mapCardDisputeItems,
  mapCardRestrictionItem,
  mapCardRestrictionItems,
} from './card-account-mapping-utils';

@Injectable({
  providedIn: 'root',
})
export class CardAccountService {
  constructor(private backendService: BackendService, private customerService: CustomerService, private store: Store) {}

  public issueCardAccount(cardIssueData: CardIssue): Observable<EntityFinancialAccountDetailsRaw> {
    return this.backendService
      .post<EntityFinancialAccountDetailsRaw>(`${environment.cardsEndpoint}/cards`, cardIssueData)
      .pipe(catchError((errorRes) => throwError(() => errorRes)));
  }

  public reissueCardAccount(cardAccountId: string, cardReissueData: CardReissue): Observable<EntityFinancialAccountDetailsRaw> {
    return this.backendService
      .post<EntityFinancialAccountDetailsRaw>(`${environment.cardsEndpoint}/cards/${cardAccountId}/reissue`, cardReissueData)
      .pipe(catchError((errorRes) => throwError(() => errorRes)));
  }

  public activateCardAccount({ cardAccountId }: { cardAccountId: string }): Observable<EntityFinancialAccountDetailsRaw> {
    return this.backendService
      .post<EntityFinancialAccountDetailsRaw>(`${environment.cardsEndpoint}/cards/${cardAccountId}/activate`)
      .pipe(catchError((errorRes) => throwError(() => errorRes)));
  }

  public getCardAccountDetails({
    businessAccountId,
    cardAccountId,
  }: {
    businessAccountId: string;
    cardAccountId: string;
  }): Observable<FinancialAccountDetails> {
    const params = new HttpParams({ encoder: new CustomHttpParamEncoder() }).set('embed', 'balances,cardProviderDetails');

    return this.backendService
      .get<FinancialAccountDetailsWithBalancesRaw>(
        `${environment.financialAccountFlow}/business-accounts/${businessAccountId}/financial-accounts/${cardAccountId}`,
        { params },
      )
      .pipe(
        tap({
          next: (cardDetailsResponse) => {
            const { createdBy, updatedBy } = cardDetailsResponse;
            this.store.dispatch(AuditActions.loadTeamMembers({ ids: compact([createdBy, updatedBy]) }));
          },
        }),
        map((response) => mapCardAccountDetails(response)),
        catchError((errorRes) => throwError(() => errorRes)),
      );
  }

  public getCardRestrictions({ financialAccountId }: { financialAccountId: string }): Observable<CardRestriction[]> {
    return this.backendService.get<CardRestrictionRaw[]>(`${environment.cardsEndpoint}/cards/${financialAccountId}/restrictions`).pipe(
      map((response) => mapCardRestrictionItems(response)),
      catchError((errorRes) => throwError(() => errorRes)),
    );
  }

  public getCardRestrictionById({
    financialAccountId,
    restrictionId,
  }: {
    financialAccountId: string;
    restrictionId: string;
  }): Observable<CardRestriction> {
    return this.backendService
      .get<CardRestrictionRaw>(`${environment.cardsEndpoint}/cards/${financialAccountId}/restrictions/${restrictionId}`)
      .pipe(
        map((response) => mapCardRestrictionItem(response)),
        catchError((errorRes) => throwError(() => errorRes)),
      );
  }

  public getCardAlerts({ financialAccountId }: { financialAccountId: string }): Observable<CardAlert[]> {
    return this.backendService.get<CardAlertRaw[]>(`${environment.cardsEndpoint}/cards/${financialAccountId}/alerts`).pipe(
      map((response) => mapCardAlertItems(response)),
      catchError((errorRes) => throwError(() => errorRes)),
    );
  }

  public getCardAlertById({ financialAccountId, alertId }: { financialAccountId: string; alertId: string }): Observable<CardAlert> {
    return this.backendService.get<CardAlertRaw>(`${environment.cardsEndpoint}/cards/${financialAccountId}/alerts/${alertId}`).pipe(
      map((response) => mapCardAlertItem(response)),
      catchError((errorRes) => throwError(() => errorRes)),
    );
  }

  public getCardDisputes({ financialAccountId }: { financialAccountId: string }): Observable<CardDispute[]> {
    return this.backendService.get<CardDisputeRaw[]>(`${environment.cardsEndpoint}/cards/${financialAccountId}/disputes`).pipe(
      map((response) => mapCardDisputeItems(response)),
      catchError((errorRes) => throwError(() => errorRes)),
    );
  }

  public getCardDisputeById({ financialAccountId, disputeId }: { financialAccountId: string; disputeId: string }): Observable<CardDispute> {
    const params = new HttpParams({ encoder: new CustomHttpParamEncoder() }).set('caseNumber', disputeId);
    return this.backendService
      .get<CardDisputeRaw[]>(`${environment.cardsEndpoint}/cards/${financialAccountId}/disputes`, {
        params,
      })
      .pipe(
        concatMap((response) => {
          if (!response || response.length === 0) {
            return throwError(() => new Error('dispute-not-found'));
          } else {
            return of(response);
          }
        }),
        map((response) => mapCardDisputeItem(response[0])),
        catchError((errorRes) => throwError(() => errorRes.message)),
      );
  }

  public getCardStatements({ financialAccountId }: { financialAccountId: string }): Observable<CardStatement[]> {
    return this.backendService.get<CardStatementListRaw>(`${environment.cardsEndpoint}/cards/${financialAccountId}/statements`).pipe(
      mergeMap((response) => {
        return this.customerService.getCustomerById(response.customerId).pipe(
          map((customer) => ({
            ...response,
            customerEmail: find(customer.contacts, ['primary', true])?.primaryEmail?.value ?? customer?.primaryEmail?.value,
          })),
        );
      }),
      concatLatestFrom(() => [this.store.select(fromAuth.selectUserRole)]),
      map(
        ([{ generatedStatements, customerEmail }, userRole]) =>
          generatedStatements.map((item) => ({
            ...item,
            customerEmail,
            emailStatement: !READ_ONLY_ROLES.includes(userRole) ? 'emailStatementBtn' : undefined,
            financialAccountId,
          })) || [],
      ),
      catchError((errorRes) => throwError(() => errorRes)),
    );
  }

  public sendCardStatementByEmail({
    financialAccountId,
    month,
    year,
  }: {
    financialAccountId: string;
    month: number;
    year: number;
  }): Observable<boolean> {
    return this.backendService.post(`${environment.cardsEndpoint}/cards/${financialAccountId}/statements/${year}/${month}/email`).pipe(
      map(() => true),
      catchError((errorRes) => throwError(() => errorRes)),
    );
  }

  public createDispute(financialAccountId: string, createDisputeData: CreateDisputeData): Observable<boolean> {
    return this.backendService
      .post(`${environment.cardsEndpoint}/cards/${financialAccountId}/disputes`, {
        ...createDisputeData,
      })
      .pipe(
        map(() => true),
        catchError((errorRes) => throwError(() => errorRes)),
      );
  }

  public getCardDisputeQuestions({
    financialAccountId,
    disputeType,
  }: {
    financialAccountId: string;
    disputeType: CardDispute['disputeType'];
  }): Observable<CardDisputeQuestion[]> {
    return this.backendService
      .get<CardDisputeQuestion[]>(`${environment.cardsEndpoint}/cards/${financialAccountId}/disputes/dispute-questions/${disputeType}`, {
        context: new HttpContext().set(CUSTOM_ERROR_MESSAGE, 'Unable to fetch Dispute Question Codes.'),
      })
      .pipe(catchError((errorRes) => throwError(() => errorRes)));
  }

  public createRestriction(financialAccountId: string, createRestrictionData: CreateRestrictionData): Observable<boolean> {
    return this.backendService
      .post(`${environment.cardsEndpoint}/cards/${financialAccountId}/restrictions`, { ...createRestrictionData })
      .pipe(
        map(() => true),
        catchError((errorRes) => throwError(() => errorRes)),
      );
  }

  public editRestriction(id: string, createRestrictionData: CreateRestrictionData): Observable<boolean> {
    return this.backendService
      .update(`${environment.cardsEndpoint}/cards/${createRestrictionData.financialAccountId}/restrictions/${id}`, {
        ...createRestrictionData,
      })
      .pipe(
        map(() => true),
        catchError((errorRes) => throwError(() => errorRes)),
      );
  }

  public createAlert(createAlertData: CreateAlertData): Observable<boolean> {
    return this.backendService
      .post(
        `${environment.cardsEndpoint}/cards/${createAlertData.financialAccountId}/alerts`,
        { ...createAlertData },
        { context: new HttpContext().set(IS_EXTENDED_ERRORS, true) },
      )
      .pipe(
        map(() => true),
        catchError((errorRes) => throwError(() => errorRes)),
      );
  }

  public editAlert(id: string, createAlertData: CreateAlertData): Observable<boolean> {
    return this.backendService
      .update(`${environment.cardsEndpoint}/cards/${createAlertData.financialAccountId}/alerts/${id}`, { ...createAlertData })
      .pipe(
        map(() => true),
        catchError((errorRes) => throwError(() => errorRes)),
      );
  }

  public getCardPrograms(): Observable<CardProgram[]> {
    return this.backendService
      .get<CardProgramRaw[]>(`${environment.cardsEndpoint}/programs`)
      .pipe(catchError((errorRes) => throwError(() => errorRes)));
  }

  public getCardIssueSupportedLanguages(): Observable<SupportedLanguage[]> {
    return this.backendService
      .get<SupportedLanguage[]>(`${environment.cardsEndpoint}/supported-languages`)
      .pipe(catchError((errorRes) => throwError(() => errorRes)));
  }

  public getMccItems(): Observable<MccItem[]> {
    return this.backendService.get<MccItemRaw[]>(`${environment.cardsEndpoint}/mcc`).pipe(
      map((result) => {
        return result.map((item) => ({
          label: `${item.mcc}: ${item.mccDescription}`,
          value: `${item.mcc}`,
        }));
      }),
      catchError((errorRes) => throwError(() => errorRes)),
    );
  }
}
