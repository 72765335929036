import { Component, OnInit } from '@angular/core';
import { ActiveModal } from '@rocketfinancialcorp/rocket-ui/modal';
import { DatatableColumn } from '@rocketfinancialcorp/rocket-ui/table';
import { NotificationService, ReportService } from '@shared/services';
import { MESSAGE } from '@shared/constants';
import { isEmpty } from 'lodash-es';
import { AskMeAnythingActions, fromAskMeAnything, fromAuth } from '@shared/store';
import { Store } from '@ngrx/store';
import { Router } from '@angular/router';
import { ErrorUtils, toTitleCase } from '@shared/utils';
import { Subject, takeUntil } from 'rxjs';
import { AskMeAnythingQuestion } from '@shared/models';

interface AISearchResult {
  [key: string]: number | string;
}

@Component({
  selector: 'app-natural-language-search-modal',
  moduleId: module.id,
  templateUrl: 'natural-language-search-modal.component.html',
  styleUrls: ['natural-language-search-modal.component.scss'],
})
export class NaturalLanguageSearchModalComponent implements OnInit {
  activeUser$ = this.store.select(fromAuth.selectUser);

  askMeAnythingQuestions$ = this.store.select(fromAskMeAnything.selectAskMeAnythingQuestions);

  searchString?: string;

  searchedQuery?: string;

  searchErrorText!: string;

  downloadResultURL!: string | undefined;

  insightSummary!: string | undefined;

  showTableData!: boolean;

  searchResults: AISearchResult[] = [];

  loading = false;

  columns: DatatableColumn[] = [];

  askMeAnythingQuestions: AskMeAnythingQuestion[] = [];

  sampleQueryTexts: string[] = [
    'What is my monthly transaction volume and amount?',
    'How many of my transactions are currently pending?',
    'How many customers do I have?',
    'How many financial accounts do I have?',
  ];

  get maxRecordCountReached(): boolean {
    return this.totalElements === 200;
  }

  get totalElements(): number {
    return this.searchResults.length;
  }

  get downloadResultEnabled(): boolean {
    return !isEmpty(this.downloadResultURL);
  }

  private destroy$ = new Subject<void>();

  constructor(
    public activeModal: ActiveModal,
    private notificationService: NotificationService,
    private reportService: ReportService,
    private store: Store,
    private router: Router,
  ) {
    this.store
      .select(fromAskMeAnything.selectAskMeAnythingQuestions)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (askMeAnythingQuestions) => {
          this.askMeAnythingQuestions = askMeAnythingQuestions;
        },
      });
  }

  ngOnInit() {
    this.store.dispatch(AskMeAnythingActions.loadAskMeAnythingQuestions());
  }

  getAISearchResults() {
    if (!this.searchString) return;

    this.searchReset();

    this.loading = true;

    this.reportService.getAISearchResults(this.searchString).subscribe({
      next: (response) => {
        if (response?.results) {
          this.searchResults = response.results;
          this.columns = this.mapObjectColumn(response.results);
        }
        this.searchedQuery = this.searchString;
        this.downloadResultURL = response.presignedUrlForCsvResults;
        this.insightSummary = this.formatInsightSummary(response.summaryOfResults);
        this.searchErrorText = 'No search results. Try entering another search query.';
        this.loading = false;
        this.searchString = undefined;
      },
      error: (error) => {
        console.warn('error', error);
        this.searchErrorText = MESSAGE.UNKNOWN_ERROR;
        this.loading = false;
      },
    });
  }

  searchReset() {
    this.searchErrorText = '';
    this.searchResults = [];
    this.columns = [];
    this.downloadResultURL = undefined;
    this.searchedQuery = undefined;
  }

  downloadCSVResult() {
    if (this.downloadResultURL) {
      window.open(this.downloadResultURL, '_self');
    }
  }

  updateSearchString(queryText: string) {
    this.searchString = queryText;
  }

  formatInsightSummary(summaryOfResults: string | undefined): string | undefined {
    if (!summaryOfResults) return;

    return `<ul class="summary-result-list">${summaryOfResults.replace(/•/g, '<li>•')}</ul>`;
  }

  viewEntityDetails(entityData: AISearchResult): void {
    const urlPath = this.getEntityRouteInformation(entityData);
    const relativeURL = this.router.createUrlTree([urlPath]);
    const basePath = window.location.href.replace(this.router.url, '');

    window.open(basePath + relativeURL, '_blank');
  }

  mapObjectColumn(searchResponse: AISearchResult[]): DatatableColumn[] {
    const datatableColumn: DatatableColumn[] = [];

    Object.keys(searchResponse[0]).map((key) => {
      const dataEntry: DatatableColumn = {
        name: toTitleCase(key),
        prop: key,
        flexSize: 200,
        canAutoResize: true,
        minWidth: 150,
        maxWidth: 300,
        colType: 'text',
        fixedSize: 0,
      };

      if (['created_at', 'updated_at'].includes(key)) {
        dataEntry.colType = 'date-time';
        dataEntry.fixedSize = 150;
      }

      if (['customer_id', 'financial_account_id', 'recipient_id', 'transaction_id'].includes(key)) {
        dataEntry.colType = 'short-id';
        dataEntry.fixedSize = 150;
      }

      if (['latest_status', 'status'].includes(key)) {
        dataEntry.colType = 'status-tag';
      }

      if (key.includes('amount')) {
        dataEntry.colType = 'amount';
      }

      if (key.includes('email') || key.includes('name')) {
        dataEntry.fixedSize = 250;
      }

      datatableColumn.push(dataEntry);
    });

    return datatableColumn;
  }

  private getEntityRouteInformation(entityData: AISearchResult): string | undefined {
    const entityIdKey = Object.keys(entityData).find((key) => key.includes('_id'));

    if (!entityIdKey) return;

    let routeURL = `/app/`;

    if (entityIdKey === 'financial_account_id') {
      routeURL += `settings/financial-accounts/${entityData[entityIdKey]}/account-details`;
    }

    if (entityIdKey === 'recipient_id') {
      routeURL += `recipients/${entityData[entityIdKey]}/profile`;
    }

    if (entityIdKey === 'transaction_id') {
      routeURL += `transactions/transactions/${entityData[entityIdKey]}`;
    }

    if (entityIdKey === 'customer_id') {
      const customerType = entityData['type'] === 'INDIVIDUAL' ? 'individuals' : 'businesses';
      routeURL += `customers/${customerType}/${entityData[entityIdKey]}/profile`;
    }

    return routeURL;
  }

  markQuestionAsFavourite(searchString: string | undefined) {
    if (!searchString || this.isFavouriteQuestion(searchString)) return;

    this.reportService
      .addAskMeAnythingQuestion({ query: searchString })
      .pipe()
      .subscribe({
        next: () => {
          this.store.dispatch(AskMeAnythingActions.loadAskMeAnythingQuestions());
        },
      });
  }

  isFavouriteQuestion(searchString: string | undefined): boolean {
    if (!searchString) return false;

    return !!this.askMeAnythingQuestions.find((questions) => questions.queryText === searchString);
  }

  deleteFavouriteQuestion(askMeAnythingQuestion: AskMeAnythingQuestion) {
    const { id } = askMeAnythingQuestion;
    this.reportService.deleteAskMeAnythingQuestion(id).subscribe({
      next: () => this.store.dispatch(AskMeAnythingActions.loadAskMeAnythingQuestions()),
      error: (error) => this.onDeleteQuestionFailure(error),
    });
  }

  private onDeleteQuestionFailure(error?: number): void {
    this.notificationService.displayError('Unable to delete question', 'Error');
    ErrorUtils.catchError('reportService.deleteAskMeAnythingQuestion', error);
  }
}
