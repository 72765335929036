import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { compact } from 'lodash-es';
import { TeamMemberRaw, User, UserRaw } from '@shared/models';
import { BackendService } from '@shared/services/backend.service';
import { environment } from '@env';
import { Patterns } from '@shared/components';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(private backendService: BackendService) {}

  getUserDetails(): Observable<User> {
    return this.backendService.get<UserRaw>(`${environment.accountFlowService}/employees/quick-info`).pipe(
      map((userDetails) => {
        const name = compact([userDetails?.firstName, userDetails?.lastName]).join(' ');
        const initials = (name.match(Patterns.INITIALS) ?? []).join('');

        return { ...userDetails, name, initials };
      }),
      catchError((errorRes) => throwError(() => errorRes)),
    );
  }

  getUserFullDetails({ businessAccountId, userId }: { businessAccountId: string; userId: string }): Observable<TeamMemberRaw> {
    return this.backendService.get<TeamMemberRaw>(
      `${environment.accountFlowService}/business-accounts/${businessAccountId}/employees/${userId}`,
    );
  }
}
