import { AfterViewInit, ChangeDetectorRef, Component, OnDestroy } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { formInput, formRow, formTextarea } from '@rocketfinancialcorp/rocket-ui/form';
import { ActiveModal } from '@rocketfinancialcorp/rocket-ui/modal';
import { Patterns } from '@shared/components/entity';
import { ALLOWED_ATTACHMENT_FILE_FORMATS } from '@shared/constants';
import { FormModel } from '@shared/models';
import { AttachmentCreateModel } from '@shared/models/attachments';

@Component({
  selector: 'app-attachment-create-form',
  templateUrl: './attachment-create-form.component.html',
  styleUrls: ['./attachment-create-form.component.scss'],
})
export class AttachmentCreateFormComponent implements OnDestroy, AfterViewInit {
  formModel: FormModel<AttachmentCreateModel> = {};

  attachmentForm = new FormGroup({});

  allowedFileFormats = ALLOWED_ATTACHMENT_FILE_FORMATS;

  attachmentFields = [
    formRow([
      formInput({
        key: 'name',
        label: 'Name',
        props: {
          pattern: Patterns.GENERIC_NAME,
          maxLength: 100,
        },
        validation: { messages: { pattern: 'Enter a valid Attachment Name' } },
      }),
    ]),
    formRow([
      formTextarea({
        key: 'description',
        label: 'Description',
        props: {
          required: false,
          maxLength: 1000,
        },
      }),
    ]),
  ];

  attachmentUploadData?: File | null;

  attachmentType!: string;

  isLoading = false;

  isEditMode = false;

  constructor(public activeModal: ActiveModal, private ref: ChangeDetectorRef) {}

  ngAfterViewInit(): void {
    this.ref.detectChanges();
  }

  ngOnDestroy(): void {
    this.attachmentForm.markAsUntouched();
  }

  onFileUploaded(file: File | null): void {
    if (!file && this.formModel.isFileUploaded) {
      this.formModel = {
        ...this.formModel,
        isFileUploaded: false,
      };
    }
    if (!file) {
      this.attachmentUploadData = null;
    } else {
      this.attachmentUploadData = file;
      this.formModel = {
        ...this.formModel,
        name: this.formModel.name ?? file?.name,
      };
    }
  }

  onSubmit(): void {
    if (!this.attachmentUploadData) {
      return;
    }
    const extension = this.attachmentUploadData?.name.match(/^.*\.(?<extension>[a-z]+)$/i);
    const attachmentData: Partial<AttachmentCreateModel> = {
      ...this.formModel,
      file: this.attachmentUploadData,
      extension: extension?.groups?.extension.toUpperCase(),
    };

    this.activeModal.close(attachmentData);
  }
}
