<div class="status-select">
  <div class="rkt-form">
    <div class="rkt-form-control">
      <div class="rkt-form-field">
        <label class="rkt-form-label" [for]="selectId">Status</label>
        <div class="rkt-form-select-field">
          <ng-select
            #select
            class="rkt-form-select rkt-form-select-status-dropdown"
            [items]="items"
            placeholder=""
            [virtualScroll]="false"
            [tabIndex]="0"
            [(ngModel)]="selectedItem"
            bindLabel="label"
            bindValue="value"
            [clearable]="false"
            [multiple]="false"
            [searchable]="false"
            (change)="onChange($event)"
            [closeOnSelect]="true"
            [minTermLength]="0"
            appendTo="body"
            [maxSelectedItems]="1"
            [disabled]="disabled"
            (open)="onOpen()"
            (close)="onClose()"
          >
            <ng-template ng-label-tmp let-item="item" let-clear="clear">
              <div class="option-label">
                <div class="status-tag" [class.no-value]="!item?.label" [ngClass]="'status-tag-' + item?.label?.toLowerCase()">
                  {{ item?.label | titlecase }}
                </div>
              </div>
            </ng-template>

            <ng-template ng-notfound-tmp>
              <div class="ng-option ng-option-disabled">
                <div class="option-content">No items found</div>
              </div>
            </ng-template>
          </ng-select>
        </div>
      </div>
    </div>
  </div>
</div>
