<div class="list-page-header" *ngIf="isShowControls">
  <div class="list-filters">
    <button type="button" class="table-header-filter-btn" (click)="filterMenu.toggle($event)" #filterBtn>
      <rkt-icon name="filter" class="table-header-filter-icon"></rkt-icon>
      Filter
      <span class="table-header-filter-counter" *ngIf="activeFiltersCount">{{ activeFiltersCount }}</span>
    </button>

    <app-filter-menu
      #filterMenu
      [initialValues]="activeFilters"
      [filters]="filters"
      (opened)="filterBtn.classList.add('opened')"
      (closed)="filterBtn.classList.remove('opened')"
      (saved)="applyFilters($event)"
    ></app-filter-menu>
  </div>

  <div class="list-page-controls">
    <button
      rktButton
      *ngIf="createIntegratedFaFlag$ | async"
      btnType="secondary"
      (click)="addAccountStep()"
      title="Add Financial Account"
      class="add-entity-btn"
      [disabled]="!!(loading | accessControl | async)"
    >
      <rkt-icon name="plus"></rkt-icon>
      Add Financial Account
    </button>
  </div>
</div>

<rkt-table
  [rows]="financialAccounts"
  [columns]="columns"
  [limit]="10"
  (fetchData)="getFinancialAccounts($event)"
  (rowActivated)="viewAccount($event)"
  [count]="totalElements"
  [loadingIndicator]="loading"
  [offset]="activePage"
  [messages]="{ emptyMessage: 'No Financial Accounts found.' }"
  emptyValuePlaceholder="--"
></rkt-table>
