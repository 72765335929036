<ng-container *ngIf="createTransactionReviewData$ | async as reviewData">
  <div *ngIf="createdTransactionId">
    <div class="modal-icon" [ngClass]="'modal-icon-' + (reviewData.scheduleDateTime ? 'scheduled' : 'success')"></div>

    <ng-container *ngIf="reviewData.transactionType === 'MLT'">
      <div class="modal-title" *ngIf="isUpdate">Multi-Leg Transaction Updated!</div>

      <div class="modal-title" *ngIf="!isUpdate">
        {{ reviewData?.scheduleDateTimeDisplayValue ? 'Multi-Leg Transaction Scheduled!' : 'Multi-Leg Transaction Created!' }}
      </div>
    </ng-container>

    <ng-container *ngIf="reviewData.transactionType === 'SLT'">
      <div class="modal-title" *ngIf="isUpdate">Transaction Updated!</div>

      <div class="modal-title" *ngIf="!isUpdate">
        {{ reviewData?.scheduleDateTimeDisplayValue ? 'Transaction Scheduled!' : 'Transaction Created!' }}
      </div>
    </ng-container>

    <div class="transaction-id">Transaction # {{ createdTransactionId | rktShortId }}</div>
  </div>

  <div *ngIf="!createdTransactionId" class="review-header">
    <div class="review-title">Review the transaction</div>

    <div class="multi-leg-title" *ngIf="reviewData.transactionType === 'MLT'">
      <div class="multi-leg-title-icon"></div>
      This is a Multi-Leg Transaction
    </div>
  </div>

  <div class="separator-line"></div>

  <div class="amount">
    <ng-container>
      <ng-container *ngIf="reviewData.amountOption === 'EXACT_AMOUNT'">
        <div class="label">Amount</div>
        <div class="value">
          {{ reviewData.amount || '' | amount: reviewData.currency || undefined }}
        </div>
      </ng-container>

      <ng-container *ngIf="reviewData.amountOption === 'PERCENTAGE'">
        <div class="label">Percentage</div>
        <div class="value">{{ reviewData.amount }}%</div>
      </ng-container>
    </ng-container>

    <div class="scheduler-name" *ngIf="reviewData.name">
      {{ reviewData.name }}
    </div>
  </div>

  <div class="schedule-preview" *ngIf="reviewData.scheduleDateTime">
    <div class="scheduled-for">
      <div class="schedule-icon"></div>

      <div class="scheduled-for-label">Scheduled for:</div>

      <span class="scheduled-date-time">{{ reviewData.scheduleDateTimeDisplayValue }}</span>
    </div>

    <div class="schedule-recurring" *ngIf="reviewData.scheduleRecurringDisplayValue as scheduleRecurring">
      Recurrence: {{ scheduleRecurring }}
    </div>
  </div>

  <div class="financial-accounts-container">
    <ng-container
      *ngTemplateOutlet="
        financialAccountView;
        context: {
          $implicit: reviewData.fromAccount,
          method: reviewData.fromMethod,
          achDetails: reviewData.fromAchDetails,
          wireDetails: reviewData.fromWireDetails,
          paymentReasonName: reviewData.fromPaymentReason,
          processingPriority: reviewData.fromProcessingPriority,
          label: 'From Account'
        }
      "
    ></ng-container>

    <div class="financial-accounts-icon"></div>

    <ng-container
      *ngTemplateOutlet="
        financialAccountView;
        context: {
          $implicit: reviewData.toAccount,
          method: reviewData.toMethod,
          achDetails: reviewData.toAchDetails,
          wireDetails: reviewData.toWireDetails,
          paymentReasonName: reviewData.toPaymentReason,
          processingPriority: reviewData.toProcessingPriority,
          label: 'To Account'
        }
      "
    ></ng-container>
  </div>

  <div class="separator-line"></div>

  <div class="additional-details">
    <div class="additional-details-title">
      {{ reviewData?.deliveryOption?.label }}
    </div>
    <div class="additional-details-sections">
      <div class="additional-details-inner">
        <div class="details-item">
          <div class="details-label">Payment Reason</div>
          <div class="details-value">{{ reviewData.toPaymentReason || reviewData.fromPaymentReason | notAvailable }}</div>
        </div>

        <ng-container *ngIf="reviewData.fromMethod === 'wire' && reviewData?.fromWireDetails as wireDetails">
          <div class="details-item">
            <div class="details-label">Narrative:</div>
            <div class="details-value">{{ wireDetails.description }}</div>
          </div>

          <div class="details-item">
            <div class="details-label">Memo:</div>
            <div class="details-value">
              <div *ngIf="wireDetails.memo; else noValue">{{ wireDetails.memo }}</div>
            </div>
          </div>
        </ng-container>

        <ng-container
          *ngIf="
            (reviewData.toMethod === 'ach' && reviewData?.toAchDetails) ||
            (reviewData.fromMethod === 'ach' && reviewData?.fromAchDetails) as achDetails
          "
        >
          <div class="details-item" *ngIf="achDetails.rkorACHEntryType">
            <div class="details-label">SEC Code:</div>
            <div class="details-value">{{ achDetails.rkorACHEntryType }}</div>
          </div>

          <div class="details-item" *ngIf="achDetails.rkorACHIndividualId">
            <div class="details-label">Individual ID:</div>
            <div class="details-value">{{ achDetails.rkorACHIndividualId }}</div>
          </div>

          <div class="details-item" *ngIf="achDetails.rkorACHCheckSerialNumber">
            <div class="details-label">Check Serial Number:</div>
            <div class="details-value">{{ achDetails.rkorACHCheckSerialNumber }}</div>
          </div>

          <div class="details-item" *ngIf="achDetails.rkorACHTerminalCity">
            <div class="details-label">Terminal City:</div>
            <div class="details-value">{{ achDetails.rkorACHTerminalCity }}</div>
          </div>

          <div class="details-item" *ngIf="achDetails.rkorACHTerminalState">
            <div class="details-label">Terminal State:</div>
            <div class="details-value">{{ achDetails.rkorACHTerminalState }}</div>
          </div>
        </ng-container>

        <ng-container *ngIf="reviewData.toMethod === 'wire' && reviewData?.toWireDetails as wireDetails">
          <div class="details-item">
            <div class="details-label">Narrative:</div>
            <div class="details-value">{{ wireDetails.description }}</div>
          </div>

          <div class="details-item">
            <div class="details-label">Memo:</div>
            <div class="details-value">
              <div *ngIf="wireDetails.memo; else noValue">{{ wireDetails.memo }}</div>
            </div>
          </div>
        </ng-container>
      </div>
      <div class="additional-details-inner">
        <div class="details-item">
          <div class="details-label">Funds Delivery Estimate</div>
          <div class="details-value">
            {{ reviewData?.deliveryOption?.estLabelDay }} – {{ reviewData?.deliveryOption?.estLabelDate }}
            <div class="details-value-estimation">
              We estimate the funds will settle at the receiving institution in the
              <span class="strong">{{ reviewData?.deliveryOption?.estLabelTime }}</span> of
              <span class="strong">{{ reviewData?.deliveryOption?.estLabelDate }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="separator-line"></div>

  <div class="notes" *ngIf="reviewData.note">
    <div class="notes-label">Notes</div>
    <div class="notes-note">{{ reviewData.note }}</div>
  </div>

  <div class="buttons" [class.editing]="isUpdate" *ngIf="createdTransactionId">
    <button *ngIf="!isUpdate" rktButton btnType="secondary" title="Create new transaction" (click)="onCreateNewTransactionBtnClick()">
      Create new transaction
    </button>

    <button rktButton title="Done" (click)="onDoneBtnClick(reviewData.transactionType === 'MLT', !!reviewData.scheduleDateTime)">
      Done
    </button>
  </div>

  <div class="buttons" *ngIf="!createdTransactionId">
    <button rktButton btnType="secondary" title="Back to Edit" (click)="activeModal.close({ action: 'EDIT' })">Back to Edit</button>

    <button rktButton title="Confirm" title="Confirm" (click)="onSubmit()" [disabled]="submitLoading" [class.loading]="submitLoading">
      <span class="btn-text">Confirm</span>
      <span *ngIf="submitLoading" class="btn-spinner"><span class="spinner-grow" role="status" aria-hidden="true"></span></span>
    </button>
  </div>
</ng-container>

<ng-template #noValue>
  <div class="empty-value">--</div>
</ng-template>

<ng-template
  #financialAccountView
  let-financialAccount
  let-method="method"
  let-achDetails="achDetails"
  let-wireDetails="wireDetails"
  let-paymentReasonName="paymentReasonName"
  let-processingPriority="processingPriority"
  let-label="label"
>
  <div class="financial-account">
    <div class="financial-account-title">{{ label }}</div>
    <div class="rows" *ngIf="financialAccount">
      <div class="row">
        <div class="label">Account Holder:</div>
        <div class="value">
          <div [class.empty-value]="!(financialAccount.accountHolderInfo | rktSplit: '::':0)">
            {{ financialAccount.accountHolderInfo | rktSplit: '::':0 | notAvailable: '--' }}
          </div>
          <div [class.empty-value]="!(financialAccount.accountHolderInfo | rktSplit: '::':1)">
            {{ financialAccount.accountHolderInfo | rktSplit: '::':1 | notAvailable: '--' }}
          </div>
        </div>
      </div>

      <div class="row">
        <div class="label">Account Info:</div>
        <div class="value">
          <div [class.empty-value]="!(financialAccount.accountInfo | rktSplit: '::':0)">
            {{ financialAccount.accountInfo | rktSplit: '::':0 | notAvailable: '--' }}
          </div>
          <div [class.empty-value]="!(financialAccount.accountInfo | rktSplit: '::':1)">
            {{ financialAccount.accountInfo | rktSplit: '::':1 | notAvailable: '--' }}
          </div>
        </div>
      </div>

      <div class="row">
        <div class="label">Account Category:</div>
        <div class="value">{{ financialAccount.accountCategory }}</div>
      </div>

      <div class="row" *ngIf="financialAccount.accountCategory !== 'EXTERNAL'">
        <div class="label">Available Balance:</div>
        <div class="value">
          <div *ngIf="financialAccount.availableBalance !== undefined; else noValue">
            {{ financialAccount.currency | currencySign }}
            {{ (financialAccount.availableBalance | number: '1.2-2') || '' | mask: 'separator':',' }}
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
