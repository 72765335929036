<div class="modal-title">Exception History</div>

<div class="exception-history-items">
  <div *ngFor="let item of historyItems$ | async" class="history-item">
    <div class="history-item-date">{{ item.createdAt | dateFormat }}</div>
    <div class="history-item-descr">
      <strong>{{ item.type.replace('_', ' ') | titlecase }}</strong> {{ item.newValue ? 'changed' : 'made' }} by
      <strong>{{ item.updatedBy.id | teamMemberName | async }}</strong>
    </div>
  </div>
</div>

<div class="empty-list-message" *ngIf="!(historyItems$ | async)?.length">No Exception History yet.</div>

<div class="modal-buttons">
  <button rktButton title="Close" (click)="activeModal.close(true)">Close</button>
</div>
