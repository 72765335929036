<h1 class="title">Update Scheduled Report</h1>

<button class="close-button" (click)="activeModal.close()"><rkt-icon name="times"></rkt-icon></button>

<div class="details-page-edit-form">
  <form [formGroup]="updateReportForm">
    <rkt-form [form]="updateReportForm" [fields]="updateFormFields" [model]="reportModel"></rkt-form>
  </form>
</div>

<div *ngIf="submitError" class="details-page-edit-form-error">{{ submitError }}</div>

<div class="buttons">
  <button rktButton type="button" btnType="tertiary" (click)="activeModal.close()">Cancel</button>
  <button rktButton type="submit" (click)="onSave()" [disabled]="isLoading || updateReportForm.invalid">Save</button>
</div>
