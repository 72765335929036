import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CanActivate, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { map } from 'rxjs/operators';
import { fromAuth } from '@shared/store';
import { constants, READ_ONLY_ROLES } from '@shared/constants';

@Injectable({
  providedIn: 'root',
})
export class AccessControlGuard implements CanActivate {
  router = inject(Router);

  store = inject(Store);

  userRole$ = this.store.select(fromAuth.selectUserRole);

  canActivate(): Observable<boolean> {
    return this.userRole$.pipe(
      map((role) => {
        if (READ_ONLY_ROLES.includes(role)) {
          this.router.navigate([constants.HOME_ROUTE]);
          return false;
        }

        return true;
      }),
    );
  }
}
