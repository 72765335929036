import { Component, inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { ActiveModal } from '@rocketfinancialcorp/rocket-ui/modal';
import { exceptionManagementFeature } from '@shared/store';

@Component({
  templateUrl: './exception-history-modal.component.html',
})
export class ExceptionHistoryModalComponent {
  activeModal = inject(ActiveModal);

  store = inject(Store);

  historyItems$ = this.store.select(exceptionManagementFeature.selectExceptionHistoryItems);
}
