<ng-container *ngIf="beneficiary; else beneficiaryLoading">
  <ng-container *ngTemplateOutlet="beneficiaryDetails; context: { $implicit: beneficiary, loading: false }"></ng-container>
</ng-container>
<ng-template #beneficiaryLoading>
  <ng-container *ngTemplateOutlet="beneficiaryDetails; context: { $implicit: null, loading: true }"></ng-container>
</ng-template>

<ng-template #beneficiaryDetails [appTemplateContext]="beneficiaryDetailsContext" let-beneficiary let-loading="loading">
  <app-details-account-verification
    [verificationStatus]="beneficiary?.verificationStatus"
    [verificationStatusHistory]="beneficiary?.verificationStatusHistory"
  ></app-details-account-verification>

  <div class="entity-details-container profile-details-container">
    <div class="details-section-title">
      <div class="title"><rkt-icon name="profile"></rkt-icon> Beneficial Owner Information</div>

      <div class="controls">
        <button
          rktButton
          btnType="link"
          class="control-btn"
          (click)="onEditBeneficiaryBtnClick(beneficiary)"
          [disabled]="!!(loading | accessControl | async)"
        >
          Edit
        </button>
      </div>
    </div>

    <div class="details-section basic-info-section">
      <div class="id-row">
        <app-text-with-placeholder [showPlaceholder]="loading">ID: {{ beneficiary?.id || '' | rktShortId }}</app-text-with-placeholder>
      </div>

      <div class="details-row">
        <app-details-item
          [class]="pageClassPrefix + 'first-name'"
          [showPlaceholder]="loading"
          label="First Name"
          [value]="beneficiary?.firstName"
        ></app-details-item>

        <app-details-item
          [class]="pageClassPrefix + 'middle-name'"
          [showPlaceholder]="loading"
          label="Middle Name"
          [value]="beneficiary?.middleName"
        ></app-details-item>

        <app-details-item
          [class]="pageClassPrefix + 'last-name'"
          [showPlaceholder]="loading"
          label="Last Name"
          [value]="beneficiary?.lastName"
        ></app-details-item>

        <app-details-item
          [class]="pageClassPrefix + 'email'"
          [showPlaceholder]="loading"
          label="Email Address"
          [noBreak]="true"
          [value]="beneficiary?.email"
        ></app-details-item>

        <app-details-item
          [class]="pageClassPrefix + 'phone-number'"
          [showPlaceholder]="loading"
          label="Phone Number"
          [noBreak]="true"
          [value]="beneficiary?.phoneNumber | phone"
        ></app-details-item>

        <app-details-item
          [class]="pageClassPrefix + 'sex'"
          [showPlaceholder]="loading"
          label="Sex"
          [value]="beneficiaryGender"
        ></app-details-item>

        <app-details-item
          [class]="pageClassPrefix + 'date-of-birth'"
          [showPlaceholder]="loading"
          label="Date of Birth"
          [value]="beneficiary?.dateOfBirth | dateFormat: 'MM/dd/yy':'ISO'"
        ></app-details-item>

        <app-details-item
          [class]="pageClassPrefix + 'place-of-birth'"
          [showPlaceholder]="loading"
          label="Place of Birth"
          [value]="beneficiary?.placeOfBirthDisplayValue"
        ></app-details-item>

        <app-details-item
          [class]="pageClassPrefix + 'job-title'"
          [showPlaceholder]="loading"
          label="Job Title"
          [value]="beneficiary?.title"
        ></app-details-item>

        <app-details-item [class]="pageClassPrefix + 'controller'" [showPlaceholder]="loading" label="Controller" [contentValue]="true">
          <div class="controller-value">
            <rkt-icon [class.checked]="beneficiary?.controller" [name]="beneficiary?.controller ? 'check' : 'close'"></rkt-icon>
            {{ beneficiary?.controller ? 'Yes' : 'No' }}
          </div>
        </app-details-item>
      </div>

      <div class="details-separator"></div>

      <div class="details-row">
        <app-details-item
          [class]="pageClassPrefix + 'social-id'"
          [showPlaceholder]="loading"
          iconName="lock"
          label="Social ID (SSN)"
          [value]="beneficiary?.socialId"
        ></app-details-item>

        <app-details-item
          [class]="pageClassPrefix + 'social-id-country'"
          [showPlaceholder]="loading"
          label="Social ID Issuing Country"
          [value]="beneficiary?.socialIdCountryCode"
        ></app-details-item>

        <app-details-item
          [class]="pageClassPrefix + 'citizenship-country'"
          [showPlaceholder]="loading"
          label="Citizenship Country"
          [value]="beneficiary?.citizenshipCountryCode"
        ></app-details-item>

        <app-details-item
          [class]="pageClassPrefix + 'percentage-owned'"
          [showPlaceholder]="loading"
          label="Percentage of Business owned"
          [value]="beneficiary?.ownershipPercentage ? beneficiary?.ownershipPercentage + '%' : undefined"
        ></app-details-item>
      </div>

      <div class="details-separator"></div>

      <app-address-view [isLoading]="loading" [addresses]="addresses"></app-address-view>

      <div class="details-separator"></div>

      <div class="create-update-section">
        <div class="details-row">
          <app-details-item
            [class]="pageClassPrefix + 'created-at'"
            [showPlaceholder]="loading"
            label="Created At"
            [value]="beneficiary?.createdAt | dateFormat"
          ></app-details-item>

          <app-details-item
            [class]="pageClassPrefix + 'created-by'"
            [showPlaceholder]="loading"
            label="Created By"
            [value]="beneficiary?.createdBy?.id | teamMemberName | async"
          ></app-details-item>
        </div>

        <div class="details-row">
          <app-details-item
            [class]="pageClassPrefix + 'updated-at'"
            [showPlaceholder]="loading"
            label="Updated At"
            [value]="beneficiary?.updatedAt | dateFormat"
          ></app-details-item>

          <app-details-item
            [class]="pageClassPrefix + 'updated-by'"
            [showPlaceholder]="loading"
            label="Updated By"
            [value]="beneficiary?.updatedBy?.id | teamMemberName | async"
          ></app-details-item>
        </div>
      </div>
    </div>
  </div>
</ng-template>
