import { Injectable } from '@angular/core';
import { BehaviorSubject, catchError, Observable, of, switchMap, map, throwError, forkJoin, tap } from 'rxjs';
import { environment } from '@env';
import {
  FilterValues,
  RequestPageParams,
  LinxRequestList,
  LinxRequestPayload,
  LinxRequestDetails,
  LinxDocuments,
  LinxRequestCreateResponse,
  LinxRequestExtendExpiry,
  LinxRequestNotification,
  LinxTemplateCreationPayload,
  LinxTemplateCreationResponse,
  LinxTemplateDetails,
  LinxTemplateList,
} from '@shared/models';
import { BackendService } from '@shared/services/backend.service';
import { StorageService } from '../storage/storage.service';
import { constants } from '@shared/constants';
import { CustomHttpParamEncoder } from '@shared/encoder';
import { HttpContext, HttpHeaders, HttpParams } from '@angular/common/http';
import { TeamMemberService } from '../team-member/team-member.service';
import { CUSTOM_ERROR_MESSAGE, IS_EXTENDED_ERRORS } from '@shared/interceptors';
import { v4 as uuidv4 } from 'uuid';
import { compact } from 'lodash-es';
import { Store } from '@ngrx/store';
import { AuditActions } from '@shared/store';
@Injectable({
  providedIn: 'root',
})
export class LinxService {
  linxListFilter = new BehaviorSubject<FilterValues>({});

  constructor(
    private backendService: BackendService,
    private storageService: StorageService,
    private teamMemberService: TeamMemberService,
    private store: Store,
  ) {}

  public setLinxFilterParams(filters: FilterValues): void {
    this.storageService.setItem(constants.LINX_REQUESTS_FILTER, filters);
    this.updateLinxFilterParams(filters);
  }

  public getLinxFilterParams(): void {
    const filters = this.storageService.getItem<FilterValues>(constants.LINX_REQUESTS_FILTER);

    if (filters && Object.keys(filters).length) {
      this.updateLinxFilterParams(filters);
    }
  }

  private updateLinxFilterParams(filters: FilterValues): void {
    this.linxListFilter.next(filters);
  }

  getLinxWorkflows(requestParams: RequestPageParams): Observable<LinxRequestList> {
    const { page, size, searchString, sortParams, activeFilters } = requestParams;
    let params = new HttpParams({ encoder: new CustomHttpParamEncoder() });

    params = params.append('size', size ?? constants.TABLE_ROWS);

    if (page) {
      params = params.append('page', page);
    }

    if (sortParams) {
      params = params.append('sort', `${sortParams.key},${sortParams.sortDir}`);
    }

    if (searchString) {
      params = params.append('search', searchString);
    }

    if (activeFilters) {
      const filterByAttributes: string[] = [];
      Object.keys(activeFilters).forEach((key) => {
        if (key === 'templateId' || key === 'status') {
          params = params.append(key, activeFilters[key]!);
        } else {
          filterByAttributes.push(`${key}=${activeFilters[key]}`);
        }
      });

      if (filterByAttributes.length > 0) {
        params = params.append('filterByAttributes', filterByAttributes.join('&'));
      }
    }

    return this.backendService
      .get<LinxRequestList>(`${environment.linxEndpoint}`, { params })
      .pipe(catchError((errorRes) => throwError(() => errorRes)));
  }

  getLinxWorkflowDetails(workflowId: string): Observable<LinxRequestDetails> {
    return this.backendService.get<LinxRequestDetails>(`${environment.linxEndpoint}/${workflowId}`).pipe(
      tap({
        next: (linxRequestDetails) => {
          const { createdBy, updatedBy } = linxRequestDetails;
          this.store.dispatch(AuditActions.loadTeamMembers({ ids: compact([createdBy, updatedBy]) }));
        },
      }),
      switchMap((linxRequestDetails) =>
        this.getLinXNotificationHistory(linxRequestDetails.id).pipe(
          switchMap((notifications) => {
            return forkJoin(
              notifications.map((item) => {
                return this.teamMemberService.getEmployeeDetails(item.notifiedBy).pipe(
                  tap({
                    next: (user) => {
                      item.notifiedByUser = user;
                    },
                  }),
                  catchError(() => of('')),
                );
              }),
            ).pipe(map(() => notifications));
          }),
          map((notifications) => {
            return {
              ...linxRequestDetails,
              notifications: notifications,
            };
          }),
        ),
      ),
      catchError((errorRes) => throwError(() => errorRes)),
    );
  }

  getLinXNotificationHistory(workflowId: LinxRequestDetails['id']): Observable<LinxRequestNotification[]> {
    return this.backendService
      .get<LinxRequestNotification[]>(`${environment.linxEndpoint}//${workflowId}/notifications/history`)
      .pipe(catchError(() => of([])));
  }

  triggerLinxRequest(formData: LinxRequestPayload): Observable<LinxRequestCreateResponse> {
    let headers = new HttpHeaders();
    const idempotencyKey = uuidv4();
    headers = headers.set('Idempotency-Key', idempotencyKey);
    return this.backendService
      .post<LinxRequestCreateResponse>(`${environment.linxEndpoint}`, formData, { headers })
      .pipe(catchError((errorRes) => throwError(() => errorRes)));
  }

  updateLinXRequest(
    workflowId: LinxRequestDetails['id'],
    action: string,
    formData?: LinxRequestExtendExpiry,
  ): Observable<LinxRequestDetails> {
    return this.backendService
      .post<LinxRequestDetails>(`${environment.linxEndpoint}/${workflowId}/${action}`, formData)
      .pipe(catchError((errorRes) => throwError(() => errorRes)));
  }

  getLinXDocumentAcceptanceDetails(): Observable<LinxDocuments[]> {
    return this.backendService
      .get<LinxDocuments[]>(`${environment.documentAcceptanceService}/acceptances/documents/groups/details`, {
        context: new HttpContext().set(CUSTOM_ERROR_MESSAGE, 'Unable to fetch document Acceptance details.'),
      })
      .pipe(catchError((errorRes) => throwError(() => errorRes)));
  }

  createLinxTemplate(formData?: LinxTemplateCreationPayload): Observable<LinxTemplateCreationResponse> {
    let headers = new HttpHeaders();
    const idempotencyKey = uuidv4();
    headers = headers.set('Idempotency-Key', idempotencyKey);
    return this.backendService
      .post<LinxTemplateCreationResponse>(environment.linxTemplatesEndpoint, formData, {
        headers,
        context: new HttpContext().set(IS_EXTENDED_ERRORS, true),
      })
      .pipe(catchError((errorRes) => throwError(() => errorRes)));
  }

  updateLinxTemplate(formData: LinxTemplateCreationPayload, templateId: string): Observable<LinxTemplateCreationResponse> {
    return this.backendService
      .update<LinxTemplateCreationResponse>(`${environment.linxTemplatesEndpoint}/${templateId}`, formData, {
        context: new HttpContext().set(IS_EXTENDED_ERRORS, true).set(CUSTOM_ERROR_MESSAGE, 'Unable to update LinX template.'),
      })
      .pipe(catchError((errorRes) => throwError(() => errorRes)));
  }

  deleteLinXTemplate(templateId: LinxTemplateDetails['templateId']) {
    return this.backendService
      .delete<void>(`${environment.linxTemplatesEndpoint}/${templateId}`, {
        context: new HttpContext().set(CUSTOM_ERROR_MESSAGE, 'Unable to delete LinX template.'),
      })
      .pipe(catchError((errorRes) => throwError(() => errorRes)));
  }

  public updateTemplateStatus(templateId: LinxTemplateDetails['templateId'], status: string) {
    return this.backendService
      .post<void>(`${environment.linxTemplatesEndpoint}/${templateId}/${status}`, {
        context: new HttpContext().set(CUSTOM_ERROR_MESSAGE, 'Unable to update LinX template status.'),
      })
      .pipe(catchError((errorRes) => throwError(() => errorRes)));
  }

  getLinxTemplates(requestParams: RequestPageParams): Observable<LinxTemplateList> {
    const { page, size, searchString, sortParams, activeFilters } = requestParams;
    let params = new HttpParams({ encoder: new CustomHttpParamEncoder() });

    params = params.append('page', page ?? 0);

    params = params.append('size', size ?? constants.TABLE_ROWS);

    if (sortParams) {
      params = params.append('sort', `${sortParams.key},${sortParams.sortDir}`);
    }

    if (searchString) {
      params = params.append('search', searchString);
    }

    if (activeFilters) {
      Object.keys(activeFilters).forEach((key) => {
        params = params.append(key, activeFilters[key]!);
      });
    }

    return this.backendService
      .get<LinxTemplateList>(`${environment.linxTemplatesEndpoint}`, { params })
      .pipe(catchError((errorRes) => throwError(() => errorRes)));
  }

  getLinxTemplateDetails(templateId: string): Observable<LinxTemplateDetails> {
    return this.backendService.get<LinxTemplateDetails>(`${environment.linxTemplatesEndpoint}/${templateId}`).pipe(
      tap({
        next: (linxTemplateDetails) => {
          const { createdBy, updatedBy } = linxTemplateDetails;
          this.store.dispatch(AuditActions.loadTeamMembers({ ids: compact([createdBy, updatedBy]) }));
        },
      }),
      catchError((errorRes) => throwError(() => errorRes)),
    );
  }
}
