import { Component, OnInit } from '@angular/core';
import { ReportMenuOption } from '@shared/models';
import { Router } from '@angular/router';
import { NaturalLanguageSearchModalComponent } from '@shared/components';
import { ModalService } from '@rocketfinancialcorp/rocket-ui/modal';
import { InsightDocumentActions } from '@shared/store/insight-document-store/insight-document.actions';
import { Store } from '@ngrx/store';
import { fromInsightDocument } from '@shared/store';

@Component({
  selector: 'app-reports-page',
  templateUrl: './reports-page.component.html',
  styleUrls: ['./reports-page.component.scss'],
})
export class ReportsPageComponent implements OnInit {
  insightUnreadDocumentCount$ = this.store.select(fromInsightDocument.selectInsightUnreadDocuments);

  menuOptions: ReportMenuOption[] = [
    {
      icon: 'brainstorm',
      title: 'Ask me anything!',
      subtitle: 'What do you want to know today?',
      customClick: (e) => {
        e?.preventDefault();
        this.openSearchModal();
      },
    },
    {
      icon: 'graph-report',
      title: 'Schedule a Custom Report',
      subtitle: 'Stay up-to-date with the performance',
      customClick: (e) => {
        e?.preventDefault();
        this.viewScheduledReports();
      },
    },
  ];

  constructor(private router: Router, private modalService: ModalService, private store: Store) {}

  ngOnInit() {
    this.store.dispatch(InsightDocumentActions.loadInsightDocumentHistory());
  }

  viewReports() {
    this.router.navigate(['app/insights/documents']);
  }

  viewScheduledReports() {
    this.router.navigate(['app/insights/documents/scheduled-report/list']);
  }

  openSearchModal() {
    this.modalService.open(NaturalLanguageSearchModalComponent, {
      className: 'natural-language-search-modal',
    });
  }
}
