<div class="assignee-filter">
  <div class="rkt-form">
    <div class="rkt-form-control">
      <div class="rkt-form-field">
        <label class="rkt-form-label" [for]="selectId">{{ label }} <span *ngIf="required" class="asterisk"></span></label>
        <div class="rkt-form-select-field">
          <ng-select
            class="rkt-form-select rkt-form-select-assignee-dropdown rkt-form-assignee-filter-dropdown"
            [items]="items$ | async"
            placeholder="All Assignees"
            [virtualScroll]="false"
            [tabIndex]="0"
            [(ngModel)]="selectedItems"
            bindLabel="label"
            bindValue="value"
            [clearable]="false"
            [multiple]="true"
            [searchable]="true"
            (change)="onChange($event)"
            [closeOnSelect]="false"
            [typeahead]="assigneeInput$"
            [minTermLength]="0"
            appendTo="body"
            [maxSelectedItems]="maxSelectedItems ?? 1000"
            (open)="onOpen()"
            (close)="onClose()"
          >
            <ng-template ng-label-tmp let-item="item" let-clear="clear">
              <span class="rkt-value-label">Assignee <span class="indicator">1</span></span>
            </ng-template>

            <ng-template ng-multi-label-tmp let-items="items">
              <span class="rkt-value-label"
                >Assignee <span class="indicator">{{ items.length }}</span></span
              ></ng-template
            >

            <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index" let-disabled="disabled">
              <ng-container *ngTemplateOutlet="optionTemplate; context: { $implicit: item, item$: item$, index: index }"> </ng-container>
            </ng-template>

            <ng-template #optionTemplate let-item let-item$="item$" let-index="index">
              <div class="option-content" [class.disabled]="!item$.selected">
                <div class="option-checkbox">
                  <input hidden id="item-{{ index }}" type="checkbox" [ngModel]="item$.selected" />
                  <rkt-icon [name]="item$.selected ? 'checkbox-on' : 'checkbox-off'" class="rkt-value-checkbox-icon"></rkt-icon>
                </div>

                <div class="option-label">
                  <div class="employee-name">{{ item.label.split('::')[0] }}</div>
                  <div class="employee-email">{{ item.label.split('::')[1] }}</div>
                </div>
              </div>
            </ng-template>

            <ng-template ng-notfound-tmp>
              <div class="ng-option ng-option-disabled">
                <div class="option-content">No items found</div>
              </div>
            </ng-template>
          </ng-select>
        </div>
      </div>
    </div>
  </div>
</div>
