import { Pipe, PipeTransform } from '@angular/core';
import { systemTimeZone, toTitleCase } from '@shared/utils';

@Pipe({
  name: 'statusHistory',
})
export class StatusHistoryPipe implements PipeTransform {
  transform(value: string, attributeType?: string): string {
    let formattedOutput = value;

    if (attributeType === 'amount') {
      formattedOutput = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
      }).format(parseFloat(value));
    }

    if (attributeType?.includes('DateTime')) {
      formattedOutput = new Intl.DateTimeFormat('en-US', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        timeZone: systemTimeZone,
      }).format(new Date(value));
    }

    return formattedOutput;
  }
}
