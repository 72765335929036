import { AskMeAnythingQuestion, ErrorMessage } from '@shared/models';
import { createReducer, on } from '@ngrx/store';
import { AskMeAnythingActions } from '@shared/store/ask-me-anything-store/ask-me-anything.actions';

export const askMeQuestionFeatureKey = 'askMeAnythingQuestionList';

export interface AskMeAnythingQuestionState {
  askMeAnythingQuestions: AskMeAnythingQuestion[];
  isLoading: boolean;
  error: ErrorMessage;
}

export const initialAskMeAnythingQuestionState: AskMeAnythingQuestionState = {
  askMeAnythingQuestions: [],
  isLoading: false,
  error: null,
};

export const reducer = createReducer(
  initialAskMeAnythingQuestionState,
  on(AskMeAnythingActions.loadAskMeAnythingQuestions, (state): AskMeAnythingQuestionState => {
    return {
      ...state,
      askMeAnythingQuestions: [],
      isLoading: true,
      error: null,
    };
  }),
  on(AskMeAnythingActions.loadAskMeAnythingQuestionSuccess, (state, { askMeAnythingQuestions }): AskMeAnythingQuestionState => {
    return {
      ...state,
      askMeAnythingQuestions,
      isLoading: false,
      error: null,
    };
  }),
  on(AskMeAnythingActions.loadAskMeAnythingQuestionFailure, (state, action): AskMeAnythingQuestionState => {
    return {
      ...state,
      isLoading: false,
      error: action.error,
    };
  }),
);

export const getAskMeAnythingQuestions = (state: AskMeAnythingQuestionState) => state.askMeAnythingQuestions;
export const getLoading = (state: AskMeAnythingQuestionState) => state.isLoading;
