<div *ngIf="showModalHeader">
  <h1 class="title">{{ documentName || 'View Document' }}</h1>

  <button class="close-button" (click)="activeModal.close()"><rkt-icon name="times"></rkt-icon></button>
</div>

<div *ngIf="documentURL">
  <ngx-extended-pdf-viewer
    [src]="documentURL"
    [useBrowserLocale]="true"
    [authorization]="true"
    backgroundColor="#F7F7F7"
    [showToolbar]="true"
    [showSidebarButton]="false"
    [showFindButton]="false"
    [showPagingButtons]="false"
    [showDrawEditor]="false"
    [showTextEditor]="false"
    [showZoomButtons]="showZoomButton"
    [showPresentationModeButton]="false"
    [showOpenFileButton]="false"
    [showPrintButton]="showPrintButton"
    [showDownloadButton]="showDownloadButton"
    [showSecondaryToolbarButton]="true"
    [showHandToolButton]="false"
    [showScrollingButton]="false"
    [showSpreadButton]="false"
    [showPropertiesButton]="false"
    [showRotateButton]="false"
    [height]="containerHeight"
    [textLayer]="false"
  >
  </ngx-extended-pdf-viewer>
</div>
