<div class="tab-nav-items" [class.no-horizontal-offset]="noHorizontalOffset">
  <div class="tab-nav-item" *ngFor="let tab of tabItems">
    <a
      [routerLinkActive]="'active'"
      [routerLink]="tab.href"
      class="tab-nav-link"
      [routerLinkActiveOptions]="{ exact: tab.isExact || false }"
    >
      <div [ngClass]="tab.isHighlighted ? 'highlighted' : ''">{{ tab.label }}</div>
    </a>
  </div>
</div>
