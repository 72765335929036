import { Component, EventEmitter, HostBinding, Input, OnChanges, Output, SimpleChanges, AfterViewInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { isEmpty, uniqueId } from 'lodash-es';
import { FilterField, FilterValue } from '@shared/models';
import { getFormattedAmount } from '@shared/utils';

@Component({
  selector: 'app-filter-amount',
  templateUrl: './filter-amount.component.html',
  styleUrls: ['./filter-amount.component.scss'],
})
export class FilterAmountComponent implements OnChanges, AfterViewInit {
  @HostBinding('class.rkt-filter-item-container') commonClass = true;

  @Input() filterName?: string;

  @Input() selectedValue?: FilterValue;

  @Input() defaultValue?: string;

  @Input() props?: FilterField['props'];

  @Input() isCollapsable: boolean = true;

  @Output() changed = new EventEmitter<string>();

  @Output() errored = new EventEmitter<boolean>();

  @Output() filterToggled = new EventEmitter<void>();

  isCollapsed: boolean = true;

  id = uniqueId();

  amountTypeOptions = [
    { label: 'Credits', value: 'CREDIT' },
    { label: 'Debits', value: 'DEBIT' },
  ];

  amountType = new FormControl<string | undefined>('CREDIT');

  minAmount = new FormControl();

  maxAmount = new FormControl();

  minAmountError = false;

  maxAmountError = false;

  maxAllowedAmountError = false;

  minAllowedAmountError = false;

  maxAmountEmptyError = false;

  get isError(): boolean {
    return (
      this.minAmountError || this.maxAmountError || this.maxAmountEmptyError || this.maxAllowedAmountError || this.minAllowedAmountError
    );
  }

  get minAmountValue(): number | undefined {
    return this.minAmount.value ? parseFloat(this.minAmount.value) : undefined;
  }

  get maxAmountValue(): number | undefined {
    return this.maxAmount.value ? parseFloat(this.maxAmount.value) : undefined;
  }

  ngAfterViewInit(): void {
    this.isCollapsed = this.isCollapsable && !this.selectedValue;
  }

  ngOnChanges(changes: SimpleChanges): void {
    const { currentValue, previousValue } = changes.selectedValue || {};
    if (currentValue === previousValue) {
      return;
    }

    const [min, max, type] = currentValue?.split('::') || [];

    this.minAmount.setValue(min || undefined);
    this.maxAmount.setValue(max || undefined);
    this.amountType.setValue(type || undefined);
  }

  onInputChange() {
    this.validateAmountValues();

    if (this.minAmount.value && this.maxAmount.value) {
      const type = this.props?.amountType && this.amountType.value ? `::${this.amountType.value}` : '';

      this.changed.emit(`${this.minAmount.value}::${this.maxAmount.value}${type}`);
    } else if (isEmpty(this.minAmount.value) && isEmpty(this.maxAmount.value)) {
      this.onResetBtnClick();
    }
  }

  onMinAmountFieldBlur(): void {
    if (!this.minAmount.value) {
      this.minAmount.reset();
    } else {
      const value = parseFloat(this.minAmount.value);
      this.minAmount.setValue(getFormattedAmount(value).formattedValue);
    }

    this.onInputChange();
  }

  onMaxAmountFieldBlur(): void {
    if (!this.maxAmount.value) {
      this.maxAmount.reset();
    } else {
      const value = parseFloat(this.maxAmount.value);
      this.maxAmount.setValue(getFormattedAmount(value).formattedValue);
    }
    this.onInputChange();
  }

  validateAmountValues(): void {
    const minAmount = this.minAmountValue;
    const maxAmount = this.maxAmountValue;

    this.minAmountError = maxAmount ? maxAmount > 0 && minAmount !== 0 && !minAmount : false;
    this.maxAmountError = minAmount && maxAmount ? minAmount > 0 && maxAmount > 0 && minAmount > maxAmount : false;
    this.maxAmountEmptyError = minAmount ? minAmount > 0 && !maxAmount : false;
    this.maxAllowedAmountError = (minAmount && minAmount > 999999) || (maxAmount && maxAmount > 999999) || false;

    this.errored.emit(this.isError);
  }

  onResetBtnClick() {
    this.minAmount.setValue(undefined);
    this.maxAmount.setValue(undefined);
    this.changed.emit();
    this.validateAmountValues();
  }

  toggleFilter() {
    if (!this.isCollapsable) {
      return;
    }

    this.isCollapsed = !this.isCollapsed;
    this.filterToggled.emit();
  }
}
