<div class="assignee-select">
  <div class="rkt-form">
    <div class="rkt-form-control">
      <div class="rkt-form-field">
        <label class="rkt-form-label" [for]="selectId">Assigned To</label>
        <div class="rkt-form-select-field">
          <ng-select
            #select
            class="rkt-form-select rkt-form-select-assignee-dropdown"
            [items]="items$ | async"
            placeholder="All Assignees"
            [virtualScroll]="false"
            [tabIndex]="0"
            [(ngModel)]="selectedItem"
            bindLabel="label"
            bindValue="value"
            [clearable]="false"
            [multiple]="false"
            [searchable]="true"
            (change)="onChange($event)"
            [closeOnSelect]="true"
            [typeahead]="assigneeInput$"
            [minTermLength]="0"
            appendTo="body"
            [maxSelectedItems]="1"
            [disabled]="disabled"
            (open)="onOpen()"
            (close)="onClose()"
          >
            <ng-template ng-label-tmp let-item="item" let-clear="clear">
              <div class="option-label">
                <div class="employee-name">{{ item.label.split('::')[0] }}</div>
              </div>
            </ng-template>

            <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index" let-disabled="disabled">
              <ng-container *ngTemplateOutlet="optionTemplate; context: { $implicit: item, item$: item$, index: index }"> </ng-container>
            </ng-template>

            <ng-template #optionTemplate let-item let-item$="item$" let-index="index">
              <div class="option-content" [class.disabled]="!item$.selected">
                <div class="option-label">
                  <div class="employee-name">{{ item.label.split('::')[0] }}</div>
                  <div class="employee-email">{{ item.label.split('::')[1] }}</div>
                </div>
              </div>
            </ng-template>

            <ng-template ng-notfound-tmp>
              <div class="ng-option ng-option-disabled">
                <div class="option-content">No items found</div>
              </div>
            </ng-template>
          </ng-select>
        </div>
      </div>
    </div>
  </div>
</div>
