export const routeNames = {
  DASHBOARD: 'dashboard',
  TRANSACTION: 'transactions',
  LINX: 'linx',
  CUSTOMER: 'customers',
  RECIPIENT: 'recipients',
  SETTINGS: 'settings',
  ACCOUNT_AUTH: 'account',
  CARDS: 'cards',
  EARNED_WAGE: 'earned-wage',
  INSIGHTS: 'insights',
  EXCEPTION_MANAGEMENT: 'exception-management',
};
