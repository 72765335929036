import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { Store } from '@ngrx/store';
import { catchError, map, mergeMap, tap, switchMap } from 'rxjs/operators';
import { AdaRegistrationService } from '@shared/services';
import { ErrorUtils } from '@shared/utils';
import { MessagesActions } from '@shared/store';
import { AdaRegistrationListActions } from './ada-registration.actions';

@Injectable()
export class AdaRegistrationEffects {
  constructor(private actions$: Actions, private adaRegistrationService: AdaRegistrationService, private store: Store) {}

  getAdaRegistrations$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AdaRegistrationListActions.loadAdaRegistrations),
      mergeMap((payload) => {
        return this.adaRegistrationService.getAdaRegistrations(payload.params).pipe(
          map((response) =>
            AdaRegistrationListActions.loadAdaRegistrationsSuccess({
              adaRegistrations: response.items,
              totalElements: response.totalElements,
            }),
          ),
          catchError((error) => {
            return of(AdaRegistrationListActions.loadAdaRegistrationsError({ error }));
          }),
        );
      }),
    );
  });

  loadAdaRegistrationsError$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AdaRegistrationListActions.loadAdaRegistrationsError),
      tap({
        next: (loadAdaRegistrationsError) => {
          ErrorUtils.catchError('adaRegistrationService.getAdaRegistrations error', loadAdaRegistrationsError.error);
        },
      }),
      switchMap(() => of(MessagesActions.displayError({ message: 'Unable to fetch Debit Authorizations.', title: 'Error' }))),
    );
  });
}
