import { formRow, formSelect } from '@rocketfinancialcorp/rocket-ui/form';

export const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export const ReportFormFields = [
  formRow([
    formSelect({
      key: 'reportType',
      label: 'Type',
      className: 'report-dropdown',
      defaultValue: 'ALL',
      props: {
        options: [],
        placeholder: 'Select report type',
        required: false,
      },
    }),
    formSelect({
      key: 'dateRange',
      label: 'Date Range',
      defaultValue: 'THIS_WEEK',
      props: {
        options: [
          { label: 'Today', value: 'TODAY' },
          { label: 'Yesterday', value: 'YESTERDAY' },
          { label: 'This Week', value: 'THIS_WEEK' },
          { label: 'This Month', value: 'THIS_MONTH' },
          { label: 'This Year', value: 'THIS_YEAR' },
        ],
        placeholder: 'Select date',
        required: false,
      },
    }),
  ]),
];
