import { Component, OnInit } from '@angular/core';
import { ScheduledReport } from '@shared/models';
import { ActivatedRoute, Router } from '@angular/router';
import { finalize, takeUntil } from 'rxjs/operators';
import { ErrorUtils } from '@shared/utils';
import { ReportService } from '@shared/services';
import { Subject } from 'rxjs';
import { ConfirmModalComponent, SuccessModalComponent } from '@shared/components';
import { ModalService } from '@rocketfinancialcorp/rocket-ui/modal';
import { CreateScheduledReportComponent, ScheduledReportUpdateComponent } from '@modules/insights/components';

@Component({
  selector: 'app-scheduled-report-list-item',
  templateUrl: './scheduled-report-list.component.html',
  styleUrls: ['./scheduled-report-list.component.scss'],
})
export class ScheduledReportListComponent implements OnInit {
  loading!: boolean;

  scheduledReports: ScheduledReport[] = [];

  searchString?: string;

  isActiveSearch = false;

  private destroy$ = new Subject<void>();

  constructor(
    private activatedRoute: ActivatedRoute,
    private modalService: ModalService,
    private router: Router,
    private reportService: ReportService,
  ) {}

  ngOnInit(): void {
    this.getScheduledReport();
  }

  onSearch(event: string): void {
    if (!event) {
      this.onSearchReset();
      return;
    }
    this.isActiveSearch = true;
    this.searchString = event;
    this.getScheduledReport();
  }

  onSearchReset(): void {
    if (!this.searchString) {
      return;
    }
    this.isActiveSearch = false;
    this.searchString = undefined;
    this.getScheduledReport();
  }

  getScheduledReport(): void {
    this.loading = true;

    this.reportService
      .getScheduledReports({
        searchString: this.searchString,
      })
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (scheduledReportList) => {
          this.onFetchComplete(scheduledReportList);
        },
        error: (error) => {
          this.loading = false;
          ErrorUtils.catchError('reportService.getScheduledReports error', error);
        },
      });
  }

  onUpdateScheduledReport(scheduledReport: ScheduledReport) {
    const editModalRef = this.modalService.open(ScheduledReportUpdateComponent, {
      className: 'entity-form-modal',
    });

    editModalRef.componentInstance.modalInitData(scheduledReport);

    editModalRef.result.then(
      (response) => {
        if (response) {
          this.getScheduledReport();
        }
      },
      () => false,
    );
  }

  private onFetchComplete(scheduledReports: ScheduledReport[]) {
    this.scheduledReports = scheduledReports;
    this.loading = false;
  }

  onDeleteScheduledReport(scheduledReport: ScheduledReport): void {
    const confirmModalRef = this.modalService.open(ConfirmModalComponent, { className: 'confirm-modal' });

    confirmModalRef.componentInstance.confirmBtnText = 'Delete';
    confirmModalRef.componentInstance.customText = 'Are you sure? This action cannot be undone.';
    confirmModalRef.componentInstance.title = 'Delete Scheduled Report';

    confirmModalRef.result.then(
      (result) => {
        if (!result) {
          return;
        }

        this.deleteScheduledReport(scheduledReport);
      },
      () => false,
    );
  }

  deleteScheduledReport(scheduledReport: ScheduledReport) {
    this.loading = true;

    this.reportService
      .deleteScheduledReport(scheduledReport.id)
      .pipe(
        takeUntil(this.destroy$),
        finalize(() => {
          this.loading = false;
        }),
      )
      .subscribe({
        next: () => {
          this.showScheduledReportChangeSuccessModal('deleted');
        },
        error: (error) => ErrorUtils.catchError('reportService.deleteScheduledReport error', error),
      });
  }

  public showScheduledReportChangeSuccessModal(status: string): void {
    const stateChangeSuccessModalRef = this.modalService.open(SuccessModalComponent, {
      className: 'success-modal',
    });

    stateChangeSuccessModalRef.componentInstance.actionName = status;
    stateChangeSuccessModalRef.componentInstance.type = 'Scheduled Report';
    stateChangeSuccessModalRef.result.finally(() => this.getScheduledReport());
  }

  createScheduledReport() {
    const createScheduledReportRef = this.modalService.open(CreateScheduledReportComponent, {
      className: 'report-document-modal',
      size: 'full-size',
    });

    createScheduledReportRef.result.then(
      (response) => {
        if (response) {
          this.showScheduledReportChangeSuccessModal('created');
        }
      },
      () => false,
    );
  }
}
