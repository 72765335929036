import { isUndefined } from 'lodash-es';
import { createFeature, createReducer, createSelector, on } from '@ngrx/store';
import { FeatureFlag } from '@shared/models';
import { FeatureManagementActions } from './feature-management.actions';

interface FeatureManagementState {
  featureFlags: FeatureFlag[];
}

export const initialFeatureManagementState: FeatureManagementState = {
  featureFlags: [
    {
      key: 'add-batch-feature',
      name: 'Add Batch',
      value: true,
      envsEnabledByDefault: ['develop'],
    },
    {
      key: 'create-integrated-fa-feature',
      name: 'Create Integrated FA (BA)',
      value: false,
      envsEnabledByDefault: ['develop', 'qa', 'staging', 'sandbox'],
    },
    {
      key: 'cards-feature',
      name: 'Cards',
      value: false,
      envsEnabledByDefault: ['develop'],
    },
    {
      key: 'earned-wage-feature',
      name: 'Earned Wage Access',
      value: false,
      envsEnabledByDefault: ['develop', 'qa'],
    },
    {
      key: 'exception-management-feature',
      name: 'Exception Management',
      value: false,
      envsEnabledByDefault: ['develop', 'qa'],
    },
  ],
};

const reducer = createReducer(
  initialFeatureManagementState,
  on(FeatureManagementActions.setFeatureFlags, (state, { featureFlagsValues }): FeatureManagementState => {
    const updatedFeatureFlags = state.featureFlags.map((featureFlag) => ({ ...featureFlag, value: featureFlagsValues[featureFlag.key] }));
    return { ...state, featureFlags: updatedFeatureFlags };
  }),
  on(FeatureManagementActions.resetFeatureFlagsComplete, (state, { featureFlags }): FeatureManagementState => {
    return { ...state, featureFlags };
  }),
);

export const featureManagementFeature = createFeature({
  name: 'featureManagementFeature',
  reducer,
});

export const selectGlobalBooleanFeatureFlags = createSelector(
  featureManagementFeature.selectFeatureFlags,
  (featureFlags) => featureFlags ?? [],
);

export const selectFeatureFlagByKey = (key: string) =>
  createSelector(featureManagementFeature.selectFeatureFlags, (featureFlags) => featureFlags?.find((flag) => flag.key === key));

export const selectFeatureFlagEnabledByKey = (key?: string) => {
  if (!key) {
    return createSelector(() => true);
  }

  return createSelector(selectFeatureFlagByKey(key), (flag) => {
    if (isUndefined(flag)) {
      console.warn(`Feature Flag with key "${key}" is not defined!`);
      return false;
    }

    return !!flag?.value;
  });
};
