import { formRow, formSelect } from '@rocketfinancialcorp/rocket-ui/form';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { isUndefined } from 'lodash-es';
import { ReportService } from '@shared/services';

const monthValues = [
  { value: 0, label: 'Jan' },
  { value: 1, label: 'Feb' },
  { value: 2, label: 'Mar' },
  { value: 3, label: 'Apr' },
  { value: 4, label: 'May' },
  { value: 5, label: 'Jun' },
  { value: 6, label: 'Jul' },
  { value: 7, label: 'Aug' },
  { value: 8, label: 'Sep' },
  { value: 9, label: 'Oct' },
  { value: 10, label: 'Nov' },
  { value: 11, label: 'Dec' },
];
const yearValues = Array.from({ length: 3 }, (_, i) => {
  const val = new Date().getFullYear() - i;
  return { value: val, label: val };
});

const expressions = {
  'props.required': (field: FormlyFieldConfig) => {
    return !isUndefined(field.model?.monthFrom) || !!field.model?.yearFrom || !isUndefined(field.model?.monthTo) || !!field.model?.yearTo;
  },
};

export const getFindReportTypeFields = (reportService: ReportService) => [
  formRow([
    formSelect({
      key: 'reportType',
      label: 'Type',
      className: 'report-dropdown',
      props: {
        options: reportService.getInsightReportTypes(),
        placeholder: 'Select report type',
        required: true,
      },
    }),
  ]),
];

export const FindReportDateFormFields = [
  formRow([
    formSelect({
      key: 'monthFrom',
      label: 'Date Range',
      className: 'find-report-form find-report-date-from',
      props: {
        options: monthValues,
        hideLabel: false,
        placeholder: 'Month',
      },
      expressions,
    }),
    formSelect({
      key: 'yearFrom',
      label: 'Year From',
      props: {
        options: yearValues,
        hideLabel: true,
        placeholder: 'Year',
      },
      expressions,
    }),
    formSelect({
      key: 'monthTo',
      label: 'to',
      className: 'find-report-form find-report-date-to',
      props: {
        options: monthValues,
        placeholder: 'Month',
        hideLabel: false,
      },
      expressions,
    }),
    formSelect({
      key: 'yearTo',
      label: 'Year To',
      props: {
        options: yearValues,
        hideLabel: true,
        placeholder: 'Year',
      },
      expressions,
    }),
  ]),
];
