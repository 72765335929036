<div class="sidebar">
  <a *ngIf="isSponsorTenant$ | async" class="navbar-brand iris" [routerLink]="['/app/dashboard']">
    <img src="./assets/images/iris-logo.svg" class="navbar-rocket-image img-fluid" alt="RocketKOR Portal" title="RocketKOR Portal" />
  </a>

  <a *ngIf="(isSponsorTenant$ | async) === false" class="navbar-brand" [routerLink]="['/app/dashboard']">
    <img src="./assets/images/rocket.svg" class="navbar-rocket-image img-fluid" alt="RocketKOR Portal" title="RocketKOR Portal" />
  </a>

  <div class="business-profile">
    <div class="business-profile-status-container">
      <div class="business-profile-status"></div>
    </div>
    <div class="business-profile-name">
      {{ businessAccountName$ | async }}
    </div>
  </div>

  <div class="menu-items" *ngIf="isSponsorTenant$ | async">
    <rkt-table-search placeholder="Search..." (click)="openBusinessAccountListModal()"></rkt-table-search>
  </div>

  <ng-template #menuItemsTmpl let-menuItems="menuItems" let-isSubItem="isSubItem">
    <div class="menu-items" *ngFor="let menuItem of menuItems">
      <ng-container *ngIf="!menuItem.subItems?.length; then item; else collapsable"> </ng-container>

      <ng-template #item>
        <a
          *ngIf="!menuItem.isExternal"
          class="menu-item"
          [class.disabled]="menuItem.notAllowed"
          [class.menu-sub-item]="isSubItem"
          [title]="menuItem.title"
          [class.d-none]="menuItem.hidden"
          [class.active]="isActive(menuItem.href)"
          [routerLink]="menuItem.notAllowed ? null : menuItem.href"
          [target]="menuItem.target ?? '_self'"
          (click)="menuItem.customClick && menuItem.customClick($event)"
        >
          <ng-container *ngIf="!!menuItem.icon">
            <rkt-icon class="menu-icon" [name]="menuItem.icon"></rkt-icon>
          </ng-container>
          <span class="menu-text">{{ menuItem.title }}</span>
        </a>
        <a
          *ngIf="menuItem.isExternal"
          class="menu-item"
          [class.disabled]="menuItem.notAllowed"
          [class.menu-sub-item]="isSubItem"
          [title]="menuItem.title"
          [class.d-none]="menuItem.hidden"
          [class.active]="isActive(menuItem.href)"
          [href]="menuItem.href"
          [target]="menuItem.target ?? '_self'"
          (click)="menuItem.customClick && menuItem.customClick($event)"
        >
          <ng-container *ngIf="!!menuItem.icon">
            <rkt-icon class="menu-icon" [name]="menuItem.icon"></rkt-icon>
          </ng-container>
          <span class="menu-text">{{ menuItem.title }}</span>
        </a>
      </ng-template>

      <ng-template #collapsable>
        <div
          class="menu-item collapsable"
          [class.active]="hasActiveSubitem(menuItem)"
          [title]="menuItem.title"
          (click)="toggleCollapsableItem(menuItem)"
        >
          <rkt-icon class="menu-icon" [name]="menuItem.icon!"></rkt-icon>
          <span class="menu-text">{{ menuItem.title }}</span>
          <rkt-icon class="menu-toggler" [name]="menuItem.isCollapsed ? 'chevron-down' : 'chevron-up'"></rkt-icon>
        </div>
        <div *ngIf="!menuItem.isCollapsed" class="subitems-block">
          <ng-container *ngTemplateOutlet="menuItemsTmpl; context: { menuItems: menuItem.subItems, isSubItem: true }"></ng-container>
        </div>
      </ng-template>
    </div>
  </ng-template>

  <ng-container *ngTemplateOutlet="menuItemsTmpl; context: { menuItems: menuItems }"></ng-container>
</div>
