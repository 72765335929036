import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-scheduled-report-page',
  templateUrl: './scheduled-report-page.component.html',
  styleUrls: ['./scheduled-report-page.component.scss'],
})
export class ScheduledReportPageComponent {
  constructor(private router: Router) {}

  viewReports() {
    this.router.navigate(['app/insights/documents']);
  }

  closeModal() {
    this.router.navigate(['app/insights']);
  }
}
