import { Component, inject, Input } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { DatatableColumn } from '@rocketfinancialcorp/rocket-ui/table';
import { ModalService } from '@rocketfinancialcorp/rocket-ui/modal';
import { AttachmentDetails, AttachmentListItem, Note } from '@shared/models';
import { AttachmentService, NotificationService } from '@shared/services';
import { ErrorUtils } from '@shared/utils';
import { ViewAttachmentModalComponent } from '@shared/components';

@Component({
  selector: 'app-notes-feed-item',
  templateUrl: './notes-feed-item.component.html',
})
export class NotesFeedItemComponent {
  attachmentService = inject(AttachmentService);

  modalService = inject(ModalService);

  notificationService = inject(NotificationService);

  @Input() loading = false;

  @Input() note!: Note;

  responsiveBreakpoints = [
    { itemsScroll: 1, itemsVisible: 5, breakpoint: 2700 },
    { itemsScroll: 1, itemsVisible: 4, breakpoint: 1700 },
    { itemsScroll: 1, itemsVisible: 3, breakpoint: 1490 },
    { itemsScroll: 1, itemsVisible: 2, breakpoint: 1340 },
    { itemsScroll: 1, itemsVisible: 1, breakpoint: 1140 },
  ];

  viewType: 'tiles' | 'list' = 'tiles';

  columns: DatatableColumn[] = [
    { name: 'File Name', prop: 'name', flexSize: 300 },
    { name: 'Description', prop: 'description', flexSize: 300 },
    { name: 'Created At', prop: 'createdAt', fixedSize: 150, colType: 'date-time' },
    {
      name: '',
      prop: 'downloadBtn',
      fixedSize: 50,
      colType: 'icon-button',
      iconName: 'download',
      colAlign: 'center',
      colTitle: 'downloadBtn',
    },
  ];

  private destroy$ = new Subject<void>();

  onViewTypeClick(): void {
    this.viewType = this.viewType === 'tiles' ? 'list' : 'tiles';
  }

  onViewAttachmentCLick({ id }: AttachmentListItem): void {
    this.attachmentService
      .getAttachmentById(id)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (response) => {
          this.viewAttachment(response);
        },
        error: (error) => {
          this.notificationService.displayError('Unable to fetch Attachment.', 'Error');
          ErrorUtils.catchError('attachmentService.getAttachmentById', error);
        },
      });
  }

  viewAttachment(attachment: AttachmentDetails): void {
    const viewAttachmentModalRef = this.modalService.open(ViewAttachmentModalComponent, {
      className: 'view-attachment-modal',
      size: 'lg',
    });
    viewAttachmentModalRef.componentInstance.modalInitData({ ...attachment, readOnly: true });

    viewAttachmentModalRef.result.then(
      (result) => {
        if (result) {
          this.onActionButtonClick(result, attachment.id);
        }
      },
      () => false,
    );
  }

  onColButtonClick({ colButton, row }: { colButton: string; row: AttachmentListItem }) {
    this.onActionButtonClick(colButton, row.id);
  }

  onActionButtonClick(action: string, attachmentId: AttachmentDetails['id'], event?: Event): void {
    event?.stopPropagation();
    switch (action) {
      case 'DELETE':
        break;
      case 'DOWNLOAD':
        this.attachmentService.downloadAttachment(attachmentId);
        break;
      case 'EDIT':
        break;
    }
  }

  getAttachmentScanLabel(attachment: AttachmentDetails): string {
    switch (attachment.status) {
      case 'NEW':
        return 'No File Available';
        break;

      case 'UPLOADED':
        return 'Scanning for Viruses';
        break;

      case 'MALICIOUS':
      case 'INCOMPLETE':
        return 'File cannot be Displayed.';
        break;

      case 'SCANNED':
      default:
        return '';
    }
  }
}
