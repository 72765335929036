import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { Store } from '@ngrx/store';
import { compact } from 'lodash-es';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, map, shareReplay, switchMap, take, tap } from 'rxjs/operators';
import {
  Beneficiaries,
  BeneficiariesRaw,
  BusinessAccount,
  BusinessAccountRaw,
  LegalEntityType,
  LinkedBusinessAccount,
  LinkedBusinessAccountsRaw,
  RequestPageParams,
  BeneficiaryRequestParams,
  BeneficiaryRaw,
  Beneficiary,
  BusinessAccountsResponse,
  FilterValues,
  VerificationStatus,
} from '@shared/models';
import { environment } from '@env';
import { BackendService } from '@shared/services/backend.service';
import { constants } from '@shared/constants';
import { AuditActions, fromAuth } from '@shared/store';
import {
  mapBusinessAccountBeneficiary,
  mapBusinessAccountBeneficiaryList,
  mapBusinessAccountDetails,
  mapLinkedBusinessAccountList,
} from './business-account-mapping-utils';
import { CustomHttpParamEncoder } from '@shared/encoder';
import { StorageService } from '@shared/services';
import { mapVerifactionStatus } from '@shared/utils';

@Injectable({
  providedIn: 'root',
})
export class BusinessAccountService {
  businessAccountListFilter = new BehaviorSubject<FilterValues>({});

  constructor(private backendService: BackendService, private store: Store, private storageService: StorageService) {}

  public getSelectedBusinessAccount(): Observable<BusinessAccount> {
    return this.store.select(fromAuth.selectActiveBusinessAccountDetails).pipe(shareReplay());
  }

  public getSelectedBusinessAccountId(): Observable<BusinessAccount['id']> {
    return this.store.select(fromAuth.selectBusinessAccountId).pipe(take(1));
  }

  public getLinkedBusinessAccounts({ page, size }: RequestPageParams): Observable<LinkedBusinessAccount[]> {
    const pageSize = size ?? constants.DEFAULT_PAGE_SIZE;
    const params = new HttpParams().set('page', `${page ?? 0}`).set('size', `${pageSize}`);

    return this.backendService.get<LinkedBusinessAccountsRaw>('/v1/employees/self/linked-business-accounts', { params }).pipe(
      map((response) => mapLinkedBusinessAccountList(response)),
      catchError((errorRes) => throwError(() => errorRes)),
    );
  }

  public getBusinessAccount(businessAccountId: BusinessAccount['id']): Observable<BusinessAccount> {
    return this.backendService.get<BusinessAccountRaw>(`${environment.accountFlowService}/business-accounts/${businessAccountId}`).pipe(
      map((response) => mapBusinessAccountDetails(response)),
      catchError((errorRes) => throwError(() => errorRes)),
    );
  }

  public getBusinessAccountVerification(
    businessAccountId: BusinessAccount['id'],
  ): Observable<{ verificationStatus?: VerificationStatus; verificationStatusHistory?: VerificationStatus[] }> {
    return this.backendService.get<BusinessAccountRaw>(`${environment.accountFlowService}/business-accounts/${businessAccountId}`).pipe(
      map(({ createdAt, verificationStatus, verificationStatusHistory }) => {
        const verification: VerificationStatus = mapVerifactionStatus({
          verificationStatus: verificationStatus,
          updatedAt: createdAt,
        });

        const verificationHistoryUpdatedBy = verificationStatusHistory?.map(({ updatedBy }) => updatedBy) ?? [];

        this.store.dispatch(AuditActions.loadTeamMembers({ ids: compact([verification.updatedBy, ...verificationHistoryUpdatedBy]) }));

        return { verificationStatus: verification, verificationStatusHistory: verificationStatusHistory ?? [verification] };
      }),
      catchError((errorRes) => throwError(() => errorRes)),
    );
  }

  public updateBusinessAccount(data: Partial<BusinessAccount>, businessAccountId: BusinessAccount['id']): Observable<BusinessAccount> {
    return this.backendService
      .update<BusinessAccountRaw>(`${environment.accountFlowService}/business-accounts/${businessAccountId}`, data)
      .pipe(
        map((response) => mapBusinessAccountDetails(response)),
        catchError((errorRes) => throwError(() => errorRes)),
      );
  }

  public getBusinessAccountBeneficiaries(): Observable<Beneficiaries[]> {
    return this.getSelectedBusinessAccountId().pipe(
      switchMap((businessAccountId) =>
        this.backendService
          .get<BeneficiariesRaw[]>(`${environment.accountFlowService}/v1/business-accounts/${businessAccountId}/beneficiaries`)
          .pipe(
            map((response) => mapBusinessAccountBeneficiaryList(response)),
            catchError((errorRes) => throwError(() => errorRes)),
          ),
      ),
    );
  }

  public addBeneficiary(beneficiaryData: BeneficiaryRequestParams): Observable<boolean> {
    return this.getSelectedBusinessAccountId().pipe(
      switchMap((businessAccountId) =>
        this.backendService
          .post<BeneficiaryRaw>(
            `${environment.accountFlowService}/v1/business-accounts/${businessAccountId}/beneficiaries`,
            beneficiaryData,
          )
          .pipe(
            map(() => true),
            catchError((errorRes) => throwError(() => errorRes)),
          ),
      ),
    );
  }

  public getBusinessAccountBeneficiaryById(beneficiaryId: Beneficiary['id']): Observable<Beneficiary> {
    return this.getSelectedBusinessAccountId().pipe(
      switchMap((businessAccountId) =>
        this.backendService
          .get<BeneficiaryRaw>(`${environment.accountFlowService}/v1/business-accounts/${businessAccountId}/beneficiaries/${beneficiaryId}`)
          .pipe(
            tap({
              next: (beneficiaryData) => {
                const { createdBy, updatedBy } = beneficiaryData;
                this.store.dispatch(AuditActions.loadTeamMembers({ ids: compact([createdBy, updatedBy]) }));
              },
            }),
            map((response) => mapBusinessAccountBeneficiary(response)),
            catchError((errorRes) => throwError(() => errorRes)),
          ),
      ),
    );
  }

  public updateBeneficiary({
    beneficiaryId,
    beneficiaryData,
  }: {
    beneficiaryId: string;
    beneficiaryData: BeneficiaryRequestParams;
  }): Observable<Beneficiary> {
    return this.getSelectedBusinessAccountId().pipe(
      switchMap((businessAccountId) =>
        this.backendService
          .update<BeneficiaryRaw>(
            `${environment.accountFlowService}/v1/business-accounts/${businessAccountId}/beneficiaries/${beneficiaryId}`,
            beneficiaryData,
          )
          .pipe(
            map((response) => mapBusinessAccountBeneficiary(response)),
            catchError((errorRes) => throwError(() => errorRes)),
          ),
      ),
    );
  }

  public getBusinessAccountListForSponsor({
    page,
    size,
    searchString,
    activeFilters,
  }: RequestPageParams): Observable<BusinessAccountsResponse> {
    const pageSize = size ?? 5;

    let params = new HttpParams({ encoder: new CustomHttpParamEncoder() });

    params = params.append('size', pageSize);

    if (page) {
      params = params.append('page', page);
    }

    if (searchString) {
      params = params.append('searchString', searchString);
    }

    if (activeFilters) {
      Object.keys(activeFilters).forEach((key) => {
        if (key === 'accountType') {
          const keyValue = activeFilters[key]!.split('=');
          params = params.append(keyValue[0], keyValue[1]);
        } else {
          params = params.append(key, activeFilters[key]!);
        }
      });
    }

    return this.backendService.get<BusinessAccountsResponse>('/v1/business-accounts/search', { params }).pipe(
      map((response) => response),
      catchError((errorRes) => throwError(() => errorRes)),
    );
  }

  public setBusinessAccountFilterParams(filters: FilterValues): void {
    this.storageService.setItem(constants.KOR_BUSINESS_ACCOUNT_FILTER, filters);
    this.updateBusinessAccountFilterParams(filters);
  }

  public getBusinessAccountFilterParams(): void {
    const filters = this.storageService.getItem<FilterValues>(constants.KOR_BUSINESS_ACCOUNT_FILTER);

    if (filters && Object.keys(filters).length) {
      this.updateBusinessAccountFilterParams(filters);
    }
  }

  private updateBusinessAccountFilterParams(filters: FilterValues): void {
    this.businessAccountListFilter.next(filters);
  }

  //  DEPRECATED/UNUSED method
  public getLegalEntityTypes(): Observable<LegalEntityType[]> {
    return this.backendService.get<LegalEntityType[]>(`${environment.accountFlowService}/business-accounts/legal-entity-types`);
  }
}
