<div class="scheduled-report-list">
  <ng-container *ngIf="scheduledReports?.length || isActiveSearch; else onFetchDocuments">
    <div class="search-container">
      <rkt-table-search
        (changed)="onSearch($event)"
        placeholder="Search..."
        class="document-search"
        [disabled]="loading"
      ></rkt-table-search>
      <button rktButton [disabled]="!!(loading | accessControl | async)" (click)="createScheduledReport()">Schedule a Report</button>
    </div>

    <div *ngFor="let scheduledReport of scheduledReports" class="scheduled-report-item">
      <div class="report-details">
        <div class="report-title">
          <div class="report-name">{{ scheduledReport.name | titlecase }}</div>
          <div class="report-recurrence">
            <rkt-icon name="wall-clock" class="rocket-icon"></rkt-icon>
            <div class="scheduler-type">
              <span class="scheduler-type-label">{{ scheduledReport.schedulerType | titlecase }}</span>
            </div>
          </div>
        </div>
      </div>

      <div class="report-actions">
        <button
          class="file-icon"
          (click)="onDeleteScheduledReport(scheduledReport)"
          [disabled]="false | accessControl | async"
          title="Delete Report"
        >
          <rkt-icon name="trash" class="rocket-icon rocket-trash"></rkt-icon>
        </button>

        <button
          class="file-icon"
          (click)="onUpdateScheduledReport(scheduledReport)"
          [disabled]="false | accessControl | async"
          title="Update Report"
        >
          <rkt-icon name="edit" class="rocket-icon"></rkt-icon>
        </button>
      </div>
    </div>
  </ng-container>

  <ng-template #onFetchDocuments>
    <div class="empty-state" *ngIf="!loading">
      <div class="image"></div>
      <div class="message">Looks like there are no reports scheduled at the moment.</div>
      <div class="sub-message">Create & schedule one now!</div>
      <button rktButton (click)="createScheduledReport()">Schedule a Report</button>
    </div>

    <ng-container *ngIf="loading">
      <div *ngFor="let i of [].constructor(10)" class="scheduled-report-item loading-placeholder"></div>
    </ng-container>
  </ng-template>
</div>
