<div class="add-notes-container" appDragDropAttachment (filesDropped)="onAttachmentsDropped($event)">
  <div class="add-notes-title">Add Notes</div>

  <div class="add-note-field-wrapper" [class.error]="(error && displayError) || newNote.errors">
    <textarea
      class="add-notes-field"
      #noteField
      (blur)="onNoteFieldBlur()"
      name="new-note"
      id="new-note"
      [formControl]="newNote"
      maxlength="1000"
    ></textarea>

    <div class="add-notes-char-count" [class.count-warning]="newNote.value?.length > 800">
      {{ newNote.value?.length || 0 }}/1000 Characters remaining
    </div>
  </div>

  <div class="note-attention">Do not enter the card/account details in notes</div>

  <div class="add-attachment-title">
    Attachments ({{ attachmentsData.length }})
    <button class="attachment-icon" (click)="onAddAttachments()" [disabled]="isDisabled">
      <rkt-icon name="attachment"></rkt-icon>
    </button>
  </div>

  <ng-container *ngIf="attachmentsData.length">
    <ng-scrollbar track="vertical" class="attachments-scroll-wrapper">
      <div class="attachment-upload-selected-container">
        <div class="uploaded-attachment-item" *ngFor="let attachment of attachmentsData; index as i">
          <rkt-icon class="attachment-status" name="check"></rkt-icon>
          <div class="name">{{ attachment.name ?? attachment.file?.name }}</div>
          <button (click)="onEditFileBtnClick(i)" class="attachment-action-button attachment-action-button-edit">
            <rkt-icon name="edit"></rkt-icon>
          </button>
          <button (click)="onDeleteFileBtnClick(i)" class="attachment-action-button attachment-action-button-delete">
            <rkt-icon name="trash"></rkt-icon>
          </button>
        </div>
      </div>
    </ng-scrollbar>
  </ng-container>

  <div class="note-add-error" *ngIf="error && displayError">{{ error }}</div>

  <div class="note-validation-error" *ngIf="newNote.errors && newNote.errors.isEmptySpaces">
    {{ newNote.errors && newNote.errors.isEmptySpaces.message }}
  </div>

  <div class="add-notes-buttons">
    <button
      rktButton
      btnType="tertiary"
      (click)="onAddNoteCancel()"
      title="Discard"
      [disabled]="!newNote.value?.length || isShowSpinner || newNote.errors !== null"
    >
      Discard
    </button>
    <button
      rktButton
      (click)="onAddNoteSave()"
      title="Save"
      [disabled]="newNote.invalid || !newNote.value?.length || isShowSpinner"
      class="add-entity-btn"
    >
      <rkt-icon name="save"></rkt-icon>Save
    </button>
  </div>
</div>
