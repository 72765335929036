<form [formGroup]="profileForm" class="enhance-profile-form">
  <div class="enhance-profile-container">
    <div class="enhance-profile-title">Enhance Details</div>
    <rkt-form class="enhance-profile-options" [form]="profileForm" [fields]="profileFields" [model]="formModel"></rkt-form>

    <div class="enhance-beneficiary-documents-title">ID Documents</div>
    <rkt-form class="enhance-profile-options" [form]="profileForm" [fields]="documentFields" [model]="formModel"></rkt-form>
  </div>

  <div class="linx-request-buttons">
    <button rktButton btnType="tertiary" class="linx-request-btn btn-clear" title="Clear" (click)="onClearBtnClick()">Clear</button>

    <button rktButton title="Continue" [disabled]="profileForm.invalid" (click)="onClickContinue()">Continue</button>
  </div>
</form>
