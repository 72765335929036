export const exceptionType: Record<string, string> = {
  ACH_RETURN: 'ACH Return',
  ACH_NOC: 'ACH NOC',
  BANK_ACCOUNT_VERIFICATION_DECLINED: 'Bank Account Verification Declined',
  BANK_ACCOUNT_VERIFICATION_NOC: 'Bank Account Verification NOC',
};

export const exceptionCategory: Record<string, string> = {
  TRANSACTION: 'Transaction',
  BANK_ACCOUNT_VERIFICATION: 'Bank Account Verification',
};
