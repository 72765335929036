<h1 class="title">Ask me anything about your account</h1>

<button class="close-button" (click)="activeModal.close()"><rkt-icon name="times"></rkt-icon></button>

<div class="rkt-table-search-container">
  <div class="rkt-table-search-label">
    <rkt-icon name="search" class="rkt-table-search-icon rkt-icon"></rkt-icon>
    <input
      type="text"
      class="rkt-input rkt-table-search-input"
      placeholder="Enter your search term..."
      [disabled]="loading"
      [(ngModel)]="searchString"
      (keydown.enter)="getAISearchResults()"
    />
    <button (click)="getAISearchResults()" class="submit-icon" [disabled]="!searchString?.length || loading">
      <rkt-icon name="send" class="rkt-icon"></rkt-icon>
    </button>
  </div>
  <div class="query-div" [hidden]="loading">
    <div class="query-pill" *ngFor="let amaQuestion of askMeAnythingQuestions$ | async">
      <div class="ama-query-text" (click)="updateSearchString(amaQuestion.queryText)">{{ amaQuestion.queryText }}</div>
      <rkt-icon name="times" class="rkt-value-clear-btn rkt-icon" (click)="deleteFavouriteQuestion(amaQuestion)"></rkt-icon>
    </div>
  </div>
</div>

<div class="natural-language-search-results-container">
  <ng-container *ngIf="loading; else resultContainer">
    <div class="loading-placeholder"></div>
  </ng-container>

  <ng-template #resultContainer>
    <ng-container *ngIf="searchResults.length || insightSummary; else emptyResult">
      <div class="natural-language-query-container">
        <div class="chat-box query-chat-box">
          <div class="user-icon">{{ (activeUser$ | async)?.initials?.charAt(0) }}</div>
          <div class="question">
            <div class="question-title">You</div>
            <div class="question-content">{{ searchedQuery }}</div>
          </div>

          <div class="user-favorite" (click)="markQuestionAsFavourite(searchedQuery)">
            <rkt-icon name="star" class="star-icon" [class.is-favorite]="isFavouriteQuestion(searchedQuery)"></rkt-icon>
          </div>
        </div>

        <div class="response-container" *ngIf="insightSummary">
          <div class="chat-box">
            <img class="rocket-icon" src="./assets/images/rocket.svg" alt="RocketKOR Portal" title="RocketKOR Portal" />
            <div class="question">
              <div class="question-title">Rocket KOR</div>
              <div class="question-content" [innerHTML]="insightSummary"></div>
            </div>
          </div>
        </div>

        <div class="tabular-data" *ngIf="searchResults.length">
          <div class="tabular-data-title" (click)="showTableData = !showTableData">
            <div>This response includes data in table format</div>
            <rkt-icon [name]="showTableData ? 'chevron-down' : 'chevron-right'"></rkt-icon>
          </div>

          <div class="natural-language-search-results-table" *ngIf="showTableData">
            <rkt-table
              [rows]="searchResults"
              [columns]="columns"
              [count]="totalElements"
              [limit]="10"
              [loadingIndicator]="loading"
              [messages]="{ emptyMessage: 'No records found.' }"
              [scrollbarH]="true"
              dateTimeFormat="MM/dd/yy HH:mm"
              (rowActivated)="viewEntityDetails($event)"
              emptyValuePlaceholder="--"
            ></rkt-table>

            <div *ngIf="maxRecordCountReached" class="max-record-count">A maximum of 200 records has been reached.</div>

            <div class="download-csv-btn" *ngIf="downloadResultEnabled">
              <button rktButton btnType="primary" (click)="downloadCSVResult()">Download CSV</button>
            </div>
          </div>
        </div>
      </div>
    </ng-container>

    <ng-template #emptyResult>
      <div class="empty-search-results">
        {{ searchErrorText }}
      </div>
    </ng-template>
  </ng-template>
</div>
