import { inject, Pipe, PipeTransform } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { fromAuth } from '@shared/store';
import { READ_ONLY_ROLES } from '@shared/constants';

@Pipe({
  name: 'accessControl',
  pure: false,
})
export class AccessControlPipe implements PipeTransform {
  store = inject(Store);

  private userRole$ = this.store.select(fromAuth.selectUserRole);

  transform(otherCondition?: Observable<boolean> | boolean | null): Observable<boolean> {
    const isDisabled = this.userRole$.pipe(map((userRole) => READ_ONLY_ROLES.includes(userRole)));
    return isDisabled || (otherCondition ?? false);
  }
}
