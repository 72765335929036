<div class="details-section-title">
  <div class="title">
    <app-text-with-placeholder [showPlaceholder]="loading">Recent Notes</app-text-with-placeholder>
    <button rktButton btnType="link" class="notes-refresh-btn" *ngIf="!loading" (click)="fetchNotes()">
      <rkt-icon name="refresh"></rkt-icon>
    </button>
  </div>

  <div class="controls">
    <button
      *ngIf="!addNote"
      rktButton
      btnType="link"
      class="control-btn"
      [disabled]="!!(loading | accessControl | async)"
      (click)="addNote = !addNote"
    >
      <rkt-icon name="plus"></rkt-icon>
      Add Note
    </button>
  </div>
</div>

<div class="details-content">
  <div *ngIf="addNote">
    <app-notes-feed-add-note
      (addBtnClick)="onNoteAdd($event)"
      (discardClick)="onNoteAddDiscard($event)"
      [loading]="newNoteLoading"
      [error]="noteAddError"
    ></app-notes-feed-add-note>

    <div class="separator-row"></div>
  </div>

  <div class="notes-feed">
    <app-notes-feed-item *ngFor="let noteItem of notes$ | async" [note]="noteItem"></app-notes-feed-item>

    <div *ngIf="!(notes$ | async)?.length" class="notes-empty-message">There are no notes.</div>
  </div>
</div>
