import { Component, EventEmitter, inject, Input, Output, ViewChild } from '@angular/core';
import { uniqueId } from 'lodash-es';
import { Store } from '@ngrx/store';
import { NgSelectComponent } from '@ng-select/ng-select';
import { catchError, concat, debounceTime, map, Observable, of, Subject, switchMap, tap } from 'rxjs';
import { TeamMemberService } from '@shared/services';
import { fromAuth } from '@shared/store';
import { DropdownItem, User } from '@shared/models';

@Component({
  selector: 'app-assignee-select',
  templateUrl: 'assignee-select.component.html',
})
export class AssigneeSelectComponent {
  @ViewChild('select') selectRef!: NgSelectComponent;

  store = inject(Store);

  @Input() label!: string;

  @Input() required = false;

  @Input() selectedAssignee?: DropdownItem;

  @Input() selectedItem?: string;

  @Input() items$!: Observable<DropdownItem[]>;

  @Input() loading = false;

  @Input() disabled = false;

  @Input() placeholder = '';

  @Output() changed = new EventEmitter<DropdownItem | undefined>();

  @Output() filterOpened = new EventEmitter();

  @Output() closed = new EventEmitter();

  activeUser$ = this.store.select(fromAuth.selectUser);

  assigneeInput$ = new Subject<string>();

  selectId = uniqueId();

  teamMemberService = inject(TeamMemberService);

  selectedItemOnOpen?: DropdownItem;

  getDefaultItems(user: User | null) {
    return [
      ...(this.selectedAssignee && ![user?.id, 'UNASSIGNED'].includes(this.selectedAssignee.value) ? [this.selectedAssignee] : []),
      { label: `${user?.name ?? ''}::${user?.email ?? ''}`, value: user?.id ?? '' },
      { label: 'Unassigned', value: 'UNASSIGNED' },
    ];
  }

  constructor() {
    this.items$ = this.activeUser$.pipe(
      switchMap((user) => {
        return concat(
          of(this.getDefaultItems(user)),
          this.assigneeInput$.pipe(
            debounceTime(300),
            tap({ next: () => (this.loading = true) }),
            switchMap((term) => {
              if (!term) {
                return of(this.getDefaultItems(user));
              } else {
                return this.teamMemberService.getBusinessAccountTeamMembers({ searchString: term }).pipe(
                  tap({ next: () => (this.loading = false) }),
                  map((items) => {
                    return [
                      ...items.items.map((employee) => {
                        return { label: `${employee.displayName}::${employee.primaryEmail}`, value: employee.id };
                      }),
                      {
                        label: 'Unassigned',
                        value: 'UNASSIGNED',
                      },
                    ];
                  }),
                  catchError(() => of([] as DropdownItem[])),
                );
              }
            }),
          ),
        );
      }),
    );
  }

  onChange(item: DropdownItem): void {
    this.changed.emit(item);
  }

  onOpen() {
    this.selectedItem = this.selectedAssignee?.value ?? 'UNASSIGNED';

    this.filterOpened.emit();
  }

  onClose() {
    this.assigneeInput$.next('');

    if (this.selectedItemOnOpen?.value !== this.selectedItem) {
      this.closed.emit();
    }

    this.selectRef?.blur();
  }
}
