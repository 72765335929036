<form [formGroup]="beneficiaryProfileTypeForm">
  <div class="enhance-profile-container">
    <rkt-form [form]="beneficiaryProfileTypeForm" [fields]="beneficiaryProfileTypeFields" [model]="formModel"></rkt-form>
    <div class="enhance-profile-title">
      <rkt-icon name="beneficiary"></rkt-icon>
      <span>Additional Beneficiary Information</span>
    </div>

    <rkt-form [form]="beneficiaryProfileTypeForm" class="enhance-profile-options" [fields]="profileFields" [model]="formModel"></rkt-form>

    <div class="enhance-beneficiary-documents-title">ID Documents</div>
    <rkt-form [form]="beneficiaryProfileTypeForm" class="enhance-profile-options" [fields]="documentFields" [model]="formModel"></rkt-form>
  </div>
  <div class="linx-request-buttons">
    <button rktButton btnType="tertiary" class="linx-request-btn btn-clear" title="Clear" (click)="onClearBtnClick()">Clear</button>

    <button rktButton title="Continue" [disabled]="beneficiaryProfileTypeForm.invalid" (click)="onClickContinue()">Continue</button>
  </div>
</form>
