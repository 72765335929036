<div class="notes-feed-add-form" appDragDropAttachment (filesDropped)="onAttachmentsDropped($event)">
  <div class="attachments-hover-overlay"></div>
  <rkt-form [form]="noteForm" [fields]="noteFormFields" [model]="noteFormModel"></rkt-form>

  <div class="note-attention">Do not enter the card/account details in notes</div>

  <div class="attachments-title">
    Attachments ({{ attachmentsData.length }})

    <button class="add-attachment-btn" (click)="onAddAttachments()">
      <rkt-icon name="attachment"></rkt-icon>
    </button>
  </div>

  <div class="attachments-list" *ngIf="attachmentsData.length">
    <div class="attachments-list-item" *ngFor="let attachment of attachmentsData; index as i">
      <div class="attachment-info">
        <rkt-icon [name]="attachment.file?.type?.includes('pdf') ? 'attachment-pdf' : 'attachment-img'" class="attachment-icon"></rkt-icon>

        <div class="attachment-name">{{ attachment.name ?? attachment.file?.name }}</div>
      </div>

      <div class="attachment-buttons">
        <button (click)="onEditFileBtnClick(i)" class="attachment-item-button">
          <rkt-icon name="edit"></rkt-icon>
        </button>

        <button (click)="onDeleteFileBtnClick(i)" class="attachment-item-button attachment-item-button-delete">
          <rkt-icon name="trash"></rkt-icon>
        </button>
      </div>
    </div>
  </div>

  <div class="details-page-edit-form-error" *ngIf="error">{{ error }}</div>

  <div class="add-notes-buttons">
    <button rktButton btnType="tertiary" (click)="onAddNoteCancel()" title="Discard">Discard</button>

    <button
      rktButton
      (click)="onAddNoteSave()"
      title="Save"
      [disabled]="!noteFormModel.note || noteForm.invalid || loading"
      class="add-entity-btn"
    >
      <rkt-icon name="save"></rkt-icon>Save
    </button>
  </div>
</div>
