<rkt-table
  [rows]="accountStatements"
  [columns]="columns"
  [limit]="10"
  [count]="totalElements"
  (fetchData)="getFinancialAccountStatements($event)"
  [loadingIndicator]="loading"
  [offset]="activePage"
  [sortParam]="sortParams"
  [isClickable]="true"
  [isInteractive]="false"
  dateTimeFormat="MM/dd/yy HH:mm"
  [messages]="{ emptyMessage: emptyListMessage }"
  (colButtonClicked)="onColButtonClick($event)"
  (rowActivated)="onReportStatementAction($event, 'PDF', false)"
  emptyValuePlaceholder="--"
></rkt-table>
