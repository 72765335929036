import { InsightStatementPageParams, RequestPageParams } from '@shared/models';
import { HttpParams } from '@angular/common/http';
import { CustomHttpParamEncoder } from '@shared/encoder';
import { constants } from '@shared/constants';
import { filterCreatedAtDateRange } from '@shared/utils';
import { endOfDay, startOfDay } from 'date-fns';

export const reportRequestParams = (requestParams: InsightStatementPageParams): HttpParams => {
  const { page, size, searchString, activeFilters, financialAccountId } = requestParams;
  let params = new HttpParams({ encoder: new CustomHttpParamEncoder() });

  params = params.append('size', size ?? constants.TABLE_ROWS);

  if (page) {
    params = params.append('page', page);
  }

  if (searchString) {
    params = params.append('name', searchString);
  }

  if (financialAccountId) {
    params = params.append('financialAccountId', financialAccountId);
  }

  if (activeFilters) {
    Object.keys(activeFilters).forEach((key) => {
      if (key === 'dateRange') {
        const { from, to } = filterCreatedAtDateRange(activeFilters[key]) ?? {};

        if (!from || !to) {
          return;
        }

        params = params.append('createdAtFrom', startOfDay(new Date(from)).toISOString());
        params = params.append('createdAtTo', endOfDay(new Date(to)).toISOString());
      } else {
        if (activeFilters[key] && activeFilters[key] !== 'ALL') params = params.append(key, activeFilters[key]!);
      }
    });
  }

  return params;
};
