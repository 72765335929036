<div class="account-limits-container">
  <div class="account-limits-title">
    Transaction Limits
    <button class="limits-edit-button" [hidden]="!isEditAllowed" [disabled]="false | accessControl | async" (click)="onEditClick()">
      <rkt-icon name="edit"></rkt-icon>
    </button>
  </div>

  <div class="limits">
    <app-transaction-limit-item *ngFor="let item of transactionLimits" [limitItem]="item"></app-transaction-limit-item>
  </div>
</div>
