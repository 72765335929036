import { trigger, AnimationEvent, transition, style, animate } from '@angular/animations';
import {
  AfterViewChecked,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { compact, isString } from 'lodash-es';
import { debounceTime, filter, fromEvent, Subject, takeUntil } from 'rxjs';
import { FilterField, FilterValue, FilterValues } from '@shared/models';
import { filterPositioning } from './filter-menu-utils';
import { FilterChecklistComponent } from '../filter-checklist/filter-checklist.component';

@Component({
  selector: 'app-filter-menu',
  templateUrl: './filter-menu.component.html',
  animations: [
    trigger('overlayAnimation', [
      transition(':enter', [style({ opacity: 0 }), animate('{{showTransitionParams}}')]),
      transition(':leave', [animate('{{hideTransitionParams}}', style({ opacity: 0 }))]),
    ]),
  ],
})
export class FilterMenuComponent implements OnInit, AfterViewChecked, OnDestroy {
  @HostBinding('class.rkt-filter-menu-container') commonClass = true;

  @ViewChildren(FilterChecklistComponent) filterChecklistRefList!: QueryList<FilterChecklistComponent>;

  @ViewChild('container') containerViewChild!: ElementRef;

  @Input() initialValues: FilterValues = {};

  @Input() filters: FilterField[] = [];

  @Output() opened = new EventEmitter();

  @Output() closed = new EventEmitter();

  @Output() saved = new EventEmitter<FilterValues>();

  values: FilterValues = {};

  errors: { [key: string]: boolean } = {};

  menuContainer?: HTMLDivElement;

  target?: HTMLElement;

  isVisible = false;

  dateCalendarList: { [key: string]: boolean } = {};

  private destroy$ = new Subject<void>();

  private filterToggleDebouncer = new Subject<void>();

  get dateRangeFilters(): FilterField[] | undefined {
    return this.filters?.filter((item) => item.type === 'DATE');
  }

  get customerDateFilters(): FilterField[] | undefined {
    return this.filters?.filter((item) => item.type === 'CUSTOM_DATE');
  }

  get isFilterHasErrors(): boolean {
    return compact(Object.values(this.errors)).length !== 0;
  }

  get isFilterCollapsable(): boolean {
    return this.filters.length > 3;
  }

  constructor(public ref: ChangeDetectorRef, public el: ElementRef, private router: Router) {
    fromEvent(window, 'scroll')
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: () => {
          if (this.isVisible) {
            this.alignOverlay();
          }
        },
      });

    fromEvent(window, 'resize')
      .pipe(debounceTime(50), takeUntil(this.destroy$))
      .subscribe({
        next: () => {
          if (this.isVisible) {
            this.alignOverlay();
          }
        },
      });

    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationStart),
        takeUntil(this.destroy$),
      )
      .subscribe({
        next: () => {
          this.hide();
        },
      });

    this.filterToggleDebouncer.pipe(debounceTime(50)).subscribe({
      next: () => this.alignOverlay(),
    });
  }

  ngOnInit() {
    this.dateRangeFilters?.map((item: FilterField) => (this.dateCalendarList[item.name] = false));
    this.customerDateFilters?.map((item: FilterField) => (this.dateCalendarList[item.name] = false));
  }

  ngAfterViewChecked(): void {
    this.ref.detectChanges();
  }

  ngOnDestroy() {
    if (this.menuContainer) {
      this.el.nativeElement.appendChild(this.menuContainer);
    }

    this.destroy$.next();
    this.destroy$.complete();
  }

  toggle(event: MouseEvent) {
    if (this.isVisible) {
      this.hide();
    } else {
      this.values = { ...this.initialValues };
      this.show(event);
    }
  }

  show(event: MouseEvent) {
    if (event.currentTarget) {
      this.target = event.currentTarget as HTMLElement;
    }

    this.isVisible = true;
  }

  hide() {
    this.isVisible = false;
  }

  captureStartEvent(event: AnimationEvent) {
    switch (event.toState) {
      case 'visible':
        this.menuContainer = event.element;
        this.opened.emit();

        this.appendOverlay();
        this.alignOverlay();

        break;

      case 'void':
        this.onOverlayHide();
        this.closed.emit();
        break;
    }
  }

  appendOverlay() {
    if (this.menuContainer && this.target) {
      document.body.appendChild(this.menuContainer);
    }
  }

  onOverlayHide() {
    this.target = undefined;
  }

  alignOverlay() {
    if (this.menuContainer && this.target) {
      this.menuContainer.style.maxHeight = 'inherit';
      filterPositioning.absolutePosition(this.menuContainer, this.target);
    }
  }

  onResetAllFilterBtnClick(): void {
    const filtersWithDefaultValues = this.filters.filter((item) => item.defaultValue);

    const defaults = Object.fromEntries(
      filtersWithDefaultValues.filter((item) => item.defaultValue).map((item) => [item.name, item.defaultValue]),
    );

    this.values = { ...defaults };
    this.errors = {};

    Object.keys(this.dateCalendarList).forEach((key) => (this.dateCalendarList[key] = false));

    this.filterChecklistRefList?.forEach((element) => element.reset());
  }

  onApplyFilterBtnClick(): void {
    this.saved.emit(this.values);
    this.hide();
  }

  setFilterValue(name: string, value: FilterValue): void {
    if (value === undefined) {
      const filtersWithDefaultValues = this.filters.filter((item) => item.defaultValue);
      const defaults = Object.fromEntries(
        filtersWithDefaultValues.filter((item) => item.defaultValue).map((item) => [item.name, item.defaultValue]),
      );
      this.values[name] = defaults[name];
      return;
    }

    this.values[name] = value;
    if (value) {
      this.dateCalendarList[name] = ['CUSTOM', 'CUSTOM_DATE'].includes(value) ? true : false;
    }
  }

  setFieldErrorState(name: string, isError: boolean): void {
    this.errors = {
      ...this.errors,
      [name]: isError,
    };
  }

  closeCalendar(name: string): void {
    const value = this.values[name];
    if (value && isString(value)) {
      const [type, from, to] = value.split('::');

      if ((type === 'CUSTOM' && !from && !to) || (type === 'CUSTOM_DATE' && !from)) {
        this.setFilterValue(name, undefined);
      }
    }

    this.dateCalendarList[name] = false;
  }

  onEditClick(selectedFilter: FilterField): void {
    this.dateCalendarList[selectedFilter.name] = true;
  }

  onFilterToggle(): void {
    this.filterToggleDebouncer.next();
  }

  getCalendarVisibility(key: string): boolean {
    return this.dateCalendarList[key];
  }
}
