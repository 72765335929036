<div class="filter-title">
  <div class="filter-name-wrapper" (click)="toggleFilter()">
    <rkt-icon *ngIf="isCollapsable" class="filter-toggler" [name]="isCollapsed ? 'chevron-right' : 'chevron-down'"></rkt-icon>
    <div class="filter-name">{{ filterName }}</div>
  </div>
  <button *ngIf="!isDefaultValuesSelected()" rktButton btnType="danger" class="filter-reset-btn" (click)="reset()">Reset</button>
</div>

<ng-container *ngIf="!isCollapsed">
  <div *ngFor="let option of options; let i = index" class="rkt-form-checkbox">
    <label
      class="rkt-form-checkbox-label"
      [for]="id + '_' + i"
      [class.disabled]="option.disabled || (option.value === 'all' && isAllSelected())"
    >
      <input
        hidden
        type="checkbox"
        [id]="id + '_' + i"
        class="rkt-form-checkbox-input"
        [name]="id"
        [attr.value]="option.value"
        [value]="option.value"
        [checked]="isChecked(option)"
        [attr.disabled]="option.disabled || (option.value === 'all' && isAllSelected()) ? true : null"
        (change)="onCheckboxChange(option.value!, $event)"
      />
      <rkt-icon [name]="isChecked(option) ? 'checkbox-on' : 'checkbox-off'" class="rkt-form-checkbox-icon"></rkt-icon>

      <div class="rkt-form-checkbox-value">
        {{ option.label }}
      </div>
    </label>
  </div>
</ng-container>
