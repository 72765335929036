<div class="details-page-header-wrapper dark-bg" *ngIf="multiLegTransaction$ | async as multiLegTransaction; else loadingPlaceholder">
  <div class="page-header">
    <div class="details-page-back-title">
      <a [routerLink]="[pageReturnURL]" class="details-page-back-btn">
        <rkt-icon name="arrow-left" class="details-page-back-btn-icon"></rkt-icon>
      </a>

      <h1 class="details-page-title">{{ multiLegTransaction.id | rktShortId | uppercase }}</h1>

      <div class="stage-tag" [class.no-value]="!multiLegTransaction.stage">
        {{ multiLegTransaction.stage | titlecase }}
      </div>

      <div
        class="status-tag"
        [class.no-value]="!multiLegTransaction.status"
        [ngClass]="'status-tag-' + multiLegTransaction.status.toLowerCase()"
      >
        {{ multiLegTransaction.status | titlecase }}
      </div>
    </div>
    <app-breadcrumbs [itemList]="breadcrumbs"></app-breadcrumbs>
  </div>

  <div class="page-header-controls">
    <div class="entity-actions dark-bg">
      <button
        appRemoveFocus
        class="action-button action-button-edit"
        [disabled]="
          (multiLegTransaction.status === 'PROCESSING' ? multiLegTransaction.stage !== 'FUNDING' : multiLegTransaction.status !== 'PENDING')
            | accessControl
            | async
        "
        title="Edit Transaction"
        (click)="onEditBtnClick()"
      >
        <rkt-icon name="edit"></rkt-icon>
      </button>

      <button
        appRemoveFocus
        class="action-button action-button-cancel"
        [disabled]="
          multiLegTransaction.stage === 'CANCELLING' ||
            (!['PENDING', 'PROCESSING'].includes(multiLegTransaction.status) && multiLegTransaction.stage !== 'DISBURSING') ||
            (!['PENDING', 'FAILED'].includes(multiLegTransaction.status) && multiLegTransaction.stage === 'DISBURSING')
            | accessControl
            | async
        "
        title="Cancel Transaction"
        (click)="onCancelBtnClick({ stage: multiLegTransaction.stage, status: multiLegTransaction.status })"
      >
        <rkt-icon name="times"></rkt-icon>
      </button>
    </div>
  </div>
</div>

<ng-template #loadingPlaceholder>
  <app-details-page-header-placeholder [isDarkBg]="true" [pageReturnURL]="pageReturnURL"></app-details-page-header-placeholder>
</ng-template>

<div class="multi-leg-transaction-details-page">
  <div class="transaction-details">
    <div
      class="entity-details-container"
      appDragDropAttachment
      [entityId]="multiLegTransactionId"
      entityType="MULTI_LEG_TRANSACTION"
      [attachmentsList]="attachmentsList"
    >
      <div class="attachments-hover-overlay"></div>
      <ng-container *ngIf="multiLegTransaction$ | async as multiLegTransaction; else transactionLoading">
        <ng-container *ngTemplateOutlet="transactionDetails; context: { $implicit: multiLegTransaction, loading: false }"></ng-container>
      </ng-container>
      <ng-template #transactionLoading>
        <ng-container *ngTemplateOutlet="transactionDetails; context: { $implicit: null, loading: true }"></ng-container>
      </ng-template>

      <div class="separator-row"></div>
      <app-attachments-list #attachmentsList [entityId]="multiLegTransactionId" entityType="MULTI_LEG_TRANSACTION"></app-attachments-list>

      <app-details-notes [entityType]="'TRANSACTION'" [entityId]="multiLegTransactionId"></app-details-notes>
    </div>
  </div>

  <div class="history-column">
    <app-transaction-history
      [transactionHistoryItems]="transactionHistoryItems"
      [loading]="(multiLegTransaction$ | async) === null"
    ></app-transaction-history>
  </div>
</div>

<ng-template #transactionDetails [appTemplateContext]="multiLegTransactionDetailsContext" let-transaction let-loading="loading">
  <div class="details-section-title">
    <div class="title">Transaction Details</div>

    <div class="multi-leg-title" *ngIf="!loading">
      <div class="multi-leg-title-icon"></div>
      This is a Multi-Leg Transaction
    </div>
  </div>

  <app-details-item [showPlaceholder]="loading" label="Transaction ID" [value]="transaction?.id || '' | rktShortId"></app-details-item>

  <div class="separator-row"></div>

  <div class="amount-row">
    <div class="amount-label">
      <app-text-with-placeholder [showPlaceholder]="loading">Amount</app-text-with-placeholder>
    </div>
    <div class="amount-value">
      <app-text-with-placeholder [showPlaceholder]="loading" [darkBg]="true">
        {{ transaction?.totalAmount | amount: transaction?.currency }} {{ transaction?.currency }}
      </app-text-with-placeholder>
    </div>
  </div>

  <div class="from-to-row">
    <div class="from-to-item">
      <div class="title">
        <app-text-with-placeholder [showPlaceholder]="loading">From Account</app-text-with-placeholder>
      </div>

      <ng-container *ngIf="loading; else debitLegDataLoaded">
        <ng-container *ngTemplateOutlet="fromToAccount; context: { $implicit: undefined, transaction, loading }"></ng-container>
      </ng-container>

      <ng-template #debitLegDataLoaded>
        <ng-container *ngFor="let debitLeg of transaction?.debits">
          <ng-container *ngTemplateOutlet="fromToAccount; context: { $implicit: debitLeg, transaction, loading }"></ng-container>
        </ng-container>
      </ng-template>
    </div>

    <div class="from-to-item">
      <div class="title">
        <app-text-with-placeholder [showPlaceholder]="loading">To Account</app-text-with-placeholder>
      </div>

      <ng-container *ngIf="loading; else creditLegDataLoaded">
        <ng-container *ngTemplateOutlet="fromToAccount; context: { $implicit: undefined, transaction, loading }"></ng-container>
      </ng-container>

      <ng-template #creditLegDataLoaded>
        <ng-container *ngFor="let creditLeg of transaction?.credits">
          <ng-container *ngTemplateOutlet="fromToAccount; context: { $implicit: creditLeg, transaction, loading }"></ng-container>
        </ng-container>
      </ng-template>
    </div>
  </div>

  <div class="additional-details" *ngIf="!!transaction?.estLabel">
    <div class="additional-details-title">
      {{ transaction?.estLabel }}
    </div>
    <div class="additional-details-sections">
      <div class="additional-details-inner">
        <div class="details-item">
          <div class="details-label">Payment Reason:</div>
          <div class="details-value">{{ transaction?.credits?.[0]?.paymentReason  | notAvailable }}</div>
        </div>

        <ng-container *ngIf="transaction?.credits?.[0]?.solution === 'wire'">
          <div class="details-item">
            <div class="details-label">Narrative:</div>
            <div class="details-value">{{ transaction?.description }}</div>
          </div>

          <div class="details-item">
            <div class="details-label">Memo:</div>
            <div class="details-value" [class.empty-value]="!transaction?.memo">
              {{ transaction?.memo | notAvailable }}
            </div>
          </div>
        </ng-container>

        <ng-container *ngIf="transaction?.credits?.[0]?.solution === 'ach' && transaction?.credits?.[0] as achDetails">
          <div class="details-item">
            <div class="details-label">SEC Code:</div>
            <div class="details-value">
              {{ achDetails.metadata?.rkorACHEntryType || 'CCD' }}
            </div>
          </div>

          <div class="details-item" *ngIf="achDetails.metadata?.rkorACHIndividualId">
            <div class="details-label">Individual ID:</div>
            <div class="details-value">{{ achDetails.metadata?.rkorACHIndividualId }}</div>
          </div>

          <div class="details-item" *ngIf="achDetails.metadata?.rkorACHCheckSerialNumber">
            <div class="details-label">Check Serial Number:</div>
            <div class="details-value">{{ achDetails.metadata?.rkorACHCheckSerialNumber }}</div>
          </div>

          <div class="details-item" *ngIf="achDetails.metadata?.rkorACHTerminalCity">
            <div class="details-label">Terminal City:</div>
            <div class="details-value">{{ achDetails.metadata?.rkorACHTerminalCity }}</div>
          </div>

          <div class="details-item" *ngIf="achDetails.metadata?.rkorACHTerminalState">
            <div class="details-label">Terminal State:</div>
            <div class="details-value">{{ achDetails.metadata?.rkorACHTerminalState }}</div>
          </div>
        </ng-container>
      </div>
      <div class="additional-details-inner">
        <div class="details-item">
          <div class="details-label">Funds Delivery Estimate:</div>
          <div class="details-value">
            {{ transaction?.estLabelDay }} – {{ transaction?.estLabelDate }}
            <div class="details-value-estimation">
              We estimate the funds will settle at the receiving institution in the
              <span class="strong">{{ transaction?.estLabelTime }}</span> of
              <span class="strong">{{ transaction?.estLabelDate }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <ng-container *ngIf="!loading">
    <div class="transaction-metadata">
      <div class="transaction-metadata-title" (click)="isShowMetadata = !isShowMetadata">
        <div class="title">Metadata</div>
        <rkt-icon [name]="isShowMetadata ? 'chevron-down' : 'chevron-right'"></rkt-icon>
      </div>

      <div class="transaction-metadata-items" *ngIf="isShowMetadata">
        <div class="transaction-metadata-item transaction-metadata-header-item">
          <div class="key">Key</div>

          <div class="value">Value</div>
        </div>
        <div class="transaction-metadata-item" *ngFor="let metadataItem of transaction.metadataItems">
          <div class="key">{{ metadataItem.key }}</div>

          <div class="value">
            {{ metadataItem.value }}
          </div>
        </div>
        <div class="transaction-metadata-empty-item" *ngIf="!transaction.metadataItems.length">No Transaction Metadata</div>
      </div>
    </div>
  </ng-container>

  <div class="create-update-details">
    <app-details-item [showPlaceholder]="loading" label="Created At" [value]="transaction?.createdAt | dateFormat"></app-details-item>

    <app-details-item
      [showPlaceholder]="loading"
      label="Created By"
      [value]="transaction?.createdBy?.id | teamMemberName | async"
      [noBreak]="true"
    ></app-details-item>

    <app-details-item [showPlaceholder]="loading" label="Updated At" [value]="transaction?.updatedAt | dateFormat"></app-details-item>

    <app-details-item
      [showPlaceholder]="loading"
      label="Updated By"
      [value]="transaction?.updatedBy?.id | teamMemberName | async"
      [noBreak]="true"
    ></app-details-item></div
></ng-template>

<ng-template #fromToAccount [appTemplateContext]="legDetailsContext" let-legDetails let-transaction="transaction" let-loading="loading">
  <div class="item-body">
    <div class="item-body-row">
      <div class="status-tag" *ngIf="!loading" [ngClass]="'status-tag-' + legDetails?.latestStatus?.status?.toLowerCase()">
        {{ legDetails?.latestStatus?.status || (transaction?.status === 'FAILED' ? 'not processed' : 'pending') | titlecase }}
      </div>

      <div class="status-tag status-tag-placeholder" *ngIf="loading"><div class="loading-placeholder dark-bg"></div></div>
    </div>

    <ng-container
      *ngTemplateOutlet="
        itemBodyRows;
        context: {
          $implicit: [
            { value: legDetails?.financialAccount?.accountHolderInfo, label: 'Account Holder', splitable: true },
            { value: legDetails?.financialAccount?.accountInfo, label: 'Account Info', splitable: true },
            { value: legDetails?.financialAccount?.type, label: 'Account Type' },
            { value: legDetails?.financialAccount?.category, label: 'Account Category' },
            {
              value: legDetails?.financialAccount?.address,
              label: 'Address',
              className: 'item-body-row-with-separator item-body-row-tall',
              type: 'address'
            },
            {
              value: legDetails?.financialAccount?.bankAccount?.routingNo,
              label: 'Routing No.'
            }
          ],
          loading
        }
      "
    ></ng-container>
  </div>
</ng-template>

<ng-template #itemBodyRows let-loading="loading" let-rows>
  <div class="item-body-row" [class.empty-value]="!row.value" [ngClass]="row.className" *ngFor="let row of rows">
    <div class="label" *ngIf="row.label">
      <app-text-with-placeholder class="placeholder" [showPlaceholder]="loading" [darkBg]="true">{{ row.label }}</app-text-with-placeholder>
    </div>
    <div class="value" *ngIf="row.type !== 'address' || (row.type === 'address' && !row.value)">
      <ng-container *ngIf="row.splitable">
        <div [class.empty-value]="!(row.value || '' | rktSplit: '::':0)">
          <app-text-with-placeholder [showPlaceholder]="loading" [darkBg]="true">
            {{ row.value || '' | rktSplit: '::':0 | notAvailable }}
          </app-text-with-placeholder>
        </div>
        <div [class.empty-value]="!(row.value || '' | rktSplit: '::':1)">
          <app-text-with-placeholder [showPlaceholder]="loading" [darkBg]="true">
            {{ row.value || '' | rktSplit: '::':1 | notAvailable }}
          </app-text-with-placeholder>
        </div>
      </ng-container>

      <app-text-with-placeholder *ngIf="!row.splitable" class="placeholder" [showPlaceholder]="loading" [darkBg]="true">
        <ng-container *ngIf="row.type === 'solution'; else plainValue">
          <rkt-icon *ngIf="row.value" [name]="(row.value | solutionIcon)!"></rkt-icon>
          {{ row.value | solutionName }}
        </ng-container>

        <ng-template #plainValue>
          {{ row.value | notAvailable }}
        </ng-template>
      </app-text-with-placeholder>
    </div>

    <div class="value" *ngIf="row.type === 'address' && row.value">
      <app-text-with-placeholder class="placeholder" [showPlaceholder]="loading" [darkBg]="true">
        {{ row.value?.addressLine1 }},<br />
        <ng-container *ngIf="row.value?.addressLine2">{{ row.value?.addressLine2 }}<br /></ng-container>
        {{ row.value?.city }}, {{ row.value?.state }}<br />
        {{ row.value?.postalCode }}
        {{ row.value?.country }}
      </app-text-with-placeholder>
    </div>
  </div>
</ng-template>
