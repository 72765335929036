<ng-container *ngIf="exceptionDetails$ | async as exception; else exceptionDetailsLoading">
  <ng-container *ngTemplateOutlet="exceptionDetails; context: { $implicit: exception, loading: false }"></ng-container>
</ng-container>

<ng-template #exceptionDetailsLoading>
  <ng-container *ngTemplateOutlet="exceptionDetails; context: { $implicit: null, loading: true }"></ng-container>
</ng-template>

<ng-template #exceptionDetails [appTemplateContext]="exceptionDetailsContext" let-exception let-loading="loading">
  <div class="details-page-header-wrapper" *ngIf="!loading; else loadingPlaceholder">
    <div class="page-header">
      <div class="details-page-back-title">
        <a [routerLink]="[pageReturnURL]" class="details-page-back-btn">
          <rkt-icon name="arrow-left" class="details-page-back-btn-icon"></rkt-icon>
        </a>

        <h1 class="details-page-title">
          {{ exception?.id || '' | rktShortId }}
        </h1>
        <div class="status-tag" [class.no-value]="!exception.status" [ngClass]="'status-tag-' + exception.status.toLowerCase()">
          {{ exception.status.replace('_', ' ') | titlecase }}
        </div>
      </div>
      <app-breadcrumbs [itemList]="breadcrumbs"></app-breadcrumbs>
    </div>
  </div>

  <ng-template #loadingPlaceholder>
    <app-details-page-header-placeholder [pageReturnURL]="pageReturnURL" [withStatus]="false"></app-details-page-header-placeholder>
  </ng-template>

  <div class="exception-layout exception-layout-full-width">
    <div class="entity-details-container">
      <div class="details-section-title">
        <div class="title">
          <app-text-with-placeholder [showPlaceholder]="loading">Exception Details</app-text-with-placeholder>
        </div>
      </div>

      <div class="details-row">
        <app-assignee-select
          [selectedAssignee]="selectedAssignee"
          [selectedItem]="selectedAssignee?.value ?? 'UNASSIGNED'"
          (changed)="setSelectedAssignee($event)"
          [disabled]="!!(loading | accessControl | async)"
          (closed)="updateException(exception)"
        ></app-assignee-select>

        <app-status-select
          [selectedItem]="selectedStatus ?? ''"
          [items]="statusOptions"
          [disabled]="!!(!exception?.assignedTo?.id | accessControl | async)"
          (changed)="setSelectedStatus($event)"
          (closed)="updateException(exception)"
        ></app-status-select>
      </div>

      <div class="separator-row"></div>

      <div class="details-row details-row-with-offset">
        <app-details-item [showPlaceholder]="loading" label="Exception ID" [value]="exception?.id || '' | rktShortId"></app-details-item>

        <app-details-item [showPlaceholder]="loading" label="Unique ID" [contentValue]="true">
          <div class="details-item-link-container">
            <a class="page-link" [routerLink]="exception?.entityLink" [rktTooltip]="'Open ' + exception?.categoryLabel + ' Details'">
              <rkt-icon name="link"></rkt-icon>
              {{ exception?.entityId || '' | rktShortId }}
            </a>
          </div>
        </app-details-item>

        <app-details-item [showPlaceholder]="loading" label="Exception Category" [value]="exception?.categoryLabel"></app-details-item>

        <app-details-item [showPlaceholder]="loading" label="Exception Type" [value]="exception?.typeLabel"></app-details-item>

        <app-details-item [showPlaceholder]="loading" label="Description" [value]="exception?.description"></app-details-item>
      </div>
    </div>
  </div>

  <div class="exception-layout exception-layout-with-side-panel" *ngIf="exception">
    <div class="exception-layout-section">
      <div class="entity-details-container">
        <app-notes-feed [loading]="loading" [entityId]="exception.id" entityType="EXCEPTION"></app-notes-feed>
      </div>
    </div>

    <div class="exception-layout-section">
      <div class="entity-details-container">
        <div class="details-col">
          <app-details-item [showPlaceholder]="loading" label="Created At" [value]="exception?.createdAt | dateFormat"></app-details-item>

          <app-details-item
            [showPlaceholder]="loading"
            label="Created By"
            [value]="exception?.createdBy?.id | teamMemberName | async"
          ></app-details-item>
        </div>

        <div class="separator-row"></div>

        <div class="details-col">
          <app-details-item [showPlaceholder]="loading" label="Updated At" [value]="exception?.updatedAt | dateFormat"></app-details-item>

          <app-details-item
            [showPlaceholder]="loading"
            label="Updated By"
            [value]="exception?.updatedBy?.id | teamMemberName | async"
          ></app-details-item>

          <app-details-item
            [showPlaceholder]="loading"
            label="Updated"
            [value]="(exceptionHistory$|async)?.[0]?.description"
          ></app-details-item>
        </div>

        <button rktButton btnType="link" class="view-history-btn" [disabled]="loading" (click)="showHistoryModal()">
          <rkt-icon name="clock"></rkt-icon>
          View History
        </button>
      </div>
    </div>
  </div>
</ng-template>
