<div class="entity-actions" *ngIf="financialAccount && financialAccount.state !== 'DELETED'">
  <button
    *ngIf="financialAccount"
    class="action-button action-button-edit"
    [disabled]="isLoading | accessControl | async"
    (click)="onEdit()"
    title="Update Financial Account"
  >
    <rkt-icon name="edit"></rkt-icon>
  </button>

  <button
    *ngIf="financialAccount && financialAccount.category !== 'INTEGRATED'"
    class="action-button action-button-delete"
    [disabled]="isLoading | accessControl | async"
    (click)="onRemove()"
    title="Remove Financial Account"
  >
    <rkt-icon name="trash"></rkt-icon>
  </button>

  <button
    *ngIf="!['SUSPENDED', 'PENDING'].includes(financialAccountState) && !isIntegratedCard"
    class="action-button action-button-suspend"
    [disabled]="isLoading | accessControl | async"
    (click)="onStateChange()"
    title="Suspend Financial Account"
  >
    <rkt-icon name="suspend"></rkt-icon>
  </button>
  <button
    *ngIf="financialAccountState === 'SUSPENDED' && !isIntegratedCard"
    class="action-button action-button-unsuspend"
    [disabled]="isLoading | accessControl | async"
    (click)="onStateChange()"
    title="Unsuspend Financial Account"
  >
    <rkt-icon name="resume"></rkt-icon>
  </button>
</div>
