<div class="document-header">
  <div class="scheduled-report-header">
    <button class="back-button" (click)="viewReports()">
      <rkt-icon name="arrow-left"></rkt-icon>
    </button>
    <div class="document-title">Scheduled Reports</div>
  </div>

  <button class="close-button" (click)="closeModal()">
    <rkt-icon name="times"></rkt-icon>
  </button>
</div>

<div class="scheduled-report-page">
  <router-outlet></router-outlet>
</div>
