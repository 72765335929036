import {
  IdentityVerificationApplicationRaw,
  IdentityVerificationProcessingSummary,
  RequestPageParams,
  VerificationStatus,
} from '@shared/models';
import { HttpParams } from '@angular/common/http';
import { CustomHttpParamEncoder } from '@shared/encoder';
import { constants } from '@shared/constants';
import { filterCreatedAtDateRange, systemTimeZone, toPhone, toTitleCase } from '@shared/utils/index';
import { toLower, compact } from 'lodash-es';

export const idvRequestParams = (requestParams: RequestPageParams): HttpParams => {
  const { page, size, sortParams, activeFilters } = requestParams;
  let params = new HttpParams({ encoder: new CustomHttpParamEncoder() });

  params = params.append('size', size ?? constants.TABLE_ROWS);

  if (page) {
    params = params.append('page', page);
  }

  if (sortParams) {
    params = params.append('sort', `${sortParams.key},${sortParams.sortDir}`);
  }

  if (activeFilters) {
    Object.keys(activeFilters).forEach((key) => {
      if (key === 'createdAt' || key === 'completedAt') {
        const { from, to } = filterCreatedAtDateRange(activeFilters[key]) ?? {};

        if (!from || !to) {
          return;
        }

        params = params.append(key === 'createdAt' ? 'createdDateFrom' : 'completedDateFrom', from);
        params = params.append(key === 'createdAt' ? 'createdDateTo' : 'completedDateTo', to);
        params = params.append('timeZone', systemTimeZone);
      } else if (key === 'outcome') {
        params = params.append(key, toTitleCase(activeFilters[key]));
      } else {
        params = params.append(key, activeFilters[key]!);
      }
    });
  }

  return params;
};

export const formatSocialSecurityNumber = (socialId: string | undefined) => {
  if (!socialId) return undefined;

  if (socialId.length === 4) {
    return socialId;
  }

  return `${socialId.slice(0, 3)}-${socialId.slice(3, 5)}-${socialId.slice(5, 9)}`;
};

export const formatBusinessTaxId = (taxId: string | undefined) => {
  if (!taxId) return undefined;

  return `${taxId.slice(0, 2)}-${taxId.slice(2)}`;
};

export const identityProcessingSummarySerializer = (
  identityApplicationData: IdentityVerificationApplicationRaw,
): IdentityVerificationProcessingSummary => {
  const docArrayTypes: string[] = [];

  const processingSummaryData = identityApplicationData['processingSummary'];

  if (!processingSummaryData) return {};

  Object.keys(processingSummaryData).map((key: string) => {
    const keyValue = key.split('.');
    if (keyValue[0] === 'DOCS') docArrayTypes.push(keyValue[1]?.split('_')?.join(' '));
  });

  return {
    displayName:
      compact([processingSummaryData?.['NAME.FIRST'], processingSummaryData?.['NAME.LAST']])?.join(' ') ||
      processingSummaryData?.['NAME.BUSINESS_LEGAL_NAME'],
    emailAddress: processingSummaryData?.['INFO.PRIMARY_EMAIL_ADDRESS'],
    dateOfBirth: processingSummaryData?.['INFO.DATE_OF_BIRTH'],
    phoneNumber: toPhone(processingSummaryData?.['INFO.PRIMARY_PHONE_NUMBER']),
    address: {
      addressLine1: processingSummaryData?.['ADDRESS.LINE1'],
      addressLine2: processingSummaryData?.['ADDRESS.LINE2'],
      city: processingSummaryData?.['ADDRESS.CITY'],
      state: processingSummaryData?.['ADDRESS.STATE'],
      postalCode: processingSummaryData?.['ADDRESS.POSTAL_CODE'],
      country: processingSummaryData?.['ADDRESS.COUNTRY'],
    },
    entityVerificationStatus: toLower(identityApplicationData?.status),
    latestStatus: toTitleCase(identityApplicationData?.status),
    entityVerificationOutcome: identityApplicationData?.outcome,
    accountTypeInfo: identityApplicationData?.accountType || identityApplicationData?.entityType,
    collapseSectionExpanded: false,
    documentList: docArrayTypes,
    docUploadUrl: identityApplicationData?.docUploadUrl,
    outcomeDetailsTags: identityApplicationData.outcomeDetails?.tags,
  };
};

/* 
    FE: Outcome tags Color code
    https://rocketbnk.atlassian.net/wiki/spaces/RKOR/pages/edit-v2/3360686115
*/

export const getIdvOutcomeTagColor = (value?: string): string | undefined => {
  if (!value) {
    return;
  }

  const outcomeTags: Record<string, string> = {
    'Velocity Warning': 'warning',
    'CIP Review': 'warning',
    'Phone Match': 'success',
    'Vouched Result Passed': 'success',
    'Vouched Result Review': 'warning',
    'Document Address Not Verified': 'warning',
    'Document DOB Not Verified': 'warning',
    'Document Name Not Verified': 'warning',
    'Document Address Verified': 'success',
    'Document DOB Verified': 'success',
    'Document Name Verified': 'success',
    'Document ID Expired': 'warning',
    'Vouched Result Failed': 'error',
    'IP Address Velocity': 'warning',
    'SSN Velocity': 'warning',
    'High Fraud Risk': 'error',
    'CIP Address Verified': 'success',
    'CIP DOB Verified': 'success',
    'CIP Name Verified': 'success',
    'CIP SSN Verified': 'success',
    'CIP Address Not Verified': 'warning',
    'CIP DOB Not Verified': 'warning',
    'CIP Name Not Verified': 'warning',
    'CIP SSN Not Verified': 'warning',
    'Watchlist Review': 'warning',
    'High Synthetic Fraud Risk': 'error',
    'Medium Synthetic Fraud Risk': 'warning',
    'SSN Warning': 'error',
    'Medium Device Risk': 'warning',
    'Foreign or Unknown Device': 'warning',
    'High Device Risk': 'error',
    'Fraud Review': 'warning',
    'SSN Miskey': 'warning',
    'DOB Miskey': 'warning',
    'Email Warning': 'warning',
    'Address Warning': 'error',
    'Medium Fraud Risk': 'warning',
    'DOB Mismatch': 'warning',
    'FEIN Document Required': 'warning',
    'FEIN Unmatched': 'error',
    'Address Not Verified': 'error',
    'Behavioral Warning': 'error',
    'Identity Theft Warning': 'error',
    'Synthetic Fraud Risk': 'error',
    'Synthetic Fraud Warning': 'error',
    'Denied Fraud': 'error',
    'Denylist Alert': 'error',
    'Device Warning': 'error',
    'Business Name Unmatched': 'warning',
    'Watchlist Warning': 'warning',
    'Secretary of State Warning': 'warning',
    'Behavioral Risk': 'warning',
    'Identity Theft Risk': 'warning',
    'Fraud Risk': 'warning',
    'Device Risk': 'warning',
    'SSN/Email/Device/Address Velocity': 'warning',
    'Email Velocity': 'warning',
    'Phone Velocity': 'warning',
    'SSN Mismatch': 'warning',
    'Name Mismatch': 'warning',
    'Address Mismatch': 'warning',
    'KYC Failed': 'error',
    'OFAC Match': 'warning',
    'PEP Match': 'warning',
    'Representative Match': 'success',
    'FEIN Found': 'success',
    'Address Matched': 'success',
    'Business Name Matched': 'success',
    'Name Match': 'success',
    'Address Match': 'success',
    'SSN Match': 'success',
    'DOB Match': 'success',
    'Middesk Pending': 'warning',
  };

  return outcomeTags[value] || undefined;
};

export const mapVerifactionStatus = ({
  verificationStatus,
  updatedAt,
}: {
  verificationStatus?: VerificationStatus;
  updatedAt: string;
}): VerificationStatus => {
  return (
    verificationStatus ?? {
      status: 'UNVERIFIED',
      reason: 'New Account',
      updatedAt,
      updatedBy: {
        id: '99999999-9999-9999-9999-999999999999',
        type: 'internal',
      },
    }
  );
};
