<div class="list-page-header" *ngIf="loading || documents.length">
  <div class="list-page-controls">
    <ng-container *ngTemplateOutlet="uploadDocumentButton"></ng-container>
  </div>
</div>

<rkt-table
  *ngIf="loading || documents.length; else emptyList"
  [rows]="documents"
  [columns]="columns"
  [limit]="10"
  (fetchData)="getDocuments($event)"
  (rowActivated)="onRowClick($event)"
  [count]="totalElements"
  [loadingIndicator]="loading"
  [offset]="activePage"
  [sortParam]="sortParams"
  dateTimeFormat="MM/dd/yy HH:mm"
  [messages]="{ emptyMessage: emptyListMessage }"
  emptyValuePlaceholder="--"
></rkt-table>

<ng-template #emptyList>
  <div class="empty-container">
    <div class="empty-container-icon">
      <rkt-icon name="identification-checked"></rkt-icon>
    </div>
    <div class="empty-container-description">Upload the first document</div>
    <div>
      <ng-container *ngTemplateOutlet="uploadDocumentButton"></ng-container>
    </div>
  </div>
</ng-template>

<ng-template #uploadDocumentButton>
  <button
    rktButton
    btnType="primary"
    title="Upload Document"
    (click)="onAddDocumentBtnClick()"
    class="add-entity-btn"
    [disabled]="!!(loading | accessControl | async)"
  >
    <rkt-icon name="upload"></rkt-icon>
    Upload Document
  </button>
</ng-template>
