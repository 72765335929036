import { Component, inject, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { ActivatedRoute } from '@angular/router';
import { tap } from 'rxjs';
import { ModalService } from '@rocketfinancialcorp/rocket-ui/modal';
import { NavigationService } from '@shared/services';
import { ExceptionManagementActions, exceptionManagementFeature } from '@shared/store';
import { Breadcrumb, DropdownItem, ExceptionDetails, TemplateContext } from '@shared/models';
import { ExceptionHistoryModalComponent } from '@shared/components';

@Component({
  selector: 'app-exception-management-details',
  templateUrl: './exception-management-details.component.html',
})
export class ExceptionManagementDetailsComponent implements OnInit {
  navigationService = inject(NavigationService);

  modalService = inject(ModalService);

  activatedRoute = inject(ActivatedRoute);

  store = inject(Store);

  exceptionId: string;

  exceptionDetailsContext?: TemplateContext<ExceptionDetails>;

  exceptionDetails$ = this.store.select(exceptionManagementFeature.selectExceptionDetails).pipe(
    tap({
      next: (exception) => {
        this.selectedAssignee = exception?.assignedTo?.id ? { label: exception.assignee!, value: exception.assignedTo.id } : undefined;
        this.selectedStatus = exception?.status;
        this.setStatusOptions();
      },
    }),
  );

  exceptionHistory$ = this.store.select(exceptionManagementFeature.selectExceptionHistoryItems);

  pageReturnURL = '/app/exception-management/exceptions';

  breadcrumbs: Breadcrumb[] = [{ label: 'Exceptions', url: this.pageReturnURL }, { label: 'Exception Details' }];

  selectedAssignee?: DropdownItem;

  selectedStatus?: string;

  statusOptions: DropdownItem[] = [];

  constructor() {
    this.exceptionId = this.activatedRoute.snapshot?.paramMap.get('exceptionId')!;
  }

  ngOnInit() {
    this.store.dispatch(ExceptionManagementActions.loadExceptionDetails());
  }

  showHistoryModal() {
    this.modalService.open(ExceptionHistoryModalComponent, { className: 'exception-history-modal' });
  }

  setSelectedAssignee(item?: DropdownItem) {
    this.selectedAssignee = item;
  }

  setSelectedStatus(item?: DropdownItem) {
    this.selectedStatus = item?.value;
  }

  updateException(exception: ExceptionDetails) {
    if (exception.assignedTo?.id !== this.selectedAssignee?.value) {
      this.store.dispatch(
        ExceptionManagementActions.updateException({
          data: { assignedTo: this.selectedAssignee?.value },
        }),
      );
    } else if (exception.status !== this.selectedStatus) {
      this.store.dispatch(
        ExceptionManagementActions.updateException({
          data: { status: this.selectedStatus },
        }),
      );
    }
  }

  setStatusOptions() {
    let options: DropdownItem[] = [];

    switch (this.selectedStatus) {
      case 'NEW':
        options = [
          { label: 'New', value: 'NEW' },
          { label: 'In Progress', value: 'IN_PROGRESS' },
          { label: 'Resolved', value: 'RESOLVED' },
        ];
        break;

      case 'IN_PROGRESS':
        options = [
          { label: 'In Progress', value: 'IN_PROGRESS' },
          { label: 'Resolved', value: 'RESOLVED' },
        ];
        break;

      case 'RESOLVED':
        options = [
          { label: 'Reopened', value: 'REOPENED' },
          { label: 'Resolved', value: 'RESOLVED' },
        ];
        break;

      case 'REOPENED':
        options = [
          { label: 'In Progress', value: 'IN_PROGRESS' },
          { label: 'Reopened', value: 'REOPENED' },
        ];
        break;

      default:
        break;
    }

    this.statusOptions = options;
    return;
  }
}
