<div class="notes-feed-item">
  <div class="note-feed-item-title">
    <div class="author">{{ note.createdBy.id | teamMemberName | async }}</div>
    <div class="date">{{ note.createdAt | dateFormat }}</div>
  </div>
  <div class="note-feed-item-content">
    {{ note.contentText }}
  </div>
  <div class="note-feed-item-attachments" *ngIf="note.attachments.length">
    <div class="attachments-item">
      <div class="attachments-item-title">
        <div class="attachments-count">Attachments ({{ note.attachments.length }})</div>
        <div class="view-type-switcher" (click)="onViewTypeClick()">
          <div class="type-switch-btn btn-left" [class.active]="viewType === 'tiles'">
            <rkt-icon name="tiles"></rkt-icon>
          </div>

          <div class="type-switch-btn btn-right" [class.active]="viewType === 'list'">
            <rkt-icon name="list"></rkt-icon>
          </div>
        </div>
      </div>

      <div class="attachments-item-files">
        <ng-container *ngIf="viewType === 'tiles'">
          <rkt-carousel [items]="note.attachments" [itemsVisible]="3" [itemsScroll]="3" [responsiveBreakpoints]="responsiveBreakpoints">
            <ng-template let-item rktCarouselItem="item">
              <div class="file-item" (click)="onViewAttachmentCLick(item)">
                <div class="file-name">
                  <rkt-icon
                    [name]="item.extension?.includes('PDF') ? 'attachment-pdf' : 'attachment-img'"
                    class="attachment-icon"
                  ></rkt-icon>

                  <span class="file-name-text">{{ item.name }}</span>
                </div>
                <div class="file-image">
                  <img class="file-image-preview" [src]="item.thumbnailUrl?.value" *ngIf="item.thumbnailUrl?.value" [alt]="item.name" />
                  <div class="banner-text">{{ getAttachmentScanLabel(item) }}</div>
                </div>
              </div>
            </ng-template>
          </rkt-carousel>
        </ng-container>

        <div class="notes-feed-attachments-list" *ngIf="viewType === 'list'">
          <rkt-table
            [rows]="note.attachments"
            [columns]="columns"
            [limit]="10"
            (rowActivated)="onViewAttachmentCLick($event)"
            (colButtonClicked)="onColButtonClick($event)"
            [count]="note.attachments.length"
            [loadingIndicator]="loading"
            [offset]="0"
            [externalPaging]="false"
            dateTimeFormat="MM/dd/yy HH:mm"
            [messages]="{ emptyMessage: '' }"
            emptyValuePlaceholder="--"
          >
          </rkt-table>
        </div>
      </div>
    </div>
  </div>
</div>
