import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { EMPTY, of } from 'rxjs';
import { Store } from '@ngrx/store';
import { flatten, isEmpty, omitBy } from 'lodash-es';
import { Router } from '@angular/router';
import { catchError, map, tap, switchMap, mergeMap, takeUntil, expand, reduce } from 'rxjs/operators';
import { MultiLegTransactionsService, ScheduledTransactionsService, TransactionService } from '@shared/services';
import { ErrorUtils } from '@shared/utils';
import { MessagesActions, TransactionFormActions, fromAuth, selectRouteParams, selectUrl, transactionFeature } from '@shared/store';
import { TransactionHistory, TransactionHistoryList } from '@shared/models';
import {
  MultiLegTransactionActions,
  ScheduledTransactionActions,
  SingleLegTransactionActions,
  TransactionsActions,
} from './transactions.actions';

@Injectable()
export class TransactionsEffects {
  constructor(
    private actions$: Actions,
    private transactionService: TransactionService,
    private scheduledTransactionsService: ScheduledTransactionsService,
    private multiLegTransactionsService: MultiLegTransactionsService,
    private store: Store,
    private router: Router,
  ) {}

  loadCardTransactions$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(TransactionsActions.loadCardTransactions),
      concatLatestFrom(() => [
        this.store.select(fromAuth.selectBusinessAccountId),
        this.store.select(transactionFeature.selectCardTransactionsActiveFilters),
      ]),
      mergeMap(([payload, activeBusinessAccountId, activeFilters]) => {
        return this.transactionService
          .getTransactionList(activeBusinessAccountId, {
            ...payload.params,
            solution: 'i2c-transaction',
            activeFilters: activeFilters ?? undefined,
          })
          .pipe(
            map((response) => {
              const { items, totalElements } = response;
              const searchString = payload.params.searchString;

              if (!searchString) {
                return TransactionsActions.loadCardTransactionsSuccess({
                  transactionListItems: items,
                  totalElements: totalElements,
                });
              } else {
                const transactionListItems = items.filter((item) => item.id?.toLowerCase().indexOf(searchString) !== -1);

                return TransactionsActions.loadCardTransactionsSuccess({
                  transactionListItems,
                  totalElements: transactionListItems.length,
                });
              }
            }),
            catchError((error) => {
              return of(TransactionsActions.loadCardTransactionsFailure({ error }));
            }),
          );
      }),
    );
  });

  loadCardTransactionsFailure$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(TransactionsActions.loadCardTransactionsFailure),
      tap({
        next: (errorResponse) => {
          ErrorUtils.catchError('transactionService.getTransactionList - Card Transactions - Error', errorResponse.error);
        },
      }),
      switchMap(() => of(MessagesActions.displayError({ message: 'Unable to fetch Card Transaction Activity.' }))),
    );
  });

  scheduledTransactionsLoad$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ScheduledTransactionActions.load),
      concatLatestFrom(() => [this.store.select(transactionFeature.selectScheduledTransactionsActiveFilters)]),
      mergeMap(([payload, activeFilters]) => {
        const name = payload.params.searchString;

        return this.scheduledTransactionsService
          .getScheduledTransactions({
            ...payload.params,
            activeFilters: activeFilters ?? undefined,
            name,
          })
          .pipe(
            map((response) => {
              const { items, totalElements } = response;

              return ScheduledTransactionActions.loadSuccess({
                transactionListItems: items,
                totalElements: totalElements,
              });
            }),
            catchError((error) => {
              return of(ScheduledTransactionActions.loadFailure({ error }));
            }),
          );
      }),
    );
  });

  scheduledTransactionsLoadFailure$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ScheduledTransactionActions.loadFailure),
      tap({
        next: (errorResponse) => {
          ErrorUtils.catchError('transactionService.getScheduledTransactions Error', errorResponse.error);
        },
      }),
      switchMap(() => of(MessagesActions.displayError({ message: 'Unable to fetch Scheduled Transactions.' }))),
    );
  });

  loadScheduledTransactionDetails$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ScheduledTransactionActions.loadDetails),
      concatLatestFrom(() => [this.store.select(fromAuth.selectBusinessAccountId), this.store.select(selectRouteParams)]),
      mergeMap(([, businessAccountId, routeParams]) => {
        return this.scheduledTransactionsService.getScheduledTransactionById(businessAccountId, routeParams.scheduledTransactionId).pipe(
          map((response) => ScheduledTransactionActions.loadDetailsSuccess({ scheduledTransactionDetails: response })),
          catchError((error) => {
            return of(ScheduledTransactionActions.loadDetailsFailure({ error }));
          }),
        );
      }),
    );
  });

  loadScheduledTransactionDetailsError$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ScheduledTransactionActions.loadDetailsFailure),
      tap({
        next: (loadFinancialAccountError) => {
          ErrorUtils.catchError('scheduledTransactionsService.getScheduledTransactionById error', loadFinancialAccountError.error);
        },
      }),
      switchMap(() => of(MessagesActions.displayError({ message: 'Unable to fetch Scheduled Transaction Details.' }))),
    );
  });

  loadScheduledTransactionDetailsErrorRedirect$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(ScheduledTransactionActions.loadDetailsFailure),
        concatLatestFrom(() => this.store.select(selectUrl)),
        tap({
          next: ([, currentUrl]) => {
            const prevUrl = currentUrl.split('/').slice(0, -1).join('/');
            this.router.navigateByUrl(prevUrl);
          },
        }),
      );
    },
    { dispatch: false },
  );

  multiLegTransactionsLoad$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(MultiLegTransactionActions.load),
      concatLatestFrom(() => [this.store.select(transactionFeature.selectMultiLegTransactionsActiveFilters)]),
      mergeMap(([payload, activeFilters]) => {
        return this.multiLegTransactionsService
          .getMultiLegTransactions({
            ...payload.params,
            activeFilters: omitBy(activeFilters, isEmpty),
          })
          .pipe(
            map((response) => {
              const { items, totalElements } = response;

              return MultiLegTransactionActions.loadSuccess({
                transactionListItems: items,
                totalElements: totalElements,
              });
            }),
            catchError((error) => {
              return of(MultiLegTransactionActions.loadFailure({ error }));
            }),
          );
      }),
    );
  });

  multiLegTransactionsLoadFailure$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(MultiLegTransactionActions.loadFailure),
      tap({
        next: (errorResponse) => {
          ErrorUtils.catchError('transactionService.getMultiLegTransactions Error', errorResponse.error);
        },
      }),
      switchMap(() => of(MessagesActions.displayError({ message: 'Unable to fetch Multi-Leg Transactions.' }))),
    );
  });

  loadMultiLegTransactionDetails$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(MultiLegTransactionActions.loadDetails),
      concatLatestFrom(() => [this.store.select(selectRouteParams)]),
      mergeMap(([, routeParams]) => {
        return this.multiLegTransactionsService.getMultiLegTransactionById(routeParams.multiLegTransactionId).pipe(
          map((response) => MultiLegTransactionActions.loadDetailsSuccess({ multiLegTransactionDetails: response })),
          catchError((error) => {
            return of(MultiLegTransactionActions.loadDetailsFailure({ error }));
          }),
        );
      }),
    );
  });

  loadMultiLegTransactionDetailsError$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(MultiLegTransactionActions.loadDetailsFailure),
      tap({
        next: (errorRes) => {
          ErrorUtils.catchError('multiLegTransactionsService.getMultiLegTransactionById error', errorRes.error);
        },
      }),
      switchMap(() => of(MessagesActions.displayError({ message: 'Unable to fetch Multi-Leg Transaction Details.' }))),
    );
  });

  loadMultiLegTransactionDetailsErrorRedirect$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(MultiLegTransactionActions.loadDetailsFailure),
        concatLatestFrom(() => this.store.select(selectUrl)),
        tap({
          next: ([, currentUrl]) => {
            const prevUrl = currentUrl.split('/').slice(0, -1).join('/');
            this.router.navigateByUrl(prevUrl);
          },
        }),
      );
    },
    { dispatch: false },
  );

  cancelMltGetDefaultFinancialAccount$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(TransactionFormActions.formCancelMltInitialize),
      concatLatestFrom(() => [this.store.select(fromAuth.selectBusinessAccountId)]),
      mergeMap(([, businessAccountId]) => {
        return this.multiLegTransactionsService.getDefaultBusinessAccountFinancialAccount(businessAccountId).pipe(
          map((response) => MultiLegTransactionActions.setDefaultBusinessAccountFinancialAccount({ financialAccount: response })),
          catchError(() => {
            return of(MultiLegTransactionActions.setDefaultBusinessAccountFinancialAccount({ financialAccount: null }));
          }),
        );
      }),
    );
  });

  loadSingleLegTransactionDetails$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SingleLegTransactionActions.loadDetails),
      concatLatestFrom(() => [this.store.select(fromAuth.selectBusinessAccountId), this.store.select(selectRouteParams)]),
      mergeMap(([, businessAccountId, routeParams]) => {
        return this.transactionService.getTransactionById(businessAccountId, routeParams.transactionId).pipe(
          map((response) => SingleLegTransactionActions.loadDetailsSuccess({ singleLegTransactionDetails: response })),
          catchError((error) => {
            return of(SingleLegTransactionActions.loadDetailsFailure({ error }));
          }),
        );
      }),
    );
  });

  loadSingleLegTransactionDetailsError$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SingleLegTransactionActions.loadDetailsFailure),
      tap({
        next: (errorRes) => {
          ErrorUtils.catchError('transactionService.getTransactionById error', errorRes.error);
        },
      }),
      switchMap(() => of(MessagesActions.displayError({ message: 'Unable to fetch Transaction Details.' }))),
    );
  });

  loadSingleLegTransactionDetailsErrorRedirect$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(SingleLegTransactionActions.loadDetailsFailure),
        concatLatestFrom(() => [this.store.select(selectUrl), this.store.select(selectRouteParams)]),
        tap({
          next: ([, currentUrl, routeParams]) => {
            const prevUrl = currentUrl
              .split('/')
              .slice(0, routeParams.exceptionId ? -2 : -1)
              .join('/');
            this.router.navigateByUrl(prevUrl);
          },
        }),
      );
    },
    { dispatch: false },
  );

  loadSingleLegTransactionHistory$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SingleLegTransactionActions.loadDetails),
      concatLatestFrom(() => [this.store.select(selectRouteParams)]),
      mergeMap(([, routeParams]) => {
        let historyPageNumber = 0;
        const transactionId = routeParams.transactionId;
        return this.transactionService.getTransactionHistory({ transactionId, size: 200, page: historyPageNumber }).pipe(
          expand((response) => {
            if (response.hasNext) {
              historyPageNumber += 1;

              return this.transactionService.getTransactionHistory({
                transactionId: transactionId!,
                size: 200,
                page: historyPageNumber,
              });
            } else {
              return EMPTY;
            }
          }),
          reduce<TransactionHistoryList, TransactionHistory[]>((acc, current) => acc.concat(current.items), []),
          map((response) => SingleLegTransactionActions.loadTransactionHistorySuccess({ transactionHistory: flatten(response) })),
          catchError((error) => {
            ErrorUtils.catchError('transactionService.getTransactionHistory error', error);
            return of(SingleLegTransactionActions.loadTransactionHistoryFailure({ error }));
          }),
          takeUntil(this.actions$.pipe(ofType(SingleLegTransactionActions.loadDetails))),
        );
      }),
    );
  });

  loadScheduledTransactionHistory$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ScheduledTransactionActions.loadDetails),
      concatLatestFrom(() => [this.store.select(fromAuth.selectBusinessAccountId), this.store.select(selectRouteParams)]),
      mergeMap(([, businessAccountId, routeParams]) => {
        let historyPageNumber = 0;
        const transactionId = routeParams.scheduledTransactionId;
        return this.scheduledTransactionsService
          .getTransactionHistory({ businessAccountId, transactionId, size: 200, page: historyPageNumber })
          .pipe(
            expand((response) => {
              if (response.hasNext) {
                historyPageNumber += 1;

                return this.scheduledTransactionsService.getTransactionHistory({
                  businessAccountId,
                  transactionId: transactionId!,
                  size: 200,
                  page: historyPageNumber,
                });
              } else {
                return EMPTY;
              }
            }),
            reduce<TransactionHistoryList, TransactionHistory[]>((acc, current) => acc.concat(current.items), []),
            map((response) => ScheduledTransactionActions.loadTransactionHistorySuccess({ transactionHistory: flatten(response) })),
            catchError((error) => {
              ErrorUtils.catchError('scheduledTransactionsService.getTransactionHistory error', error);
              return of(ScheduledTransactionActions.loadTransactionHistoryFailure({ error }));
            }),
            takeUntil(this.actions$.pipe(ofType(ScheduledTransactionActions.loadDetails))),
          );
      }),
    );
  });
}
