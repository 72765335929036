import * as fromAskMeAnything from './ask-me-anything.reducer';
import { askMeQuestionFeatureKey } from './ask-me-anything.reducer';
import { fromRoot } from '@shared/store';
import { Action, combineReducers, createFeatureSelector, createSelector } from '@ngrx/store';

export const AskMeAnythingStoreKey = 'ask-me-anything-store';

export interface AskMeAnythingState {
  [fromAskMeAnything.askMeQuestionFeatureKey]: fromAskMeAnything.AskMeAnythingQuestionState;
}

export interface State extends fromRoot.RootState {
  [AskMeAnythingStoreKey]: AskMeAnythingState;
}

export const reducers = (state: AskMeAnythingState | undefined, action: Action) => {
  return combineReducers({
    [fromAskMeAnything.askMeQuestionFeatureKey]: fromAskMeAnything.reducer,
  })(state, action);
};

export const selectAskMeAnythingState = createFeatureSelector<AskMeAnythingState>(AskMeAnythingStoreKey);

export const selectAskMeAnythingListState = createSelector(selectAskMeAnythingState, (state) => state[askMeQuestionFeatureKey]);

export const selectAskMeAnythingQuestions = createSelector(selectAskMeAnythingListState, fromAskMeAnything.getAskMeAnythingQuestions);
