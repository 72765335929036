import { InsightDocument } from '@shared/models';
import { months } from '@modules/insights/components';
import { getMonth, getYear, parseJSON } from 'date-fns';

export const groupReportByDate = (documents: InsightDocument[]) => {
  const group = documents.reduce((acc, curr) => {
    const key = `${months[getMonth(parseJSON(curr.createdAt))]} ${getYear(parseJSON(curr.createdAt))}`;

    if (!acc[key]) {
      acc[key] = [];
    }
    acc[key].push(curr);
    return acc;
  }, {} as Record<string, InsightDocument[]>);

  return Object.keys(group).map((key) => ({
    groupCategory: key,
    documents: group[key],
  }));
};
