<div class="latest-note" [class.compact]="isCompact">
  <div class="latest-note-header">
    <div class="latest-note-title">
      Recent Note
      <button
        rktButton
        btnType="link"
        class="view-all-notes-btn"
        [disabled]="!!(isShowPlaceholder | accessControl | async)"
        (click)="onViewAllBtnClick()"
      >
        <rkt-icon name="plus"></rkt-icon>
        Add note
      </button>
    </div>
    <button rktButton btnType="link" *ngIf="latestNote" class="view-all-notes-btn" (click)="onViewAllBtnClick()">View all</button>
  </div>

  <div *ngIf="isShowPlaceholder">
    <app-note-item #latestNoteItem [singleNote]="true" [showPlaceholder]="true"></app-note-item>
  </div>

  <div class="notes-empty" *ngIf="!latestNote && !loading">
    <rkt-icon name="note" class="empty-note-icon"></rkt-icon>
    <div class="empty-note-text">No Recent Note</div>
  </div>

  <div *ngIf="latestNote">
    <app-note-item
      #latestNoteItem
      [singleNote]="true"
      [note]="latestNote"
      [notesLoading]="loading"
      (changed)="getLatestNote()"
    ></app-note-item>
  </div>
</div>
