import { Component, EventEmitter, inject, Input, Output, ViewChild } from '@angular/core';
import { uniqueId } from 'lodash-es';
import { Store } from '@ngrx/store';
import { NgSelectComponent } from '@ng-select/ng-select';
import { DropdownItem } from '@shared/models';

@Component({
  selector: 'app-status-select',
  templateUrl: 'status-select.component.html',
})
export class StatusSelectComponent {
  @ViewChild('select') selectRef!: NgSelectComponent;

  store = inject(Store);

  @Input() label!: string;

  @Input() required = false;

  @Input() selectedItem?: string;

  @Input() items: DropdownItem[] = [];

  @Input() loading = false;

  @Input() disabled = false;

  @Input() placeholder = '';

  @Output() changed = new EventEmitter<DropdownItem | undefined>();

  @Output() filterOpened = new EventEmitter();

  @Output() closed = new EventEmitter();

  selectId = uniqueId();

  selectedItemOnOpen?: DropdownItem;

  onChange(item: DropdownItem): void {
    this.changed.emit(item);
  }

  onOpen() {
    this.filterOpened.emit();
  }

  onClose() {
    if (this.selectedItemOnOpen?.value !== this.selectedItem) {
      this.closed.emit();
    }

    this.selectRef?.blur();
  }
}
