import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { TabItem } from '@shared/components';
import { fromInsightDocument } from '@shared/store';
import { Store } from '@ngrx/store';
import { combineLatest, Observable, map } from 'rxjs';
import { compact } from 'lodash-es';

@Component({
  selector: 'app-document-page',
  templateUrl: './document-page.component.html',
  styleUrls: ['./document-page.component.scss'],
})
export class DocumentPageComponent {
  get isExcludedPage() {
    return this.router.url.match('/app/insights/documents/(scheduled|find)-report/*');
  }

  menuItems$: Observable<TabItem[]> = combineLatest([this.store.select(fromInsightDocument.selectInsightUnreadDocuments)]).pipe(
    map(([insightUnreadDocuments]) => {
      const tabItems = [{ label: 'Reports', href: '/app/insights/documents/reports', isHighlighted: false }];

      const unreadDocs = insightUnreadDocuments.map((document) => document.section);

      tabItems.map((tabItem) => {
        if (tabItem && unreadDocs.includes(tabItem.label.toUpperCase())) {
          tabItem.isHighlighted = true;
        }
      });

      return compact(tabItems);
    }),
  );

  constructor(private router: Router, private store: Store) {}

  closeSideMenu() {
    this.router.navigate(['/app/insights']);
  }

  onSearchReports() {
    this.router.navigate(['/app/insights/documents/find-report']);
  }
}
