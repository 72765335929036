<ng-container *ngIf="reportDocument">
  <h1 class="title">Download {{ reportDocument.name | titlecase }}</h1>

  <button class="close-button" (click)="activeModal.close()"><rkt-icon name="times"></rkt-icon></button>

  <div class="details-page-edit-form">
    <form [formGroup]="templateNameForm">
      <rkt-form [form]="templateNameForm" [fields]="formFields" [model]="formModel"></rkt-form>
    </form>
  </div>

  <div class="buttons">
    <button rktButton type="button" btnType="tertiary" (click)="activeModal.close()">Cancel</button>
    <button rktButton type="submit" (click)="onSave()" [disabled]="templateNameForm.invalid || !formFieldValues.length">Download</button>
  </div>
</ng-container>
